import React, { useState, createContext } from "react"
import socketIOClient from "socket.io-client"
import { URL } from '../Utils/URL'

const UserContext = createContext();

function getCartFromLocalStorage() {
    return localStorage.getItem("auth")
        ? JSON.parse(localStorage.getItem("auth"))
        : false;
    }

function UserProvider({ children }) {
    const [auth, setAuth] = useState(getCartFromLocalStorage())
    const [userInstall, setUserInstall] = useState([])
    const [isDemo, setIsDemo] = useState(false)
    const [usernameContext, setUsernameContext] = useState('')
    const [passwordContext, setPasswordContext] = useState('')
    let socket = socketIOClient(`${URL.BACKEND_PREFIX}${URL.BACKEND_SOCKET_URL}`, {
        //transports: ['websocket'],
        autoConnect: false
        //path: '/', 
        //withCredentials: true,
        //reconnection: false
    })
    const [deviceId, setDeviceId] = React.useState('')
    const [hideDrawler, setHideDrawler] = React.useState(true)
    
    return (
        <UserContext.Provider
            value={{
                socket,
                deviceId,
                setDeviceId,
                usernameContext, 
                setUsernameContext,
                passwordContext, 
                setPasswordContext,
                userInstall,
                setUserInstall,
                setAuth,
                auth,
                isDemo,
                setIsDemo,
                hideDrawler, 
                setHideDrawler
            }}
        >
        {children}
        </UserContext.Provider>
    )
}

export { UserProvider, UserContext };