import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import {ScheduleContext} from '../../../../Context/ScheduleContext'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function ModalDeletePoint({open, onClose, closeUpdate, point}) {
    const {listPoints, setlistPoints, setPointDel} = React.useContext(ScheduleContext)
    const {t} = useTranslation('common')
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const deletePoint = () => {
        let item = listPoints.indexOf(point)
        listPoints.splice(item, 1)
        setlistPoints([...listPoints]) 
        setPointDel(Math.floor(Math.random() * (100000000 - 1) + 1))
        onClose()
        closeUpdate()
    }

    return (
        <Dialog open={open}
        style={matches ? {height: '50%'} : {height: '100%'}}
            onClose={onClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">{t('schedules.schedules_create_modal_delete_point_header')}</DialogTitle>
            <DialogContent>
                <Typography>{t('schedules.schedules_create_modal_delete_point_body')}</Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', paddingTop: '20px' }}>
                <Button
                    onClick={deletePoint}
                    color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.accept')}
                </Button>
                <Button onClick={onClose} color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
