import { Typography, Box, TextField, Dialog, DialogTitle, Button, Grid, IconButton,
    DialogContent, DialogContentText, DialogActions, Link as MaterialLink} from '@material-ui/core';
import React from 'react'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import '../../App.css';
import { makeStyles } from '@material-ui/core/styles'
import Image from 'material-ui-image'
import ImageLogin from '../../assets/logoColorClaro.png'
import { Link as RouterLink } from 'react-router-dom'
import axios from "axios";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { UserContext } from '../../Context/UserContext'
import { Alert} from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import {URL} from '../../Utils/URL'
//import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiInputBase-root': {
            color: '#EDE7D2',
        },
        "& .MuiFormLabel-root": {
            color: "#EDE7D2" // or black
        },
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid black'
        }
    }
}));

export default function Register({history}) {
    const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/createUser`
    const classes = useStyles()
    const gridStyle = { background: '#C62828', height: '100vh', padding: '40px 40px 0px 40px' }
    const btnstyle = { margin: '12px auto', width: 150, display: 'flex', backgroundColor: '#EDE7D2', color: '#000000' }
    const [username, setName] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [passwordError, setPasswordError] = React.useState(false)
    const [showPass, setShowPass] = React.useState(false)
    const [openTerms, setOpenTerms] = React.useState(false)
    const [scroll, setScroll] = React.useState('paper')
    const [userBackendError, setUserBackendError] = React.useState(false)
    const {setUsernameContext, setPasswordContext} = React.useContext(UserContext)
    //const {t} = useTranslation('common')
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const handleClickShowPass = () => {
        setShowPass(!showPass)
    }

    const handleClickOpen = (scrollType) => () => {
        setOpenTerms(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpenTerms(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (openTerms) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openTerms]);

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    } 
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }
    const [deviceId, setDeviceId] = React.useState('')
    const fpPromise = FingerprintJS.load()
    ;(async () => {
        const fp = await fpPromise
        const result = await fp.get()
        const visitorId = result.visitorId
        setDeviceId(visitorId)
    })()

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if(password.length < 6)
            setPasswordError(true)
        else {
            setUsernameContext(username)
            setPasswordContext(password)
            const data = {
                username: username,
                password: password,
                apiKey: 'hDBWRmZytD4q82n3Z3B2Xgsj6MehzGwS',
                lang: navigator.language.substring(0,2),
                deviceUuid: deviceId
            }
            axios.post(url, data)
            .then((res) => {
                localStorage.setItem('token', res.data.token)
                history.push('/login')
                //console.log(res)
            })
            .catch((e) => {
                //console.log(e)
                setUserBackendError(true)
            })
        }
    }

    return (
        <Grid container style={gridStyle} direction="row" justify="center" alignItems="center" >
            <Grid align='center' >
                <Image style={{ backgroundColor: 'transparent', position: 'inherit', paddingTop: 'inherit' }} src={ImageLogin}
                    alt='imagen portada' imageStyle={{ objectFit: 'contain', width: '56%', height: '12%', left: '0', paddingLeft: '22%', paddingRight: '16%', paddingTop: '57px' }} />
            </Grid>
            <form onSubmit={handleFormSubmit} style={matches ? {display: 'grid', justifyItems: 'center'} : {}}>
                <TextField
                    label='Usuario:'
                    placeholder='usuario@email.com'
                    type='email'
                    fullWidth
                    required
                    className={classes.root}
                    onChange={(e) => setName(e.target.value)}
                    style={matches ? {width: 190, display: 'grid'} : {width: 438}}
                />
                <Box style={{ marginTop: '12px' }}>
                    <TextField
                        label='Contraseña:'
                        placeholder='contraseña'
                        type={showPass ? 'text' : 'password'}
                        fullWidth
                        helperText={passwordError ? 'La contraseña debe tener más de 6 letras' : null}
                        required
                        className={classes.root}
                        onChange={handleChangePassword}
                        InputProps={{
                            endAdornment: <IconButton onClick={handleClickShowPass} onMouseDown={handleMouseDownPassword}>
                                {showPass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        }}
                        style={matches ? {width: 190, display: 'grid'} : {width: 438}}
                    />
                </Box>
                <Button
                    type='submit'
                    color='secondary'
                    variant="contained"
                    style={btnstyle}
                    fullWidth>
                    Crear
                </Button>
                {userBackendError ? <Alert action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => {setUserBackendError(false);}}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>} variant="filled" severity="warning">
                    Este usuario ya existe por favor introduzca otro
                </Alert> : null}
            <Typography style={{ color: 'black', textAlign: 'center', padding: '0px 50px' }} variant="caption" >
                    <p>Creando una cuenta de usuario se aceptan los <MaterialLink style={{ color: '#EDE7D2', fontStyle: 'italic', cursor: 'pointer' }}
                        onClick={handleClickOpen('paper')}>
                        términos y condiciones
                    </MaterialLink > del servicio</p>
                    <Dialog open={openTerms}
                        onClose={handleClose}
                        scroll={scroll}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description">
                        <DialogTitle id="scroll-dialog-title">Términos y Condiciones</DialogTitle>
                        <DialogContent dividers={scroll === 'paper'}>
                            <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                            >{[...new Array(50)]
                                .map(
                                    () => `Cras mattis consectetur purus sit amet fermentum.
                                            Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                                            Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                                )
                                .join('\n')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} color='secondary' style={{backgroundColor: '#C62828'}}>
                                Aceptar
                            </Button>
                            <Button onClick={handleClose} style={{backgroundColor: '#C62828', color: '#EDE7D2'}}>
                                Cancelar
                            </Button>
                            
                        </DialogActions>
                    </Dialog>
                </Typography>
                <Typography style={{ textAlign: 'center', padding: '0px 50px' }} variant="caption">
                    <MaterialLink style={{ color: '#EDE7D2' }} component={RouterLink} to='/'>
                        <p>Ya tengo una cuenta</p>
                    </MaterialLink >
                </Typography>
        </form>
        </Grid>
    )
}
