import React, { useContext } from 'react'
import { Container, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ScheduleContext } from '../../Context/ScheduleContext'
import Schedules from './Schedules'
import { InstallContext } from '../../Context/InstallContext'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UserContext } from '../../Context/UserContext'
import { useTranslation } from "react-i18next"
import { SystemContext } from '../../Context/SystemContext'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        paddingTop: 110,
        paddingBottom: 284,
        '& .MuiSwitch-colorSecondary.Mui-checked': {
            color: '#C62828'
        }
    }
});

export default function ScheduleComponent({ history }) {
    const classes = useStyles();
    const { listSchedule, setListSchedule, setListAvailableTemperature, setlistPoints, setWeekSelect, listAvailableTemperature, setDays, setNameSchedule, setType, setIcons } = useContext(ScheduleContext)
    const { install } = React.useContext(InstallContext)
    const { isDemo } = React.useContext(UserContext)
    const { setListSystem } = React.useContext(SystemContext)
    const [showCircle, setShowCircle] = React.useState(false)
    const { t } = useTranslation('common')
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const getListAvailableTemperature = () => {
        let newObject = []
        Object.entries(install.scheduler.availableTemperatures).forEach(([key, value]) => {
            let aTemp = {
                id: key,
                name: value.name,
                temperature: value.temperature
            }
            newObject.push(aTemp)
        })
        return newObject
    }

    const getPoints = () => {
        let p = []
        Object.keys(install.scheduler.availableTemperatures).forEach((key) => {
            let pointTemp = {
                temperature_id: key,
                time: {
                    hour: 0,
                    minute: 0
                }
            }
            p.push(pointTemp)
        })
        return p
    }

    React.useEffect(() => {
        if (!isDemo) {
            if(Object.keys(install).length !== 0){
                setListSchedule(install.scheduler.schedules)
                setListAvailableTemperature(getListAvailableTemperature())
                setlistPoints(getPoints())
                setShowCircle(false)
                setListSystem(install.systems)
            }
            else {
                if(Object.values(install).length === 0){
                    setShowCircle(false)
                }
                else {
                    setListSchedule(install.scheduler.schedules)
                    setShowCircle(true)
                }                
            }
        }
    }, [install])

    const handleAddSchedule = () => {
        setNameSchedule('')
        setDays([])
        setType(1)
        setWeekSelect({
            sunday: false,
            saturday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            monday: false
        })
        setIcons(1)
        setlistPoints([
            {
                temperature_id: listAvailableTemperature[0].id,
                time: {
                    hour: 8,
                    minute: 0
                }
            },
            {
                temperature_id: listAvailableTemperature[1].id,
                time: {
                    hour: 7,
                    minute: 0
                }
            },
            {
                temperature_id: listAvailableTemperature[0].id,
                time: {
                    hour: 14,
                    minute: 30
                }
            },
            {
                temperature_id: listAvailableTemperature[2].id,
                time: {
                    hour: 20,
                    minute: 0
                }
            }
        ])
        history.push('/addSchedule')
    }
    return (
        <Container style={matches ? {width: '100vh'} : {width: '100%'}}>
            <div className={classes.root}>
                <Typography variant={'h3'}>
                    {t('schedules.schedules_header')}
                </Typography>
                {!showCircle ? Object.keys(listSchedule).map((key) => (
                    <Schedules key={key} idSchedule={key} history={history} />
                )) : <CircularProgress color="primary" style={{ width: 140, height: 140, display: 'block', margin: '0 auto' }} />
                }
                <Button
                    type='submit'
                    variant='contained'
                    style={{ margin: 20, backgroundColor: '#C62828', color: '#EDE7D2' }}
                    onClick={handleAddSchedule}>{t('schedules.schedules_create_header')}</Button>
            </div>
        </Container>
    )
}