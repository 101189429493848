import React from 'react'
import { Dialog, TextField, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import HomeIcon from '@material-ui/icons/Home'
import ApartmentIcon from '@material-ui/icons/Apartment'
import axios from 'axios'
import { UserContext } from '../../../Context/UserContext'
import {URL} from '../../../Utils/URL'
import { useTranslation } from 'react-i18next'
import { InstallContext } from '../../../Context/InstallContext'

export default function ModalInstall({ id_inst, name, open, onClose }) {
    
    const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setInstallationNameAndIcon`
    const { userInstall, setUserInstall, isDemo} = React.useContext(UserContext)
    const { setInstallActive, installActive } = React.useContext(InstallContext)
    const [value, setValue] = React.useState(userInstall.name)
    const [icons, setIcons] = React.useState(userInstall.icon)
    const {t} = useTranslation('common')

    const handleChange = (e) => {
        setValue(e.target.value)
    }
    const handleChangeIcon = (e, value) => {
        setIcons(value)
    }

    const updateInstall = (e) => {
        onClose()
        if (isDemo){
            setUserInstall({...userInstall,
                id: id_inst,
                icon: icons,
                name: value
            })
            setInstallActive({
                ...installActive,
                id: id_inst,
                icon: icons,
                name: value
            })
        }
        else {
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
        const data = {
            name : value,
            icon: icons,
            id: id_inst
        }
        //console.log(data)
            axios.post(url, data, config)
            .then((res) => {
                setUserInstall({...userInstall,
                    id: id_inst,
                    icon: icons,
                    name: value
                })
                setInstallActive({
                    ...installActive,
                    id: id_inst,
                    icon: icons,
                    name: value
                })
            })
            .catch((e) => {
                //console.log(e)
            })
        }
    }
    const onCloseEdit = () =>{
        //setValue(name)
        onClose()
    }
    const setInstallName = () => {
        if(t(`install.${value}`) === 'Home' || t(`install.${value}`) === 'Casa' || t(`install.${value}`) === 'Maison'){
            return t(`install.${value}`)
        }
        else {
            return value
        }
    }

    return (
        
        <Dialog 
        open={open}
            onClose={onClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">{setInstallName()}</DialogTitle>
            <DialogContent>
                <TextField
                    id="standard-multiline-flexible"
                    label={setInstallName()}
                    multiline
                    rowsMax={4}
                    value={setInstallName()}
                    onChange={handleChange}
                />
                <ToggleButtonGroup value={icons} size="large" exclusive={true} onChange={handleChangeIcon} style={{display : 'block', paddingTop: '20px', textAlign: 'center'}} >
                    <ToggleButton style={{ borderRadius: '50px', border: 'transparent'}} value={1}>
                        <HomeIcon />
                    </ToggleButton>
                    <ToggleButton style={{marginLeft: '20px', borderRadius: '50px', border: 'transparent'}} value={2}>
                        <ApartmentIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            </DialogContent>
            <DialogActions style={{justifyContent: 'center', paddingTop: '20px'}}>
                <Button 
                onClick={updateInstall} 
                color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.accept')}
                </Button>
                <Button onClick={onCloseEdit} color='secondary' style={{ backgroundColor: '#C62828' }}>
                {t('modals.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}