import React from "react";
import { ScheduleContext } from "../../../../Context/ScheduleContext";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Snackbar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ModalDeletePoint from "./ModalDeletePoint";
import MuiAlert from '@material-ui/lab/Alert'
import CircleCheckedFilled from '@material-ui/icons/FiberManualRecord'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ModalUpdatePoint({ open, onClose, point }) {
    const {t} = useTranslation('common')
    const { listAvailableTemperature, listPoints, setlistPoints, setPointUpd } =
        React.useContext(ScheduleContext)
    const colors = ["#ff4040", "#0088ff", "gray", "#ffbf00", "#00a000", "#7956ca"];
    const [time, setTime] = React.useState({hour: 0, minute: 0});
    const [deletePoint, setDeletePoint] = React.useState(false);
    const [error, setError] = React.useState(false)
    const [tempPoint, setTempPoint] = React.useState('')
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    React.useEffect(() => {
        setTempPoint(point.temperature_id)
        setTime({ hour: point.time.hour, minute: point.time.minute })
    }, [listPoints])

    const valuePoint = () => {
        if (time.minute < 10 && time.hour < 10) return `0${time.hour}:0${time.minute}`;
        else if (time.hour < 10) return `0${time.hour}:${time.minute}`;
        else if (time.minute < 10) return `${time.hour}:0${time.minute}`;
        else {
            return `${time.hour}:${time.minute}`;
        }
    }

    const setColors = (aTemp) => {
        if (listAvailableTemperature.indexOf(aTemp) === 0) return colors[0];
        else if (listAvailableTemperature.indexOf(aTemp) === 1) return colors[1];
        else if (listAvailableTemperature.indexOf(aTemp) === 2) return colors[2];
        else if (listAvailableTemperature.indexOf(aTemp) === 3) return colors[3];
        else if (listAvailableTemperature.indexOf(aTemp) === 4) return colors[4];
        else if (listAvailableTemperature.indexOf(aTemp) === 5) return colors[5];
    };

    const openModalDeletePoint = () => {
        setDeletePoint(true);
    };

    const closeModalDeletePoint = () => {
        setDeletePoint(false);
    };

    const updatePoint = () => {
        if (tempPoint !== "") {
            let updatedList = listPoints.map((p) => {
                if (p.temperature_id === point.temperature_id && listPoints.indexOf(point) === listPoints.indexOf(p)) {
                    return {
                        ...p,
                        temperature_id: tempPoint,
                        time: { hour: time.hour, minute: time.minute },
                    };
                }
                return p
            })
            setlistPoints(updatedList);
            setPointUpd(Math.floor(Math.random() * (100000000 - 1) + 1))
            onClose();
        }
        else {
            setError(true)
        }
    };

    const handleClose = () => [
        setError(false)
    ]

    const handleChangeTemperature = (e, nextIcon) => {
        setTempPoint(nextIcon)
    }

    return (
        <Dialog
        style={matches ? {height: '50%'} : {height: '100%'}}    
        open={open}
            onClose={onClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">{t('schedules.schedules_create_modal_update_point_header')}</DialogTitle>
            <DialogContent>
                {listAvailableTemperature.map((at) => (
                    <ToggleButtonGroup key={at.id} value={tempPoint} exclusive onChange={handleChangeTemperature}
                    style={{paddingTop: '20px', textAlign: 'center' }}>
                        <ToggleButton style={{ borderRadius: '50px', border: 'transparent' }} value={at.id}>
                            <CircleCheckedFilled style={{color: setColors(at)}} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    ))}
                <IconButton color="inherit" onClick={openModalDeletePoint}>
                    <DeleteIcon color="action" />
                </IconButton>
                <Box width={200}>
                    <TextField
                        fullWidth
                        id="time"
                        label={t('schedules.schedules_select_hour')}
                        type="time"
                        value={valuePoint()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 60, // 5 min
                        }}
                        onChange={(e) => {
                            setTime({
                                hour: Number.parseInt(e.target.value.substring(0, 2)),
                                minute: Number.parseInt(e.target.value.substring(3, 5)),
                            });
                        }}
                    />
                </Box>
                <Snackbar open={error} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="warning">{t('schedules.schedules_select_color')}</Alert>
                </Snackbar>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center", paddingTop: "20px" }}>
                <Button
                    onClick={updatePoint}
                    color="secondary"
                    style={{ backgroundColor: "#C62828" }}
                >
                    {t('modals.accept')}
                </Button>
                <Button
                    onClick={onClose}
                    color="secondary"
                    style={{ backgroundColor: "#C62828" }}
                >
                    {t('modals.cancel')}
                </Button>
            </DialogActions>
            <ModalDeletePoint
                open={deletePoint}
                onClose={closeModalDeletePoint}
                closeUpdate={onClose}
                point={point}
            />
        </Dialog>
    );
}
