import { Grid, TextField, Button, Snackbar, Link as MaterialLink, Typography } from '@material-ui/core'
import { Alert} from '@material-ui/lab'
import '../../App.css';
import Image from 'material-ui-image'
import ImageLogin from '../../assets/logoColorClaro.png'
import { makeStyles } from '@material-ui/core/styles';
import React from 'react'
import axios from 'axios'
import {URL} from '../../Utils/URL'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiInputBase-root': {
            color: '#EDE7D2',
        },
        "& .MuiFormLabel-root": {
            color: "#EDE7D2" // or black
        },
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid black'
        }
    }
}));

export default function ForgetPassword({history}) {
    const gridStyle = { background: '#C62828', height: '100vh', padding: '40px 40px 0px 40px' }
    const btnstyle = { margin: '12px auto', width: 200, display: 'flex', backgroundColor: '#EDE7D2', color: '#000000' }
    const classes = useStyles();
    const [forgetPassword, setForgetPassword] = React.useState('')
    const url= `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/passwordRecovery`
    const [error, setError] = React.useState(false)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const data = {
            username: forgetPassword,
            apiKey: 'hDBWRmZytD4q82n3Z3B2Xgsj6MehzGwS',
            lang: navigator.language.substring(0,2)
        }
        axios.post(url, data)
        .then((res) => {
            //console.log(res)
            history.push('/')
        })
        .catch((e)=>{
            //console.log(e) 
            setError(true)
        })
    }

    return (
        <Grid
            container
            style={gridStyle}
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid align='center' >
                <Image style={{ backgroundColor: 'transparent', position: 'inherit',paddingTop: 'inherit' }} src={ImageLogin} alt='imagen portada'
                    imageStyle={{ objectFit: 'contain', width: '56%', height: '12%', left: '0', paddingLeft: '22%', paddingRight: '16%', paddingTop: '90px' }} />
            </Grid>
            <form onSubmit={handleFormSubmit} style={matches ? {display: 'grid', justifyItems: 'center'} : {}}>
                <TextField
                    label='Usuario'
                    placeholder='usuario@email.com'
                    fullWidth
                    onChange={(e) => setForgetPassword(e.target.value)}
                    className={classes.root}
                    style={matches ? {width: 190, display: 'grid'} : {width: 438}}
                />
                <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    style={btnstyle}
                    fullWidth
                >
                    Recuperar contraseña
                </Button>
                <Typography style={{ textAlign: 'center', padding: '0px 50px' }} variant="caption">
                    <MaterialLink style={{ color: '#EDE7D2' }} component={RouterLink} to='/'>
                        <p>Ya tengo una cuenta</p>
                    </MaterialLink >
                </Typography>
                <Snackbar open={error} autoHideDuration={1500} onClose={() => {setError(false)}}>
                    <Alert onClose={() => {setError(false)}} severity="warning" variant="filled">Usuario incorrecto por favor introduzca otros datos</Alert>
                </Snackbar>
            </form>
        </Grid>
    )
}
