import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@material-ui/core'
import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { UserContext } from '../../../../Context/UserContext'
import { ScheduleContext } from '../../../../Context/ScheduleContext'
import { InstallContext } from '../../../../Context/InstallContext'
import axios from 'axios'
import { URL } from '../../../../Utils/URL'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function ModalAddTemperature({ open, onClose }) {
    const {t} = useTranslation('common')
    const [nameTemperature, setNameTemperature] = React.useState('')
    const [temperature, setTemperature] = React.useState(22.5)
    const { isDemo } = React.useContext(UserContext)
    const { setListAvailableTemperature, setListFull } = React.useContext(ScheduleContext)
    const { installActive, getInstallation } = React.useContext(InstallContext)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const handleChange = (e) => {
        setNameTemperature(e.target.value)
    }
    const clickSumTemp = () => {
        if (temperature < 30.0)
            setTemperature(Math.round((temperature + 0.5) * 10) / 10)
    }

    const clickRestTemp = () => {
        if (temperature > 18.0)
            setTemperature(Math.round((temperature - 0.5) * 10) / 10)
    }
    
    const addTemperature = () => {
        if (isDemo) {
            setListAvailableTemperature(listAvailableTemperature => [...listAvailableTemperature, {
                id: `atm_${Math.floor(Math.random() * (100000000 - 1) + 1)}00${listAvailableTemperature.length}`,
                name: nameTemperature,
                temperature: temperature
            }])
            setNameTemperature('')
            setTemperature(22.5)
            onClose()
        }
        else {
            const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/createTemperature`
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            const data = {
                installationId: installActive.id,
                name: nameTemperature,
                value: temperature
            }
            axios.post(url, data, config)
                .then((res) => {
                    //console.log(res)
                    setListAvailableTemperature(listAvailableTemperature => [...listAvailableTemperature, {
                        id: res.data.id,
                        name: nameTemperature,
                        temperature: temperature
                    }])
                    getInstallation(installActive.id)
                    setNameTemperature('')
                    setTemperature(22.5)
                    onClose()
                })
                .catch((e) => {
                    onClose()
                    setListFull(true)
                })
        }
    }
    React.useEffect(()=>{
        setNameTemperature('')
    }, [])

    return (
        <Dialog open={open}
            onClose={onClose}
            aria-labelledby="scroll-dialog-title"
            style={matches ? {height: '50%'} : {height: '100%'}} 
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">{t('schedules.schedules_create_modal_add_temperatures_header')}</DialogTitle>
            <DialogContent>
                <TextField
                    id="standard-multiline-flexible"
                    label={'Nombre'}
                    multiline
                    rowsMax={4}
                    value={nameTemperature}
                    onChange={handleChange}
                />
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                    <IconButton onClick={clickRestTemp} color="inherit">
                        <RemoveIcon />
                    </IconButton>
                        <Typography style={{fontFamily: 'system-ui', fontWeight: 400}} variant='h6'>{temperature.toFixed(1)}º</Typography>
                    <IconButton onClick={clickSumTemp} color="inherit">
                        <AddIcon />
                    </IconButton>
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', paddingTop: '20px' }}>
                <Button
                    onClick={addTemperature}
                    color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.accept')}
                </Button>
                <Button onClick={onClose} color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
