import React from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import '../../App.css';
import Image from 'material-ui-image'
import ImageLogin from '../../assets/logoColorClaro.png'
import { UserContext } from '../../Context/UserContext'
import axios from 'axios'
import {URL} from '../../Utils/URL'
import { InstallContext } from '../../Context/InstallContext';

export default function ConfirmLogin({ history }) {

    const gridStyle = { background: '#C62828', height: '100vh', padding: '40px 40px 0px 40px' }
    const btnstyle = { margin: '12px auto', width: 250, display: 'flex', color: '#000' }
    const { setAuth, usernameContext, passwordContext, deviceId, setUserInstall, setIsDemo } = React.useContext(UserContext)
    const { setInstallActive } = React.useContext(InstallContext)
    const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/sendVerificationUser`

    const sendEmail = () => {
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
        const data = {
            lang: navigator.language.substring(0,2)
        }
        axios.post(url, data, config)
        .then((res)=> 
        {
            //console.log(res)
        })
        .catch((e) => {/*console.log(e)*/})
    }

    const isVerified = async () => {
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
        const urlIsVerify = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/isVerified`
        const urlLogin = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/auth`
        try {
            const res = await axios.get(urlIsVerify, config)
            //console.log(res.status)
            if(res.status === 200){
            const data = {
                username: usernameContext,
                password: passwordContext,
                apiKey: "hDBWRmZytD4q82n3Z3B2Xgsj6MehzGwS",
                deviceUuid: deviceId
            }
            axios.post(urlLogin, data)
            .then((res) => {
                    localStorage.setItem('token', res.data.token)
                    localStorage.setItem('auth', true)
                    setAuth(localStorage.getItem('auth'))
                    for(let [key, value] of Object.entries(res.data.response)){
                        
                        let userInstall = {
                            id: key,
                            icon: value.icon,
                            name: value.name
                        }
                        localStorage.setItem('userInstall', JSON.stringify(userInstall))
                    setUserInstall({
                        id: key,
                        icon: value.icon,
                        name: value.name
                    })
                    let installActive = {
                        id: key,
                        name: value.name,
                        icon: value.icon
                    }
                    setInstallActive({
                        id: key,
                        name: value.name,
                        icon: value.icon
                    })
                    setUserInstall(JSON.parse(localStorage.getItem('userInstall')))
                                localStorage.setItem('installActive', JSON.stringify(installActive))
                    setIsDemo(false)                
                    history.push("/install")
                }
            })
            .catch((e) => {/*console.log(e)*/})
        }
        } catch (e) {
            //console.log(e)
        }
    }
    React.useEffect(() => {
        const interval = setInterval(() => {
            
            isVerified()
            
        }, 5000)
        return () => clearInterval(interval);
    }, [])

    return (
        <Grid container style={gridStyle} direction="row" justify="center" alignItems="center" >
            <Grid align='center' >
                <Image style={{ backgroundColor: 'transparent', position: 'inherit', paddingTop: 'inherit' }} src={ImageLogin}
                    alt='imagen portada' imageStyle={{ objectFit: 'contain', width: '56%', height: '12%', left: '0', paddingLeft: '22%', paddingRight: '16%', paddingTop: '57px' }} />
            </Grid>
            <Grid align='center' >
            <Typography style={{ textAlign: 'center', padding: '0px 50px' }} color='secondary' variant="h6" >
                    <p>Por favor revisa la bandeja de entrada <br />de tu correo electrónico y confirma tu cuenta</p>
                </Typography>

                <Button
                    type='submit'
                    color='secondary'
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                    onClick={sendEmail}>
                    Reenviar confirmación
                </Button>
            </Grid>
        </Grid>
    )
}
