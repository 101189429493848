import React, { useState, createContext } from 'react'
import axios from 'axios'
import {URL} from '../Utils/URL'

const InstallContext = createContext();

function InstallProvider({ children }) {
  const demoInstall = {
    id: "ins_161c3effb59542bb816cf235a0dbf73c",
    systems: {
      sys_161c3effb59542bb816cf235a0dbf73c_159: {
        mode: 1,
        name: "default_system_name",
        state: {
          fan: {
            mode: 2,
            speed: 1
          },
          mode: 1
        },
        temperature: {
          setpoint: 23.0
        },
        uuid: "3c4b03bb-778aad14-220a-DEV.dev0000001",
        zones: {
          zon_161c3effb59542bb816cf235a0dbf73c_159_001: {
            enabled: true,
            name: "Salón",
            temperature: {
              sensor: 23.2,
              setpoint: 23.5
            }
          },
          zon_161c3effb59542bb816cf235a0dbf73c_159_002: {
            enabled: false,
            name: "Cocina",
            temperature: {
              sensor: 20.0,
              setpoint: 20.5
            }
          },
          zon_161c3effb59542bb816cf235a0dbf73c_159_003: {
            enabled: true,
            name: "Comedor",
            temperature: {
              sensor: 20.2,
              setpoint: 21.5
            }
          },
          zon_161c3effb59542bb816cf235a0dbf73c_159_004: {
            enabled: true,
            name: "Habitación 1",
            temperature: {
              sensor: 22.2,
              setpoint: 19.5
            }
          },
          zon_161c3effb59542bb816cf235a0dbf73c_159_005: {
            enabled: true,
            name: "Habitación 2",
            temperature: {
              sensor: 15.0,
              setpoint: 24.0
            }
          }
        }
      },
      sys_161c3effb59542bb816cf235a0dbf73c_129: {
        mode: 2,
        name: "default_system_name",
        state: {
          fan: {
            mode: 2,
            speed: 1
          },
          mode: 3
        },
        temperature: {
          setpoint: 23.0
        },
        uuid: "3c4b03bb-778aad14-220a-DEV.dev0000001",
        zones: {
          zon_161c3effb59542bb816cf235a0dbf73c_129_001: {
            enabled: true,
            name: "Estudio",
            temperature: {
              sensor: 22.2,
              setpoint: 23.5
            }
          },
          zon_161c3effb59542bb816cf235a0dbf73c_129_002: {
            enabled: false,
            name: "Habitación 3",
            temperature: {
              sensor: 20.5,
              setpoint: 20.0
            }
          }
        }
      }
    },
    scheduler: {
      availableTemperatures: {
        atm_161c3effb59542bb816cf235a0dbf73c_basic_000: {
          name: "default_normal",
          temperature: 20
        },
        atm_161c3effb59542bb816cf235a0dbf73c_basic_001: {
          name: "default_save",
          temperature: 17.5
        },        
        atm_161c3effb59542bb816cf235a0dbf73c_basic_002: {
          name: "default_off",
          temperature: 0
        }
      },
      schedules: {
        sch_161c3effb59542bb816cf235a0dbf73c_000: {
          enabled: true,
          id: "sch_161c3effb59542bb816cf235a0dbf73c_000",
          mode: 1,
          name: "Diaria invierno",
          points: [
            {
              temperature_id: "atm_161c3effb59542bb816cf235a0dbf73c_basic_000",
              time: {
                hour: 7,
                minute: 0
              }
            },
            {
              temperature_id: "atm_161c3effb59542bb816cf235a0dbf73c_basic_001",
              time: {
                hour: 11,
                minute: 0
              }
            },
            {
              temperature_id: "atm_161c3effb59542bb816cf235a0dbf73c_basic_002",
              time: {
                hour: 19,
                minute: 0
              }
            }
          ],
          type: 1,
          weeklyDays: {
            "sunday": false,
            "saturday": false,
            "tuesday": true,
            "wednesday": true,
            "thursday": true,
            "friday": true,
            "monday": true
          },
          zones: [
            "zon_161c3effb59542bb816cf235a0dbf73c_159_001",
            "zon_161c3effb59542bb816cf235a0dbf73c_159_003"
          ]
        },
        sch_161c3effb59542bb816cf235a0dbf73c_001: {
          enabled: false,
          id: "sch_161c3effb59542bb816cf235a0dbf73c_001",
          mode: 3,
          name: "Diaria verano",
          points: [
            {
              temperature_id: "atm_161c3effb59542bb816cf235a0dbf73c_basic_000",
              time: {
                hour: 7,
                minute: 0
              }
            },
            {
              temperature_id: "atm_161c3effb59542bb816cf235a0dbf73c_basic_001",
              time: {
                hour: 11,
                minute: 0
              }
            },
            {
              temperature_id: "atm_161c3effb59542bb816cf235a0dbf73c_basic_002",
              time: {
                hour: 19,
                minute: 0
              }
            }
          ],
          type: 1,
          weeklyDays: {
            "sunday": false,
            "saturday": false,
            "tuesday": true,
            "wednesday": true,
            "thursday": true,
            "friday": true,
            "monday": true
          },  
          zones: [
            "zon_161c3effb59542bb816cf235a0dbf73c_159_001",
            "zon_161c3effb59542bb816cf235a0dbf73c_159_003"
          ]
        },
        sch_161c3effb59542bb816cf235a0dbf73c_002: {
          enabled: true,
          id: "sch_161c3effb59542bb816cf235a0dbf73c_002",
          mode: 6,
          name: "Fiestas Navidad",
          points: [
            {
              temperature_id: "atm_161c3effb59542bb816cf235a0dbf73c_basic_000",
              time: {
                hour: 5,
                minute: 0
              }
            },
            {
              temperature_id: "atm_161c3effb59542bb816cf235a0dbf73c_basic_001",
              time: {
                hour: 9,
                minute: 0
              }
            },
            {
              temperature_id: "atm_161c3effb59542bb816cf235a0dbf73c_basic_000",
              time: {
                hour: 13,
                minute: 0
              }
            },
            {
              temperature_id: "atm_161c3effb59542bb816cf235a0dbf73c_basic_002",
              time: {
                hour: 19,
                minute: 0
              }
            }
          ],
          type: 2,
            specificDays: [
            {
            day: 12, month: 8, year: 2021
          },
          {
            day: 13, month: 8, year: 2021
          },
          {
            day: 10, month: 8, year: 2021
          },
          {
            day: 29, month: 8, year: 2021
          }
        ],
          zones: [
            "zon_161c3effb59542bb816cf235a0dbf73c_159_001",
            "zon_161c3effb59542bb816cf235a0dbf73c_159_003"
          ]
        },
      }
    }
  }

  const [listInstalls, setListInstalls] = useState([])
  const [installActive, setInstallActive] = useState({
    id: '',
    name: ''
  })
  const [install, setInstall] = useState({})
  const [disabled, setDisabled] = useState(true)

  const getInstallation = async (idInstall) => {    
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          id: idInstall
        }
      }
      const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/getInstallation`
      try {
        const data = await axios.get(url, config)
        //console.log(data.data)  
        setInstall(data.data)
      }
      catch (e) {
        //console.log(e)
      }
    }

  return (
    <InstallContext.Provider
      value={{
        demoInstall,
        disabled,
        setDisabled,
        listInstalls,
        setListInstalls,
        installActive,
        setInstallActive,
        getInstallation,
        install,
        setInstall
      }}
    >
      {children}
    </InstallContext.Provider>
  )
}
export { InstallProvider, InstallContext }