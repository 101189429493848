import React, { useState, useContext, useEffect } from "react";
import {
    Card,
    Box,
    CardHeader,
    CardActions,
    CardContent,
    Typography,
    IconButton
} from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core/styles";
import { SystemContext } from "../../../Context/SystemContext";
//import ModalZone from './ModalZone'
import ModalEditZone from "./Modals/ModalEditZone";
import PowerRed from "../../../assets/iconsImages/power_red.png";
import { mdiThermometer } from "@mdi/js";
import Icon from "@mdi/react";
import axios from "axios";
import { UserContext } from "../../../Context/UserContext";
import { useTranslation } from 'react-i18next'
import { URL } from '../../../Utils/URL'

const useStyles = makeStyles((theme) => ({
    root: {
        width: 200,
        maxHeight: 227,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    bodyContext: {
        textAlign: "center",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    animation: {
        '& .MuiTouchRipple-root': {
            position: 'inherit'
        }
    }
}));

export default function CardsComponent({ id_zone, id_system, temperature }) {
    const classes = useStyles();
    const { t } = useTranslation('common')
    const { changeApp, listSystem, setListSystem, setValueSys } = useContext(SystemContext);
    //    const [openModalZone, setModalZone] = useState(false)
    const [modalEditZone, setModalEditZone] = useState(false);
    const { isDemo } = React.useContext(UserContext)

    useEffect(() => {
        if (listSystem[id_system].mode === 3) {
            listSystem[id_system].zones[id_zone].enabled = false
            setListSystem({ ...listSystem })
        }
    }, [changeApp, temperature]);

    const clickRestTemp = () => {
        if (isDemo) {
            if (listSystem[id_system].zones[id_zone].temperature.setpoint > 18.0) {
                listSystem[id_system].zones[id_zone].temperature.setpoint = listSystem[id_system].zones[id_zone].temperature.setpoint - 0.5
                listSystem[id_system].mode = 2
                setListSystem({ ...listSystem })
            }
        }
        else {
            if (listSystem[id_system].zones[id_zone].temperature.setpoint > 18.0) {
                listSystem[id_system].zones[id_zone].temperature.setpoint = listSystem[id_system].zones[id_zone].temperature.setpoint - 0.5
                listSystem[id_system].mode = 2
                setListSystem({ ...listSystem })
                const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setZoneTemperatureSetpoint`
                const config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                };
                const data = {
                    id: id_zone,
                    setPoint: listSystem[id_system].zones[id_zone].temperature.setpoint,
                };
                axios
                    .post(url, data, config)
                    .then((res) => {
                        
                    })
                    .catch((e) => {
                        //console.log(e);
                    });
            }
        }
    };
    const clickSumTemp = () => {
        if (isDemo) {
            if (listSystem[id_system].zones[id_zone].temperature.setpoint < 30.0) {
                listSystem[id_system].zones[id_zone].temperature.setpoint = listSystem[id_system].zones[id_zone].temperature.setpoint + 0.5
                listSystem[id_system].mode = 2
                setListSystem({ ...listSystem })
            }
        }
        else {
            if (listSystem[id_system].zones[id_zone].temperature.setpoint < 30.0) {
                listSystem[id_system].zones[id_zone].temperature.setpoint = listSystem[id_system].zones[id_zone].temperature.setpoint + 0.5
                listSystem[id_system].mode = 2
                setListSystem({ ...listSystem })
                const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setZoneTemperatureSetpoint`
                const config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                };
                const data = {
                    id: id_zone,
                    setPoint: listSystem[id_system].zones[id_zone].temperature.setpoint,
                };
                axios
                    .post(url, data, config)
                    .then((res) => {
                        
                    })
                    .catch((e) => {
                        //console.log(e);
                    });
            }
        }
    }
    const handleClicEnabled = () => {
        if (isDemo) {
            listSystem[id_system].mode = 2
            listSystem[id_system].zones[id_zone].enabled = !listSystem[id_system].zones[id_zone].enabled
            setListSystem({ ...listSystem })
            setValueSys(Math.floor(Math.random() * (100 - 10)) + 10)
        } else {
            const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setZoneEnabled`
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            const data = {
                id: id_zone,
                enabled: !listSystem[id_system].zones[id_zone].enabled,
            };
            axios
                .post(url, data, config)
                .then((res) => {
                    listSystem[id_system].zones[id_zone].enabled = !listSystem[id_system].zones[id_zone].enabled
                    listSystem[id_system].mode = 2
                    setListSystem({ ...listSystem })
                    setValueSys(Math.floor(Math.random() * (100 - 10)) + 10)
                })
                .catch((e) => {
                    //console.log(e);
                });
        }
    };
    // const handleOpenModal = () => {
    //     setModalZone(true)
    // }
    // const handleCloseModal = () => {
    //     setModalZone(false)
    // }
    const handleOpenEditZoneModal = () => {
        setModalEditZone(true);
    };
    const handleCloseEditZoneModal = () => {
        setModalEditZone(false);
    };
    const formattZoneName = (name) => {
        if (name.length >= 7) return name.substring(0, 6) + "...";
        else return name;
    };

    const zoneNames = () => {
        let newObj = []
        for (let [key, value] of Object.entries(listSystem)) {
            let newSystem = {
                id: key,
                zones: []
            }
            let newZone = []
            for (let k of Object.keys(value.zones)) {
                let nZone = k
                newZone.push(nZone)
            }
            newSystem.zones = newZone
            newObj.push(newSystem)
        }
        return changeName(newObj)
    }

    const changeName = (names) => {
        let zN = ''
        let index = 0
        if (t(`zone.${listSystem[id_system].zones[id_zone].name}`) === 'Zona' || t(`zone.${listSystem[id_system].zones[id_zone].name}`) === 'Zone') {
            for (let name of names) {
                for (let zone of name.zones) {
                    if (zone === id_zone) {
                        index = (name.zones).indexOf(id_zone)
                        break
                    }
                }
            }
            let f = t(`zone.${listSystem[id_system].zones[id_zone].name}`)
            zN = `${f} ${index + 1}`
        }
        else {
            zN = listSystem[id_system].zones[id_zone].name
        }
        return zN
    }

    return (
        <Box boxShadow={4} m={2}>
            <Card className={classes.root}>
                {listSystem[id_system].zones[id_zone].enabled ? (
                    <React.Fragment>
                        {listSystem[id_system].zones[id_zone].temperature.setpoint === 1000 ?
                            <>
                                <CardHeader
                                    action={
                                        <IconButton disabled={true}
                                            style={{ color: "green" }}
                                            onClick={handleClicEnabled}>
                                            <PowerSettingsNewIcon />
                                        </IconButton>
                                    }
                                    title={
                                        <Box>
                                            <IconButton onClick={handleOpenEditZoneModal}>
                                                {formattZoneName(zoneNames())}
                                            </IconButton>
                                        </Box>
                                    }
                                />
                                <ModalEditZone
                                    id_system={id_system}
                                    id_zone={id_zone}
                                    name={zoneNames}
                                    open={modalEditZone}
                                    onClose={handleCloseEditZoneModal} />
                                <CardContent style={{ padding: 0 }}>
                                    <Box
                                        // onClick={handleOpenModal}
                                        style={{ textAlign: "center" }}>
                                        <Typography style={{ fontFamily: 'system-ui', fontWeight: 400 }} className={classes.bodyContext} variant={"h4"}>
                                            --.-º
                                        </Typography>
                                        <Box
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: 10,
                                            }}>
                                            <Icon size={1} path={mdiThermometer} />
                                            <Typography style={{ fontSize: 20, color: "black", fontFamily: 'system-ui', fontWeight: 400 }}>
                                                {typeof (listSystem[id_system].zones[id_zone].temperature.sensor) === 'number' && listSystem[id_system].zones[id_zone].temperature.sensor === 1000 ? '--.-' : `${listSystem[id_system].zones[id_zone].temperature.sensor.toFixed(1)}º`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* <ModalZone enabled={zone.enabled} name={zone.name} open={openModalZone} onClose={handleCloseModal} sensor={zone.temperature.sensor} temperature={tempRoom} /> */}
                                </CardContent>
                                <CardActions>
                                    <IconButton disabled={true} color="inherit">
                                        <RemoveIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={true}
                                        style={{ marginLeft: "auto" }}
                                        color="inherit">
                                        <AddIcon />
                                    </IconButton>
                                </CardActions>
                            </> :
                            <>
                                <CardHeader
                                    action={
                                        <IconButton
                                            style={{ color: "green" }}
                                            onClick={handleClicEnabled}>
                                            <PowerSettingsNewIcon />
                                        </IconButton>
                                    }
                                    title={
                                        <Box>
                                            <IconButton onClick={handleOpenEditZoneModal}>
                                                {formattZoneName(zoneNames())}
                                            </IconButton>
                                        </Box>
                                    }
                                />
                                <ModalEditZone
                                    id_system={id_system}
                                    id_zone={id_zone}
                                    name={zoneNames}
                                    open={modalEditZone}
                                    onClose={handleCloseEditZoneModal} />
                                <CardContent style={{ padding: 0 }}>
                                    <Box
                                        // onClick={handleOpenModal}
                                        style={{ textAlign: "center" }}>
                                        <Typography style={{ fontFamily: 'system-ui', fontWeight: 400 }} className={classes.bodyContext} variant={"h4"}>
                                            {`${listSystem[id_system].zones[id_zone].temperature.setpoint.toFixed(1)}º`}
                                        </Typography>
                                        <Box
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: 10,
                                            }}>
                                            <Icon size={1} path={mdiThermometer} />
                                            <Typography style={{ fontSize: 20, color: "black", fontFamily: 'system-ui', fontWeight: 400 }}>
                                                {typeof (listSystem[id_system].zones[id_zone].temperature.sensor) === 'number' && listSystem[id_system].zones[id_zone].temperature.sensor === 1000 ? '--.-' : `${listSystem[id_system].zones[id_zone].temperature.sensor.toFixed(1)}º`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* <ModalZone enabled={zone.enabled} name={zone.name} open={openModalZone} onClose={handleCloseModal} sensor={zone.temperature.sensor} temperature={tempRoom} /> */}
                                </CardContent>
                                <CardActions>
                                    <IconButton onClick={clickRestTemp} color="inherit">
                                        <RemoveIcon />
                                    </IconButton>
                                    <IconButton
                                        style={{ marginLeft: "auto" }}
                                        onClick={clickSumTemp}
                                        color="inherit">
                                        <AddIcon />
                                    </IconButton>
                                </CardActions>
                            </>
                        }
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <CardHeader title={<Box>{formattZoneName(zoneNames())}</Box>} />
                        <CardContent>
                            <IconButton
                                className={classes.animation}
                                style={{ display: "contents" }}
                                onClick={handleClicEnabled}>
                                <img
                                    src={PowerRed}
                                    style={{ maxHeight: "93px" }}
                                    alt="Power Modal" />
                            </IconButton>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: 10,
                                }}>
                                <Icon size={1} path={mdiThermometer} />
                                <Typography style={{ fontSize: 20, color: "black", fontFamily: 'system-ui', fontWeight: 400 }}>
                                    {typeof (listSystem[id_system].zones[id_zone].temperature.sensor) === 'number' && listSystem[id_system].zones[id_zone].temperature.sensor === 1000 ? '--.-' : `${listSystem[id_system].zones[id_zone].temperature.sensor.toFixed(1)}º`}
                                </Typography>
                            </Box>
                        </CardContent>
                    </React.Fragment>
                )}
            </Card>
        </Box>
    );
}