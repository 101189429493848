import React from "react";
import {
    LinearGaugeComponent,
    AxesDirective,
    AxisDirective,
    RangeDirective,
    RangesDirective,
    PointersDirective,
    PointerDirective,
} from "@syncfusion/ej2-react-lineargauge";
import { ScheduleContext } from "../../../Context/ScheduleContext";

export default function LinearGaugeAddSchedule() {
    const {listPoints, listAvailableTemperature} = React.useContext(ScheduleContext)

    return (
        <LinearGaugeComponent
            orientation="Horizontal"
            width='450px' height='150px'
            container={{ width: 30, offset: 30 }}>
            <AxesDirective>
                <AxisDirective
                    minimum={0}
                    maximum={24}
                    line={{ width: 0 }}
                    majorTicks={{ width: 0, interval: 6 }}
                    minorTicks={{ width: 0 }}
                    labelStyle={{ format: "{value}:00", offset: 70 }}>
                    <PointersDirective>
                        <PointerDirective opacity="0"></PointerDirective>
                    </PointersDirective>
                    <RangesDirective>
                        {listPoints.map((tp, index) => {
                            let end = 24;
                            let aTemp = listAvailableTemperature.find((at) => at.id === tp.temperature_id)
                            let color =
                                listAvailableTemperature.indexOf(aTemp) === 0
                                    ? "#ff4040"
                                    : listAvailableTemperature.indexOf(aTemp) === 1
                                        ? "#0088ff"
                                        : listAvailableTemperature.indexOf(aTemp) === 2
                                            ? "#E0E0E0"
                                            : listAvailableTemperature.indexOf(aTemp) === 3
                                                ? "#ffbf00"
                                                : listAvailableTemperature.indexOf(aTemp) === 4
                                                    ? "#00a000"
                                                    : "#7956ca";
                            index === listPoints.length - 1
                                ? (end = 24)
                                : (end = `${listPoints[index + 1].time.hour}.${listPoints[index + 1].time.minute}` );
                            return (                                
                                <RangeDirective
                                    key={index}
                                    start={`${tp.time.hour}.${tp.time.minute}`}
                                    end={end}
                                    color={color}
                                    offset={30}
                                    startWidth={30}
                                    endWidth={30}
                                ></RangeDirective>                                
                            );
                        })}
                    </RangesDirective>
                </AxisDirective>
            </AxesDirective>
        </LinearGaugeComponent>
    );
}