import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Container, Typography, Box} from '@material-ui/core'
import Systems from './Systems'
import { InstallContext } from "../../Context/InstallContext"
import { SystemContext } from '../../Context/SystemContext'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import Icon from "@mdi/react"
import { mdiHome } from "@mdi/js"
import { mdiOfficeBuilding  } from "@mdi/js"
import { ScheduleContext } from '../../Context/ScheduleContext'

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        paddingTop: 110,
        paddingBottom: 284
    }
});

export default function SystemComponent() {
    const classes = useStyles();
    const {listSystem, setListSystem} = React.useContext(SystemContext)
    const {installActive, isDemo, install } =  React.useContext(InstallContext)
    const {setListSchedule, listSchedule} = React.useContext(ScheduleContext)
    const { t } = useTranslation('common')
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if(isDemo){
            //setListSystem(install.systems)
            //setLoading(false)
        }
        else {
            if(Object.values(install).length === 0){
                setLoading(true)
            }
            else if(Object.keys(listSchedule).length !== 0){
                setListSchedule(install.scheduler.schedules)                
            }
            else {
                setListSystem(install.systems)
                setLoading(false)
            }
        }
    }, [install])

    const setInstallName = () => {
        if(t(`install.${installActive.name}`) === 'Home' || t(`install.${installActive.name}`) === 'Casa' || t(`install.${installActive.name}`) === 'Maison'){
            return t(`install.${installActive.name}`)
        }
        else {
            return installActive.name
        }
    }   
    
    return (
        <Container>
        <div className={classes.root}>
        <Box style={{display: 'flex', alignItems: 'flex-end'}}>
        {installActive.icon === 1 ? <Icon color='#C62828' path={mdiHome} size={3} /> : <Icon color='#C62828' path={mdiOfficeBuilding} size={3} />}
        <Typography variant='h3'>{setInstallName()}</Typography>        
        </Box>
        { 
        !loading ? Object.keys(listSystem).map((key) => (
            <Systems key={key} id_system={key} />
            ))
        :   
        <CircularProgress style={{width: 140, height: 140, display: 'block', margin: '0 auto'}} color="primary" />
        }
        </div>
        </Container>
    )                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
}