import { FormControlLabel, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'
import CircleCheckedFilled from '@material-ui/icons/FiberManualRecord'
import { useTranslation } from 'react-i18next'
import ModalUpdateTemperature from '../Temperatures/Modals/ModalUpdateTemperature'
import { ScheduleContext } from '../../../Context/ScheduleContext'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    root: {
        '& .MuiTypography-body1': {
            fontFamily: 'system-ui'
        }
    },
});

export default function TemperatureSchedule({ aTemp }) {
    const { t } = useTranslation('common')
    const colors = ['#ff4040', '#0088ff', 'gray', '#ffbf00', '#00a000', '#7956ca']
    const [modalUpdateTemperature, setModalUpdateTemperature] = React.useState(false)
    const { listAvailableTemperature } = React.useContext(ScheduleContext)
    const classes = useStyles();
    
    const setColors = () => {
        if (listAvailableTemperature.indexOf(aTemp) === 0)
            return colors[0]
        else if (listAvailableTemperature.indexOf(aTemp) === 1)
            return colors[1]
        else if (listAvailableTemperature.indexOf(aTemp) === 2)
            return colors[2]
        else if (listAvailableTemperature.indexOf(aTemp) === 3)
            return colors[3]
        else if (listAvailableTemperature.indexOf(aTemp) === 4)
            return colors[4]
        else if (listAvailableTemperature.indexOf(aTemp) === 5)
            return colors[5]
    }

    const setName = (name, temperature) => {
        if (name === 'Off') {
            return name.substring(0, 4)
        }
        else {
            return `${name.substring(0, 1)}  ${temperature}`
        }
    }

    const handleOpenModalUpdateTemperature = (e, name) => {
        if (name === 'Off') {
            setModalUpdateTemperature(false)
        }
        else {
            setModalUpdateTemperature(true)
        }
    }

    const handleCloseModalUpdateTemperature = () => {
        setModalUpdateTemperature(false)
    }
    const setNewName = () => {
        if(t(`temperatures.${aTemp.name}`) === 'Normal' || t(`temperatures.${aTemp.name}`) === 'Ordinaire' || t(`temperatures.${aTemp.name}`) === 'Normale' || t(`temperatures.${aTemp.name}`) === 'Save' || t(`temperatures.${aTemp.name}`) === 'Ahorro' || t(`temperatures.${aTemp.name}`) === 'Salvataggio' || t(`temperatures.${aTemp.name}`) === 'Économie' || t(`temperatures.${aTemp.name}`) === 'Off'){
            return t(`temperatures.${aTemp.name}`)
        }
        else
            return aTemp.name
    }

    return (
        <div>
            <FormControlLabel
                control={
                    <ListItem button onClick={(e) => { handleOpenModalUpdateTemperature(e, setNewName())}}>
                        <ListItemIcon>
                            <CircleCheckedFilled style={{ color: setColors() }} />
                        </ListItemIcon>
                        {
                            setNewName() !== 'Off' ? 
                                <ListItemText primary={`${setName(setNewName(), (aTemp.temperature).toFixed(1)).toUpperCase()}º`} className={classes.root} />                                
                                : <ListItemText primary={`${setName(setNewName(), (aTemp.temperature).toFixed(1)).toUpperCase()}`} className={classes.root} />
                            
                        }
                    </ListItem>
                } />
            <ModalUpdateTemperature
                open={modalUpdateTemperature}
                onClose={handleCloseModalUpdateTemperature}
                aTemp={aTemp}
            />
        </div>
    )
}