import React from 'react'
import {Container, Typography,TextField,Button, Snackbar, IconButton} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import axios from 'axios'
import {UserContext} from '../Context/UserContext'
import {URL} from '../Utils/URL'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

const useStyles = makeStyles((theme)=>({
    root: {
        backgroundColor: 'white',
        paddingTop: 110,
        paddingBottom: 284,
        textAlign: 'center',
        display: 'grid'
    },
    nested: {
        margin: '0 auto'
    }
}));

export default function ChangePasswordComponent() {
    const classes = useStyles();
    const btnstyle = { color: '#EDE7D2', margin: '12px auto', width: 150, display: 'flex', backgroundColor: '#C62828'}
    const [notEqualPassword, setNotEqualPassword] = React.useState(false)
    const [password, setPassword] = React.useState('')
    const [newPassword, setNewPassword]= React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const[wrongPassword, setWrongPassword] = React.useState(false)
    const {isDemo} = React.useContext(UserContext)
    const [showPass1, setShowPass1] = React.useState(false)
    const [showPass2, setShowPass2] = React.useState(false)
    const [showPass3, setShowPass3] = React.useState(false)
    const [successChangePassword, setSuccessChangePassword] = React.useState(false)
    const {t} = useTranslation('common')
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const handleClickShowPass1 = () => {
        setShowPass1(!showPass1)
    }
    const handleClickShowPass2 = () => {
        setShowPass2(!showPass2)
    }
    const handleClickShowPass3 = () => {
        setShowPass3(!showPass3)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if(confirmPassword !== newPassword && password.length < 6){
            setNotEqualPassword(true)
        }
        if(isDemo){

        }
        else{
        const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/changePassword`;
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            const data = {
                password: password,
                newPassword: newPassword,
            };
            axios.post(url, data, config).then((res)=> {
                setSuccessChangePassword(true)
            }).catch((e)=>{
                //console.log(e)
                setWrongPassword(true)
            })
        }
    }
    const handleClose = () => {
        setNotEqualPassword(false)
        setWrongPassword(false)
        setSuccessChangePassword(false)
    }


    return (
        <Container>
            <form onSubmit={handleFormSubmit} className={classes.root}>
            <Typography variant={'h3'}>{t('change_password.change_password_header')}</Typography>
            <TextField
            title=''
            className={classes.nested}
            label={t('change_password.change_password_current')}
            required
            type={showPass1 ? 'text' : 'password'}
            onChange={(e)=> setPassword(e.target.value)}
            style={{width: 340}}
            InputProps={{
                endAdornment: <IconButton onClick={handleClickShowPass1} onMouseDown={handleMouseDownPassword}>
                    {showPass1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            }}
            />
            <TextField
            title=''
            className={classes.nested}
            style={{width: 340}}
            label={t('change_password.change_password_new')}
            required
            type={showPass2 ? 'text' : 'password'}
            onChange={(e)=> setNewPassword(e.target.value)}
            InputProps={{
                endAdornment: <IconButton onClick={handleClickShowPass2} onMouseDown={handleMouseDownPassword}>
                    {showPass2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            }}
            />
            <TextField
            title=''
            className={classes.nested}
            style={{width: 340}}
            label={t('change_password.change_password_confirm')}
            required
            type={showPass3 ? 'text' : 'password'}
            onChange={(e)=> setConfirmPassword(e.target.value)}
            InputProps={{
                endAdornment: <IconButton onClick={handleClickShowPass3} onMouseDown={handleMouseDownPassword}>
                    {showPass3 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            }}
            />
            
            <Snackbar className={classes.nested} open={notEqualPassword} autoHideDuration={200000} onClose={handleClose} >
                <Alert onClose={handleClose} severity="warning">{t('change_password.change_password_validation_secure_password')}</Alert>
            </Snackbar>
            <Snackbar open={wrongPassword} className={classes.nested} autoHideDuration={2000} onClose={handleClose} >
                <Alert onClose={handleClose} severity="warning">{t('change_password.change_password_wrong_password')}</Alert>
            </Snackbar>
            <Snackbar open={successChangePassword} className={classes.nested} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">{t('change_password.change_password_success_password')}</Alert>
            </Snackbar>
            <Button 
                type='submit' 
                color='primary' 
                variant="contained" 
                style={btnstyle} 
                fullWidth>
                    {t('change_password.change_password_botton')}
                </Button>

        </form>
        </Container>
    )
}
