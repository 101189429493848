import React from 'react'
import { Box, Button, FormControl, FormGroup, Typography, IconButton, Snackbar } from '@material-ui/core'
import CircleCheckedFilled from '@material-ui/icons/FiberManualRecord'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import ModalAddTemperature from '../Temperatures/Modals/ModalAddTemperature'
import ModalAddPoint from '../Points/Modals/ModalAddPoint'
import { ScheduleContext } from '../../../Context/ScheduleContext'
import { useTranslation } from 'react-i18next'
import { SystemContext } from '../../../Context/SystemContext'
import MuiAlert from '@material-ui/lab/Alert'
import TemperatureSchedule from '../Temperatures/TemperatureSchedule'
import PointSchedule from '../Points/PointSchedule'
import LinearGaugeAddSchedule from '../LinearGauge/LinearGaugeAddSchedule'
import { InstallContext } from '../../../Context/InstallContext'
import { UserContext } from '../../../Context/UserContext'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddScheduleFinish() {
    const { newSystem, listSchedule, setNewSystem, listAvailableTemperature, listFull, setListFull, listPoints, setListSchedule, setlistPoints, /*valueSchUpd,*/ setListAvailableTemperature } = React.useContext(ScheduleContext)
    const [modalAddTemperature, setModalAddTemperature] = React.useState(false)
    const [modalAddPoint, setModalAddPoint] = React.useState(false)
    const { listSystem } = React.useContext(SystemContext)
    const { /*install,*/ installActive } = React.useContext(InstallContext)
    const { t } = useTranslation('common')
    const [systemID, setSystemID] = React.useState(1)
    const { isDemo, socket, deviceId } = React.useContext(UserContext)

    const handleOpenModalAddTemperature = () => {
        if (listAvailableTemperature.length <= 5)
            setModalAddTemperature(true)
        else {
            setModalAddTemperature(false)
        }
    }

    const handleCloseModalAddTemperature = () => {
        setModalAddTemperature(false)
    }

    const handleOpenModalAddPoint = () => {
        setModalAddPoint(true)
    }

    const handleCloseModalAddPoint = () => {
        setModalAddPoint(false)
    }

    const [checkedSystem, setCheckedSystem] = React.useState(false)

    const getNewSystem = () => {
        let newObject = []
        for (let [key, value] of Object.entries(listSystem)) {
            let nSystem = {
                systemId: key,
                systemName: value.name,
                status: false,
                zones: []
            }
            let newZones = []
            for (let [k, v] of Object.entries(value.zones)) {
                newZones.push({ zoneName: v.name, zoneId: k, status: false })
            }
            nSystem.zones = newZones
            newObject.push(nSystem)
        }
        return newObject
    }

    const scheduleTemperatureAdd = (data) => {
        setListAvailableTemperature(listAvailableTemperature => [...listAvailableTemperature, {
            id: data.id,
            name: data.name,
            temperature: data.temperature
        }])
    }
    const scheduleTemperatureUpdate = (data) => {
        setListAvailableTemperature(listAvailableTemperature => (
            listAvailableTemperature.map(at =>
                (at.id === data.id ? Object.assign(at, { name: data.name, temperature: data.temperature }) : at))
        ));
    }
    const scheduleTemperatureDelete = (data) => {
        //      Manejar que cuando se elimine una temperature tu ya sabes
        let item = listAvailableTemperature.findIndex(element => element.id === data.id)

        if (item !== -1) {
            listAvailableTemperature.splice(item, 1)
            setListAvailableTemperature([...listAvailableTemperature])
        }
    }
    const deleteSchedule = (data) => {
        for (let [key, value] of Object.entries(listSchedule)) {
            for (let point of value.points) {
                if (point.temperature_id === data.id) {
                    delete listSchedule[key]
                    setListSchedule({ ...listSchedule })
                }
            }
        }
    }

    const deletePoint = (data) => {
        for (let point of listPoints) {
            if (data.id === point.temperature_id) {
                let item = listPoints.findIndex(point)
                //console.log(item)
                listPoints.splice(item, 1)
                setlistPoints([...listPoints])
            }
        }
    }

    const handleSocketIoServer = (val) => {
        let socketObject = JSON.parse(val)
        let installSocket = Object.values(socketObject)[0][0]
        let event = installSocket.event
        let data = installSocket.data
        if (event === 21) {
            scheduleTemperatureAdd(data)
        }
        else if (event === 23) {
            scheduleTemperatureUpdate(data)
        }
        else if (event === 22) {
            scheduleTemperatureDelete(data)
            deletePoint(data)
            deleteSchedule(data)
        }
    }

    const handleSocketIoServerA = (val, key) => {
        //console.log("connectionAddTemperature")
        const data = {
            installationId: key,
            deviceUuid: deviceId,
            socketKey: "uYfa9UZ3ytSMWUMGwfpjS8g9xN9rJ6Qm",
        }
        const dataId = { installationId: key }
        socket.emit('heartbeat', dataId)
        socket.emit('fromApp', data)
    }

    React.useEffect(() => {
        setNewSystem(getNewSystem())
        sortListPoints()
        if (!isDemo) {
            socket.off('fromServer')
            socket.off('connect')
            socket.close()
            
                socket.on('connect', (val) => handleSocketIoServerA(val, installActive.id))
                socket.connect()
            
            socket.on('fromServer', (val) => handleSocketIoServer(val))
        }
    }, [listAvailableTemperature])

    // React.useEffect(() => {
    //     sortListPoints()
    // }, [listPoints])

    // const getListAvailableTemperature = () => {
    //     let newObject = []
    //     for (let [key, value] of Object.entries(install.scheduler.availableTemperatures)) {
    //         let aTemp = {
    //             id: key,
    //             name: value.name,
    //             temperature: value.temperature
    //         }
    //         newObject.push(aTemp)
    //     }
    //     return newObject
    // }

    // React.useEffect(() => {
    //     setListAvailableTemperature(getListAvailableTemperature())
    //     if (valueSchUpd !== 1) {
    //         setListAvailableTemperature(getListAvailableTemperature())
    //     }
    //     else {
    //         setListAvailableTemperature(getListAvailableTemperature())
    //     }
    // }, [install])

    const setAllFalse = () => {
        newSystem.forEach(system => {
            system.status = false
            system.zones.forEach(zone => {
                zone.status = false
            })
        })
    }

    const handleClickSystem = (e, systemId) => {
        let system = newSystem.find(system => system.systemId === systemId)
        if (systemChange(systemId)) {
            if (system.status === false) {
                setCheckedSystem(true)
                setSystemID(systemId)
                system.status = true
                system.zones.forEach(zone => {
                    zone.status = true
                    setNewSystem([...newSystem])
                })
            }
            else {
                setCheckedSystem(false)
                system.status = false
                system.zones.forEach(zone => {
                    zone.status = false
                    setNewSystem([...newSystem])
                })
            }
        }
        else {
            setCheckedSystem(true)
            setSystemID(systemId)
            system.status = true
            system.zones.forEach(zone => {
                zone.status = true
                setNewSystem([...newSystem])
            })
            let systemOld = newSystem.find(system => system.systemId === systemID)
            systemOld.status = false
            systemOld.zones.forEach(zone => {
                zone.status = false
                setNewSystem([...newSystem])
            })
        }
    }

    const systemChange = (systemId) => {
        if (systemID === 1) {
            setSystemID(systemId)
            return true
        }
        else if (systemID === systemId) {
            setSystemID(systemId)
            return true
        }
        else {
            setSystemID(systemId)
            return false
        }
    }

    const handleClickZone = (e, zoneId, systemId) => {
        if (systemChange(systemId)) {
            newSystem.forEach(system => {
                system.zones.forEach(zone => {
                    if (zone.zoneId === zoneId) {
                        if (checkedSystem) {
                            if (zone.status) {
                                zone.status = false
                                setNewSystem([...newSystem])
                            }
                            else {
                                zone.status = true
                                setNewSystem([...newSystem])
                            }
                            setCheckedSystem(false)
                        }
                        else {
                            if (zone.status) {
                                zone.status = false
                                setNewSystem([...newSystem])
                            }
                            else {
                                zone.status = true
                                setNewSystem([...newSystem])
                            }
                        }
                    }
                })
            })
        }
        else {
            setAllFalse()
            newSystem.forEach(system => {
                system.zones.forEach(zone => {
                    if (zone.zoneId === zoneId) {
                        if (zone.status) {
                            zone.status = false
                            setNewSystem([...newSystem])
                        }
                        else {
                            zone.status = true
                            setNewSystem([...newSystem])
                        }
                    }
                })
            })
        }
    }
    const zoneNames = (idZone, nameZone) => {
        let newObj = []
        for (let [key, value] of Object.entries(listSystem)) {
            let newSystem = {
                id: key,
                zones: []
            }
            let newZone = []
            for (let k of Object.keys(value.zones)) {
                let nZone = k
                newZone.push(nZone)
            }
            newSystem.zones = newZone
            newObj.push(newSystem)
        }
        return changeName(newObj, idZone, nameZone)
    }
    const changeName = (names, idZone, nameZone) => {
        let zN = ''
        let index = 0
        if (t(`zone.${nameZone}`) === 'Zona' || t(`zone.${nameZone}`) === 'Zone') {
            for (let name of names) {
                for (let zone of name.zones) {
                    if (zone === idZone) {
                        index = (name.zones).indexOf(idZone)
                        break
                    }
                }
            }
            let f = t(`zone.${nameZone}`)
            zN = `${f} ${index + 1}`
        }
        else {
            zN = nameZone
        }
        return zN
    }
    const systemNames = (systemName, idSystem) => {
        let newObj = []
        for (let [key, value] of Object.entries(listSystem)) {
            let nSystem = {
                systemId: key,
                systemName: value.name
            }
            newObj.push(nSystem)
        }
        return changeNameSystem(newObj, systemName, idSystem)
    }
    const changeNameSystem = (names, systemName, idSystem) => {
        let sN = ''
        let index = 0
        if (t(`system.${systemName}`) === 'Sistema' || t(`system.${systemName}`) === 'System' || t(`system.${systemName}`) === 'Système') {
            index = names.findIndex(s => s.systemId === idSystem)
            let f = t(`system.${systemName}`)
            sN = `${f} ${index + 1}`
        }
        else {
            sN = systemName
        }
        return sN
    }

    const handleClose = () => {
        setListFull(false)
    }
    const sortListPoints = () => {
        listPoints.sort((a, b) => (a.time.hour) - (b.time.hour))
    }
    sortListPoints()

    return (
        <div style={{ display: 'flex' }} >
            <Box style={{ marginRight: 20 }}>
                <Typography style={{ marginTop: 20, marginLeft: 5 }} variant='h6'>{t('schedules.schedules_create_zones')}</Typography>
                {newSystem.map(system => (
                    <div key={system.systemId}>
                        <FormControl component='fieldset'>
                            <FormGroup style={{ flexWrap: 'nowrap', width: 175, marginLeft: 12 }}>
                                <Box style={{ display: 'flex' }}>
                                    <IconButton onClick={(e) => { handleClickSystem(e, system.systemId) }} style={{ width: 'fit-content' }} value={system.systemId}>
                                        {system.status ? <CircleCheckedFilled color='primary' /> : <CircleUnchecked />}
                                    </IconButton>
                                    <Typography style={{ alignContent: 'center', display: 'grid' }} variant="body1" >
                                        {systemNames(system.systemName, system.systemId)}
                                    </Typography>
                                </Box>
                                {system.zones.map(zone => (
                                    <Box key={zone.zoneId} style={{ display: 'flex', marginLeft: 12 }}>
                                        <IconButton onClick={(e) => { handleClickZone(e, zone.zoneId, system.systemId) }} style={{ width: 'fit-content' }} value={zone.zoneId}>
                                            {zone.status ? <CircleCheckedFilled color='primary' /> : <CircleUnchecked />}
                                        </IconButton>
                                        <Typography style={{ alignContent: 'center', display: 'grid' }} variant="body2" >
                                            {zoneNames(zone.zoneId, zone.zoneName)}
                                        </Typography>
                                    </Box>
                                ))}
                            </FormGroup>
                        </FormControl>
                    </div>
                ))}
            </Box>
            <Box style={{ margin: '0 auto' }}>
                <Box style={{ display: 'flex', marginTop: '20px' }}>
                    <Typography variant='h6'>{t('schedules.schedules_create_temperatures')}</Typography>
                    <Button color='secondary' style={{ backgroundColor: '#C62828', marginLeft: 'auto', width: 73, height: 36 }} onClick={handleOpenModalAddTemperature}>{t('schedules.schedules_create_add_temperatures')}</Button>
                </Box>
                <Box>
                    <FormControl component='fieldset'>
                        <FormGroup style={{ flexDirection: 'row', width: 450 }}>
                            {
                                listAvailableTemperature.map((availableTemperature, i) => (
                                    <TemperatureSchedule key={i} aTemp={availableTemperature} />
                                ))
                            }
                        </FormGroup>
                    </FormControl>
                </Box>
                <Box>
                    <LinearGaugeAddSchedule />
                </Box>
                <Box>
                    <FormControl component='fieldset'>
                        <FormGroup style={{ width: 'max-content', flexDirection: 'column' }}>
                            {
                                listPoints.map((point, i) => (
                                    <PointSchedule key={i} point={point} />
                                ))
                            }
                        </FormGroup>
                    </FormControl>
                </Box>
                <Box style={{ marginTop: 50 }}>
                    <Button color='secondary' style={{ backgroundColor: '#C62828' }} onClick={handleOpenModalAddPoint}>{t('schedules.schedules_create_add_point')}</Button>
                </Box>
            </Box>
            <ModalAddTemperature
                open={modalAddTemperature}
                onClose={handleCloseModalAddTemperature}
            />
            <ModalAddPoint
                open={modalAddPoint}
                onClose={handleCloseModalAddPoint}
            />
            <Snackbar open={listFull} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">{t('schedules.schedules_create_full_temperatures')}</Alert>
            </Snackbar>
        </div>
    )
}