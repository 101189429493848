import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import React from 'react'
import { UserContext } from '../../../../Context/UserContext'
import { ScheduleContext } from '../../../../Context/ScheduleContext'
import { InstallContext } from '../../../../Context/InstallContext'
import axios from 'axios'
import { URL } from '../../../../Utils/URL'
import { useTranslation } from "react-i18next"
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function ModalDeleteTemperature({ open, onClose, closeUpdate, aTId }) {
    const { isDemo } = React.useContext(UserContext)
    const { listAvailableTemperature, setListAvailableTemperature, setValueSchDel, listSchedule, setListSchedule, listPoints, setlistPoints } = React.useContext(ScheduleContext)
    const { installActive } = React.useContext(InstallContext)
    const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/deleteSchedule`
    const { t } = useTranslation('common')
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const deleteSchedule = () => {
        for (let [key, value] of Object.entries(listSchedule)) {
            for (let point of value.points) {
                if (point.temperature_id === aTId.id) {
                    if (isDemo) {
                        delete listSchedule[key]
                        setListSchedule({ ...listSchedule })
                    }
                    else {
                        const config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        }
                        const data = {
                            installationId: installActive.id,
                            id: key
                        }
                        axios.post(url, data, config)
                            .then((res) => {
                                delete listSchedule[key]
                                setListSchedule({ ...listSchedule })
                            })
                            .catch((e) => {/*console.log(e)*/})
                    }
                }
            }
        }
    }

    const deletePoint = () => {
        for (let point of listPoints) {
            if (aTId.id === point.temperature_id) {
                let item = listPoints.indexOf(point)
                listPoints.splice(item, 1)
                setlistPoints([...listPoints])
            }
        }
    }

    const deleteTemperature = () => {
        if (isDemo) {
            let item = listAvailableTemperature.indexOf(aTId)
            listAvailableTemperature.splice(item, 1)
            setListAvailableTemperature([...listAvailableTemperature])
            setValueSchDel(Math.floor(Math.random() * (100 - 10)) + 10)
            deletePoint()
            deleteSchedule()
            onClose()
            closeUpdate()
        } else {
            const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/deleteTemperature`
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
            const data = {
                installationId: installActive.id,
                id: aTId.id
            }
            axios.post(url, data, config)
                .then((res) => {
                    let item = listAvailableTemperature.indexOf(aTId)
                    listAvailableTemperature.splice(item, 1)
                    setListAvailableTemperature([...listAvailableTemperature])
                    deletePoint()
                    deleteSchedule()                    
                    onClose()
                    closeUpdate()
                })
                .catch((e) => {/*console.log(e)*/})
        }
    }

    return (
        <Dialog open={open}
        style={matches ? {height: '50%'} : {height: '100%'}} 
            onClose={onClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">{t('schedules.schedules_create_modal_delete_temperatures_header')}</DialogTitle>
            <DialogContent>
                <Typography>{t('schedules.schedules_create_modal_delete_temperatures_body1')}</Typography>
                <Typography>{t('schedules.schedules_create_modal_delete_temperatures_body2')}</Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', paddingTop: '20px' }}>
                <Button
                    onClick={deleteTemperature}
                    color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.accept')}
                </Button>
                <Button onClick={onClose} color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
