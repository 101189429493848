import React from 'react'
import NavigationTopBar from '../../Components/NavBars/NavigationTopBar'
//import NavigationBottomBar from '../Components/NavBars/NavigationBottomBar'
import DrawlerLateralBar from '../../Components/NavBars/DrawerLateralBar'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Typography } from '@material-ui/core'
import InstallCards from '../../Components/Installations/InstallCards';
import { UserContext } from '../../Context/UserContext';
import { InstallContext } from '../../Context/InstallContext'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        paddingTop: 110,
        paddingBottom: 284
    }
});

export default function InstallPage({ history }) {
    const classes = useStyles();
    const { userInstall, setUserInstall, isDemo } = React.useContext(UserContext)
    const { setDisabled, setInstallActive } = React.useContext(InstallContext)
    const {t} = useTranslation('common')

    React.useEffect(() => {
        setDisabled(false)
        if(isDemo === false){
            setUserInstall(JSON.parse(localStorage.getItem('userInstall')))
            setInstallActive(JSON.parse(localStorage.getItem('userInstall')))
        }
    }, [])
    
    return (
        <div>
            <NavigationTopBar history={history} />
            <div style={{ display: 'flex' }}>
                <DrawlerLateralBar />
                <Container>
                    <div className={classes.root}>
                        <Typography style={{ marginBottom: 20 }} variant='h3'>{t('install.installs_header')}</Typography>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {typeof Object.keys(userInstall) !== 'undefined' && Object.keys(userInstall).length > 0
                            ? <InstallCards key={userInstall.id} id_inst={userInstall.id} />
                            : <Typography>{t('install.install_empty')}</Typography>}
                        </div>
                    </div>
                </Container>
            </div>
            {/* <NavigationBottomBar /> */}
        </div>
    )
}
