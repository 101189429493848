import { Container, Button, Stepper, Typography, Step, StepLabel, Box, Snackbar } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormUpdateScheduleStart from './FormUpdateScheduleStart'
import FormUpdateScheduleFinish from './FormUpdateScheduleFinish'
import { ScheduleContext } from '../../../Context/ScheduleContext'
import { UserContext } from '../../../Context/UserContext'
import { InstallContext } from '../../../Context/InstallContext'
import { URL } from '../../../Utils/URL'
import axios from 'axios'
import { SystemContext } from "../../../Context/SystemContext"
import MuiAlert from '@material-ui/lab/Alert'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        paddingTop: 110,
        paddingBottom: 284,
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: '#C62828'
        }
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepper: {
        width: '100%',
    },
    nested: {
        margin: '0 auto',
    }
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UpdateScheduleComponent() {
    const history = useHistory()
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0)
    const { t } = useTranslation('common')
    const { weekSelect, setWeekSelect, setDays, setListSchedule, listSchedule, nameSchedule, listPoints, icons, newSystem, editSchedule,
        setManualSystem, type, days } = React.useContext(ScheduleContext)
    const { isDemo } = React.useContext(UserContext)
    const { installActive } = React.useContext(InstallContext)
    const { listSystem, setListSystem } = React.useContext(SystemContext)
    const [showPopupName, setShowPopupName] = React.useState(false)
    const [showPopupDays, setShowPopupDays] = React.useState(false)
    const [showPopupCalendar, setShowPopupCalendar] = React.useState(false)
    const [showPopupListZone, setShowPopupListZone] = React.useState(false)

    // const handleClose = () => {
    //     setManualSystem(false)
    // }
    //console.log(weekSelect)

    const getSteps = () => {
        return [t('stepper.1'), t('stepper.2')]
    }
    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <FormUpdateScheduleStart />
                )
            case 1:
                return (
                    <FormUpdateScheduleFinish />
                )
            default:
                return 'Paso desconocido'
        }
    }

    const steps = getSteps()

    const getZoneSchedule = () => {
        let newObject = []
        for (let ns of newSystem) {
            for (let z of ns.zones) {
                if (z.status) {
                    newObject.push(z.zoneId)
                }
            }
        }
        return newObject
    }

    const formatAndOrder = () => {
        let daysFormatted = []
        let sort = days.sort(function (a, b) { return a - b });
        for (let day of sort) {
            let newObj = {
                day: day.getUTCDate(),
                month: day.getUTCMonth() + 1,
                year: day.getUTCFullYear()
            }

            daysFormatted.push(newObj)
        }
        return daysFormatted
    }

    const changeMan = () => {
        for (let [keySystem, valueSystem] of Object.entries(listSystem)) {
            for (let keyZone of Object.keys(valueSystem.zones)) {
                for (let zoneSch of getZoneSchedule()) {
                    if (zoneSch === keyZone && listSystem[keySystem].mode === 1) {
                        if (isDemo) {
                            listSystem[keySystem].mode = 2;
                            setListSystem({ ...listSystem });
                            setManualSystem(true)
                        }
                        else {
                            const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setSystemMode`;
                            const config = {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem("token"),
                                },
                            };
                            const data = {
                                mode: 2,
                                id: keySystem,
                            };
                            axios.post(url, data, config)
                                .then((res) => {                                    
                                    setManualSystem(true)
                                    listSystem[keySystem].mode = 2;
                                    setListSystem({ ...listSystem });
                                })
                                .catch((e) => {
                                    //console.log(e);
                                });
                        }                        
                    }
                    listSchedule[editSchedule].enabled=false
                    setListSchedule({...listSchedule})
                }
            }
        }
    }

    const handleNext = (e) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        if (nameSchedule === '') {
            setActiveStep(0)
            setShowPopupName(true)
        }
        if (type === 1) {
            if (!weekSelect.monday
                && !weekSelect.sunday
                && !weekSelect.saturday
                && !weekSelect.friday
                && !weekSelect.thursday
                && !weekSelect.wednesday
                && !weekSelect.tuesday) {
                setActiveStep(0)
                setShowPopupDays(true)
            }
        }
        else {
            if (days.length === 0) {
                setShowPopupCalendar(true)
                setActiveStep(0)
            }
        }
        if (e.target.textContent === t('stepper.finish')) {
            if (getZoneSchedule().length === 0) {
                setActiveStep(1)
                setShowPopupListZone(true)
            }
            else {
                if (isDemo) {
                    let newSchedule = {}
                    if (type === 1) {
                        newSchedule[editSchedule] = {
                            enabled: false,
                            name: nameSchedule,
                            mode: icons,
                            weeklyDays: weekSelect,
                            zones: getZoneSchedule(),
                            points: listPoints,
                            type: type,
                            id: editSchedule
                        }
                        setDays([])
                        Object.assign(listSchedule, newSchedule)
                        setListSchedule({ ...listSchedule })
                        changeMan()
                    }
                    else {
                        newSchedule[editSchedule] = {
                            enabled: false,
                            name: nameSchedule,
                            mode: icons,
                            specificDays: formatAndOrder(),
                            zones: getZoneSchedule(),
                            points: listPoints,
                            type: type,
                            id: editSchedule
                        }
                        setWeekSelect({
                            sunday: false,
                            saturday: false,
                            tuesday: false,
                            wednesday: false,
                            thursday: false,
                            friday: false,
                            monday: false
                        })
                        Object.assign(listSchedule, newSchedule)
                        setListSchedule({ ...listSchedule })
                        changeMan()
                    }
                }
                else {
                    const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/updateSchedule`
                    const config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    };
                    let newSchedule = {}
                    if (type === 1) {
                        const data = {
                            installationId: installActive.id,
                            name: nameSchedule,
                            mode: icons,
                            weeklyDays: weekSelect,
                            zones: getZoneSchedule(),
                            points: listPoints,
                            id: editSchedule
                        }
                        newSchedule[editSchedule] = {
                            enabled: false,
                            name: nameSchedule,
                            mode: icons,
                            weeklyDays: weekSelect,
                            zones: getZoneSchedule(),
                            points: listPoints,
                            type: type,
                            id: editSchedule
                        }
                        setDays([])
                        Object.assign(listSchedule, newSchedule)
                        setListSchedule({ ...listSchedule })
                        axios.post(url, data, config)
                            .then((res) => {
                                changeMan()
                            })
                            .catch((e) => {
                                //console.log(e)
                            })
                    }
                    else {
                        const data = {
                            id: editSchedule,
                            installationId: installActive.id,
                            name: nameSchedule,
                            mode: icons,
                            specificDays: formatAndOrder(),
                            zones: getZoneSchedule(),
                            points: listPoints
                        }
                        newSchedule[editSchedule] = {
                            enabled: false,
                            name: nameSchedule,
                            mode: icons,
                            specificDays: formatAndOrder(),
                            zones: getZoneSchedule(),
                            points: listPoints,
                            type: type,
                            id: editSchedule
                        }
                        setWeekSelect({
                            sunday: false,
                            saturday: false,
                            tuesday: false,
                            wednesday: false,
                            thursday: false,
                            friday: false,
                            monday: false
                        })
                        Object.assign(listSchedule, newSchedule)
                        setListSchedule({ ...listSchedule })
                        axios.post(url, data, config)
                            .then((res) => {
                                changeMan()
                            })
                            .catch((e) => {
                                //console.log(e)
                            })
                    }
                }
            }
            history.push('/schedules')
        }
    }
    const handleClose = () => {
        setShowPopupName(false)
        setShowPopupDays(false)
        setShowPopupCalendar(false)
        setShowPopupListZone(false)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const successUpdateSchedule = () => {
        history.push('/schedules')
    }

    return (
        <Container>
            <Box className={classes.root}>
                <Typography variant='h3'>{t('schedules.schedules_update_header')}</Typography>
                <Box className={classes.stepper}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box>
                        {activeStep === steps.length ?
                            successUpdateSchedule
                            :
                            <Box>
                                <Typography component={'span'} className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                <Box>
                                    <Button color='primary'
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                    >
                                        {t('stepper.back')}
                                    </Button>
                                    <Button color='secondary' style={{ backgroundColor: '#C62828' }} onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? t('stepper.finish') : t('stepper.next')}
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            <Snackbar open={showPopupName} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">{t('schedules.schedules_validation_name')}</Alert>
            </Snackbar>
            <Snackbar open={showPopupDays} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">{t('schedules.schedules_validation_days')}</Alert>
            </Snackbar>
            <Snackbar open={showPopupCalendar} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">{t('schedules.schedules_validation_dates')}</Alert>
            </Snackbar>
            <Snackbar open={showPopupListZone} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">{t('schedules.schedules_validation_zones')}</Alert>
            </Snackbar>
            {/* <Snackbar open={manualSystem} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">Sistema cambiado a modo manual</Alert>
            </Snackbar> */}
        </Container>
    )
}
