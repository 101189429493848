import React from 'react'
import NavigationTopBar from '../Components/NavBars/NavigationTopBar'
//import NavigationBottomBar from '../Components/NavBars/NavigationBottomBar'
import DrawlerLateralBar from'../Components/NavBars/DrawerLateralBar'
import { Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Image from 'material-ui-image'
import ImageContact from '../assets/logoColorRed.png'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        paddingTop: 110,
        paddingBottom: 284
    },
    position: {
        position: 'absolute',
        bottom: 10,
        left: '50%',
        [theme.breakpoints.down('xs')]: {
            position: 'inherit',
            bottom: 0,
            left: 0,
            paddingTop: 60
        }
    },
}))

export default function Contact({history}) {
    const classes = useStyles()
    const { t } = useTranslation('common')
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <div>
            <NavigationTopBar history={history} />
            <div style={{display: 'flex'}}>
                <DrawlerLateralBar />
                <div style={{display: 'block'}}>
                    <Container>
                    <div className={classes.root}>
                        <Typography variant= {matches ? 'h5' : "h3"} >{t('drawler.about')}</Typography>
                        <Image style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 'inherit', position: 'inherit' }} src={ImageContact}
                        alt='imagen contact' imageStyle={{ position: 'inherit',width: '56%', paddingLeft: 0, top: 'inherit', left: 'inherit' }} />
                        <Typography className={classes.position} variant= {matches ? 'h6' : "h4"}>{t('about_us.about_us_version')}</Typography>
                    </div>
                    </Container>
                </div>
            </div>
            {/* <NavigationBottomBar /> */}
        </div>
    )
}
