import React, { useState } from 'react'
import { Grid, TextField, Button, Typography, Link as MaterialLink, Box, IconButton } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import '../../App.css'
import { makeStyles } from '@material-ui/core/styles'
import Image from 'material-ui-image'
import ImageLogin from '../../assets/logoColorClaro.png'
import { Link as RouterLink } from 'react-router-dom'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import axios from "axios";
import { UserContext } from '../../Context/UserContext'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import CloseIcon from '@material-ui/icons/Close'
import { URL } from '../../Utils/URL'
import { InstallContext } from '../../Context/InstallContext'
import { ScheduleContext } from '../../Context/ScheduleContext'
import { SystemContext } from '../../Context/SystemContext'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiInputBase-root': {
            color: '#EDE7D2',
        },
        "& .MuiFormLabel-root": {
            color: "#EDE7D2" // or black
        },
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid black'
        }
    },
}));

export default function Login({ history }) {
    const { setAuth, setUserInstall, setIsDemo, setUsernameContext, setPasswordContext, setDeviceId, deviceId } = React.useContext(UserContext)
    const { setInstall, setInstallActive, demoInstall } = React.useContext(InstallContext)
    const { setListAvailableTemperature, setListSchedule, setlistPoints } = React.useContext(ScheduleContext)
    const { setListSystem } = React.useContext(SystemContext)
    const [showPass, setShowPass] = useState(false)
    const [username, setName] = useState("")
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [userBackendError, setUserBackendError] = useState(false)
    const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/auth`
    const classes = useStyles()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))
    const gridStyle = { background: '#C62828', height: '100vh', padding: '40px 40px 0px 40px' }
    const btnstyle = { margin: '12px auto', width: 150, display: 'flex', backgroundColor: '#EDE7D2', color: '#000000' }

    const handleClickShowPass = () => {
        setShowPass(!showPass)
    }

    const fpPromise = FingerprintJS.load()
    const getDeviceId = async () => {
        const fp = await fpPromise
        const result = await fp.get()
        const visitorId = result.visitorId
        setDeviceId(visitorId)
    }
    React.useEffect(() => {  
        getDeviceId()
    }, [])

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (password.length < 6)
            setPasswordError(true)
        else {
            setUsernameContext(username)
            setPasswordContext(password)
            const data = {
                username: (username.trim()).toLowerCase(),
                password: password,
                apiKey: "hDBWRmZytD4q82n3Z3B2Xgsj6MehzGwS",
                deviceUuid: deviceId
            }
            axios.post(url, data)
                .then((res) => {
                    //console.log(res)
                    if (res.status === 206) {
                        localStorage.setItem('token', res.data.token)
                        history.push('/login')
                    }
                    else if (res.status === 200) {
                        localStorage.setItem('token', res.data.token)
                        localStorage.setItem('auth', true)
                        setAuth(localStorage.getItem('auth'))
                        if (Object.keys(res.data.response).length === 1) {
                            for (let [key, value] of Object.entries(res.data.response)) {
                                
                                let userInstall = {
                                    id: key,
                                    icon: value.icon,
                                    name: value.name
                                }
                                localStorage.setItem('userInstall', JSON.stringify(userInstall))
                                setUserInstall({
                                    id: key,
                                    icon: value.icon,
                                    name: value.name
                                })
                                let installActive = {
                                    id: key,
                                    name: value.name,
                                    icon: value.icon
                                }
                                setInstallActive({
                                    id: key,
                                    name: value.name,
                                    icon: value.icon
                                })
                                setIsDemo(false)
                                setUserInstall(JSON.parse(localStorage.getItem('userInstall')))
                                localStorage.setItem('installActive', JSON.stringify(installActive))
                                history.push("/system")
                            }
                        }
                        else {
                            for (let [key, value] of Object.entries(res.data.response)) {
                                let userInstall = {
                                    id: key,
                                    icon: value.icon,
                                    name: value.name
                                }
                                localStorage.setItem('userInstall', JSON.stringify(userInstall))
                                setUserInstall({
                                    id: key,
                                    icon: value.icon,
                                    name: value.name
                                })
                                setIsDemo(false)
                                history.push("/install")
                            }
                        }
                    }
                })
                .catch((e) => {
                    //console.log(e)
                    setUserBackendError(true)
                })
        }
    }
    const getListAvailableTemperature = () => {
        let newObject = []
        Object.entries(demoInstall.scheduler.availableTemperatures).forEach(([key, value]) => {
            let aTemp = {
                id: key,
                name: value.name,
                temperature: value.temperature
            }
            newObject.push(aTemp)
        })
        return newObject
    }

    const getPoints = () => {
        let p = []
        Object.entries(demoInstall.scheduler.availableTemperatures).forEach(([key, value]) => {
            let pointTemp = {
                temperature_id: key,
                time: {
                    hour: 0,
                    minute: 0
                }
            }
            p.push(pointTemp)
        })
        return p
    }

    const demoClick = () => {
        localStorage.removeItem("auth")
        localStorage.removeItem('installActive')
        localStorage.removeItem('userInstall')
        localStorage.removeItem('install')
        localStorage.removeItem('idSchedule')
        setIsDemo(true)
        setUserInstall({
            id: 'ins_161c3effb59542bb816cf235a0dbf73c',
            icon: 1,
            name: 'default_installation_name'
        })
        setListAvailableTemperature(getListAvailableTemperature())
        setlistPoints(getPoints())
        setInstall(demoInstall)
        setListSystem(demoInstall.systems)
        setListSchedule(demoInstall.scheduler.schedules)
        setInstallActive({
            id: 'ins_161c3effb59542bb816cf235a0dbf73c',
            name: 'default_installation_name',
            icon: 1
        })
        history.push('/system')
        setAuth(true)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    }

    return (
        <Grid container style={gridStyle} direction="row" justify="center" alignItems="center" >
            <Grid align='center' >
                <Image style={{ backgroundColor: 'transparent', position: 'inherit', paddingTop: 'inherit' }} src={ImageLogin}
                    alt='imagen portada' imageStyle={{ objectFit: 'contain', width: '56%', height: '12%', left: '0', paddingLeft: '22%', paddingRight: '16%', paddingTop: '57px' }} />
            </Grid>
            <form style={{ height: '30%' }} onSubmit={handleFormSubmit}>
                <TextField
                    label='Usuario:'
                    placeholder='usuario@email.com'
                    type='email'
                    fullWidth
                    required
                    className={classes.root}
                    onChange={(e) => setName(e.target.value)}                    
                    style={matches ? {width: 190, display: 'grid'} : {width: 438}}
                />
                <Box style={{ marginTop: '12px' }}>
                    <TextField
                        label='Contraseña:'
                        placeholder='contraseña'
                        type={showPass ? 'text' : 'password'}
                        fullWidth
                        required
                        className={classes.root}
                        onChange={handleChangePassword}
                        InputProps={{
                            endAdornment: <IconButton onClick={handleClickShowPass} onMouseDown={handleMouseDownPassword}>
                                {showPass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        }}
                        style={matches ? {width: 190, display: 'grid'} : {width: 438}}
                    />
                </Box>
                <Button
                    type='submit'
                    color='secondary'
                    variant="contained"
                    style={btnstyle}
                    fullWidth>
                    Entrar
                </Button>
                {passwordError ? <Alert action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setPasswordError(false); }}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>} variant="filled" severity="warning">
                    La contraseña debe tener más de 6 letras
                </Alert> : null}
                {userBackendError ? <Alert action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setUserBackendError(false); }}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>} variant="filled" severity="warning">
                    Revise que los datos insertados sean correctos
                </Alert> : null}
                <Typography style={{ textAlign: 'center', padding: '0px 50px' }} variant="caption">
                    <MaterialLink style={{ color: '#EDE7D2' }} component={RouterLink} to='/register'>
                        <p style={{ marginTop: 0, marginBottom: 0 }}>Aun no tienes cuenta?</p>
                    </MaterialLink >
                </Typography>
                <Typography style={{ textAlign: 'center', padding: '0px 50px' }} variant="caption">
                    <MaterialLink style={{ color: '#EDE7D2' }} component={RouterLink} to='/forgetPass'>
                        <p style={{ marginTop: 0, marginBottom: 0 }}>He olvidado la contraseña</p>
                    </MaterialLink >
                </Typography>
                <Button
                    type='submit'
                    color='secondary'
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                    onClick={demoClick}
                >
                    Demo
                </Button>
            </form>
        </Grid>
    )
}
