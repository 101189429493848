import React from "react";
import {
    Card,
    Box,
    CardHeader,
    CardContent,
    CardActions,
    Typography,
    IconButton,
    Link as MaterialLink,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import ModalInstall from "./Modals/ModalEditInstall"
import { Link as RouterLink } from "react-router-dom"
import Icon from "@mdi/react"
import { mdiHome } from "@mdi/js"
import { mdiDelete } from '@mdi/js'
import { mdiOfficeBuilding  } from "@mdi/js"
import ModalDeleteInstall from './Modals/ModalDeleteInstall'
import { InstallContext } from "../../Context/InstallContext"
import { useTranslation } from 'react-i18next'
import {UserContext} from '../../Context/UserContext'


const useStyles = makeStyles((theme) => ({
    root: {
        width: 220,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    bodyContext: {
        textAlign: "center",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    underlineHover: {
        "&:hover": {
            textDecoration: "none",
        },
    },
}));

export default function InstallCards({ id_inst }) {
    const classes = useStyles()
    const [modalInst, setModalInst] = React.useState(false)
    const [modalDeleteInst, setModalDeleteInst] = React.useState(false)
    const {setInstallActive, installActive} = React.useContext(InstallContext)
    const {isDemo, socket, deviceId, setUserInstall, userInstall} = React.useContext(UserContext)
    const {t} = useTranslation('common')

    const handleOpenModal = () => {
        setModalInst(true)
    };
    const handleCloseModal = () => {
        setModalInst(false)
    };
    const handleOpenDeleteInstall = () => {
        setModalDeleteInst(true)
    }
    const handleCloseDeleteInstall = () => {
        setModalDeleteInst(false)
    }
    const formattInstallName = (name) => {
        if(name.length >= 7)
            return name.substring(0,10) + '...';
        else 
            return name
    }
    
    const changeInstallNameIcon = (data) => {
        setUserInstall({...userInstall, icon: data.value.icon, name: data.value.name})
        setInstallActive({...installActive, icon: data.value.icon, name: data.value.name })
    }

    const handleSocketIoServer = (value) => {
        let socketObject = JSON.parse(value)
        let installSocket = Object.values(socketObject)[0][0]
        let event = installSocket.event
        let data = installSocket.data
        if(event === 11) {
            changeInstallNameIcon(data)
        }
    }

    const handleSocketIoServerA = (val, key) => {
        //console.log("connectionInstall")        
        const data = {
            installationId: key,
            deviceUuid: deviceId,
            socketKey: "uYfa9UZ3ytSMWUMGwfpjS8g9xN9rJ6Qm",
        }
        socket.emit('fromApp', data)
        const dataId = {installationId: key}        
        socket.emit('heartbeat', dataId)        
    }

    React.useEffect(() => {
        if(!isDemo){
            socket.on('fromServer', (val) => handleSocketIoServer(val))
            socket.on('connect', (val) => handleSocketIoServerA(val, id_inst))
            socket.connect()
        }
    }, [])

    const handleClick = () => {        
        if(!isDemo){            
            localStorage.setItem('installActive', JSON.stringify(installActive))
        }
    }

    const setInstallName = () => {
        if(t(`install.${userInstall.name}`) === 'Home' || t(`install.${userInstall.name}`) === 'Casa' || t(`install.${userInstall.name}`) === 'Maison'){
            return t(`install.${userInstall.name}`)
        }
        else {
            return userInstall.name
        }
    }

    return (
        <Box boxShadow={4} m={2}>
            <Card className={classes.root} style={{ boxShadow: "none" }}>
                <CardHeader className={classes.bodyContext}
                title={
                    <Box>
                        <IconButton onClick={handleOpenModal}>
                            <Typography variant="h5">{formattInstallName(setInstallName())}</Typography>
                        </IconButton>
                    </Box>}
                    />
                <CardContent className={classes.bodyContext}>
                    <Box>
                        <MaterialLink
                            className={classes.underlineHover}
                            onClick={handleClick }
                            to={'/system'} 
                            component={RouterLink}>
                            {userInstall.icon === 1 ? 
                                <Icon path={mdiHome} size={3} />
                            : 
                                <Icon path={mdiOfficeBuilding} size={3} />
                            }
                        </MaterialLink>
                    </Box>
                </CardContent>
                <CardActions style={{justifyContent: 'flex-end'}}>
                    <IconButton onClick={handleOpenDeleteInstall}>
                        <Icon path={mdiDelete} size={1} />
                    </IconButton>
                </CardActions>
                <ModalInstall
                    name={setInstallName()}
                    icon={userInstall.icon}
                    id_inst={id_inst}
                    open={modalInst}
                    onClose={handleCloseModal}
                />
                <ModalDeleteInstall
                    name={setInstallName()}
                    id_inst={id_inst}
                    open={modalDeleteInst}
                    onClose={handleCloseDeleteInstall}
                />
            </Card>
        </Box>
    );
}
