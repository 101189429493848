export const URL_DATA = [{
    // RELEASE
    BACKEND_PREFIX : 'https://',
    BACKEND_URL : 'zoning.madel.ontesting.es',
    BACKEND_SOCKET_URL : 'zoning.madel.ontesting.es:444',
    BACKEND_API_PATH : '/api/v1'
},{
    // DEVELOPMENT
    BACKEND_PREFIX : 'http://',
    BACKEND_URL : '192.168.1.198:8080',
    BACKEND_SOCKET_URL : '192.168.1.198:8081',
    BACKEND_API_PATH : ''
}]

let index = 0; // 0 = release, 1 = development

export const URL = {
    BACKEND_PREFIX : URL_DATA[index].BACKEND_PREFIX,
    BACKEND_URL : URL_DATA[index].BACKEND_URL,
    BACKEND_SOCKET_URL : URL_DATA[index].BACKEND_SOCKET_URL,
    BACKEND_API_PATH : URL_DATA[index].BACKEND_API_PATH
}