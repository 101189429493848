import React from 'react'
import { AppBar, Typography, Toolbar, IconButton, Container, Menu, MenuItem, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'
import axios from 'axios'
import { UserContext } from '../../Context/UserContext'
import { InstallContext } from '../../Context/InstallContext'
import { ScheduleContext } from '../../Context/ScheduleContext'
import { SystemContext } from '../../Context/SystemContext'
import { URL } from '../../Utils/URL'
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next'
import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles(theme => ({
    appBarStyles: {
        backgroundColor: `#C62828`,
        position: 'fixed',
    },
    navDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`,
        maxWidth: `none`
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `white`
    }
}))

export default function NavigationTopBar({ history }) {
    const { isDemo, socket, hideDrawler, setHideDrawler } = React.useContext(UserContext)
    const { setInstall, setListInstalls } = React.useContext(InstallContext)
    const { setListAvailableTemperature, setListSchedule } = React.useContext(ScheduleContext)
    const { setListSystem, setListZones } = React.useContext(SystemContext)
    const {t} = useTranslation('common')
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [anchorLg, setAnchorLg] = React.useState(null)
    const open = Boolean(anchorEl)
    const openLg = Boolean(anchorLg)
    const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/logout`

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleClick = () => {
        localStorage.removeItem("auth")
        localStorage.removeItem("token")
        localStorage.removeItem('installActive')
        localStorage.removeItem('userInstall')
        localStorage.removeItem('install')
        localStorage.removeItem('idSchedule')
        if (isDemo) {
            history.push("/");
            setInstall(Object.assign({}, {}))
            setListInstalls(Object.assign({}, {}))
            setListSchedule(Object.assign({}, {}))
            setListAvailableTemperature(Object.assign({}, {}))
            setListSystem(Object.assign({}, {}))
            setListZones(Object.assign({}, {}))
        }
        else {            
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
            axios.post(url, config)
                .then((res) => {  
                    socket.off('fromServer')
                    socket.off('connect')
                    socket.close()
                    history.push("/");                    
                    localStorage.removeItem("auth")
                    localStorage.removeItem('installActive')
                    localStorage.removeItem('userInstall')
                    localStorage.removeItem('install')
                    localStorage.removeItem('idSchedule')
                    setInstall(Object.assign({}, {}))
                    setListInstalls(Object.assign({}, {}))
                    setListSchedule(Object.assign({}, {}))
                    setListAvailableTemperature(Object.assign({}, {}))
                    setListSystem(Object.assign({}, {}))
                    setListZones(Object.assign({}, {}))
                })
                .catch((e) => {
                    //console.log(e)
                })
        }
    };
    const handleCloseLang = () => {
        setAnchorLg(null);
    }

    const handleMenuLang = (event) => {
        setAnchorLg(event.currentTarget);
    }
    const { i18n } = useTranslation('common')

    const handleClickLang = (lang) => {
        i18n.changeLanguage(lang)
        setAnchorLg(null)
    }
    const menuHide = () => {
        setHideDrawler(!hideDrawler)
    }

    return (
        <div>
            <AppBar className={classes.appBarStyles} position='static' >
                <Toolbar>
                    <Container className={classes.navDisplayFlex}>                    
                        <IconButton onClick={menuHide} color="secondary" edge="start">
                                <MenuIcon />
                            <Typography style={{paddingLeft: 40}} variant='h4'>Zoning</Typography>
                        </IconButton>
                        <Box>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="secondary"
                                onClick={handleMenuLang}
                            >
                                <LanguageIcon fontSize={'large'} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorLg}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={openLg}
                                onClose={handleCloseLang}
                            >
                                <MenuItem onClick={() => handleClickLang('en')}>EN</MenuItem> 
                                <MenuItem onClick={() => handleClickLang('es')}>ES</MenuItem>
                                <MenuItem onClick={() => handleClickLang('fr')}>FR</MenuItem>
                                <MenuItem onClick={() => handleClickLang('it')}>IT</MenuItem> 
                            </Menu> 
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="secondary"
                                onClick={handleMenu}
                            >
                                <AccountCircle fontSize={'large'} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClick}>{t('banner.exit_app')}</MenuItem>
                            </Menu>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
        </div>
    )
}