import React from 'react'
import { Dialog, Typography, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import {UserContext} from '../../../Context/UserContext'
import axios from 'axios'
import {URL} from '../../../Utils/URL'
import { useTranslation } from 'react-i18next'

export default function ModalDeleteInstall({name, open, onClose}) {
    const {isDemo, userInstall, setUserInstall} = React.useContext(UserContext)
    const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/deleteInstallation`
    const {t} = useTranslation('common')


    const deleteInstall = () => {
        if(isDemo){        
            setUserInstall({})
        }
        else {
            //let find = Object.entries(userInstall).find(([k,v])=> k === userInstall.id)
            //console.log(find)
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
            const data = {
                id: userInstall.id
            }
            axios.post(url, data, config)
            .then((res)=> {
                setUserInstall({}) 
            })
            .catch((e)=> {
                //console.log(e)
            })
        }
    }
    
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description" >
            <DialogTitle id="scroll-dialog-title">{t('install.header_modal_delete_install')}</DialogTitle>
            <DialogContent style={{textAlign: 'center'}}>
                <Typography variant='body1'>{name}</Typography>
            </DialogContent>
            <DialogActions style={{justifyContent: 'center', paddingTop: '20px'}}>
                <Button 
                onClick={() => deleteInstall()} 
                color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.accept')}
                </Button>
                <Button onClick={onClose} color='secondary' style={{ backgroundColor: '#C62828' }}>
                {t('modals.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
