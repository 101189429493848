import { Box, Typography, IconButton, Switch, Paper, Snackbar } from "@material-ui/core"
import MuiAlert from '@material-ui/lab/Alert'
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Icon from "@mdi/react"
import { mdiPencil } from "@mdi/js"
import { mdiDelete } from "@mdi/js"
import { SystemContext } from "../../Context/SystemContext"
import { useTranslation } from "react-i18next"
import ModalDeleteSchedule from "./Modals/ModalDeleteSchedule"
import axios from "axios"
import { UserContext } from "../../Context/UserContext"
import { ScheduleContext } from "../../Context/ScheduleContext"
import { InstallContext } from "../../Context/InstallContext"
import ModalSetAutoMode from "./Modals/ModalSetAutoMode"
import LinearGauge from "./LinearGauge/LinearGauge"
import { URL } from '../../Utils/URL'
import BrigthIcon from "../../assets/iconsImages/mode_heat.png"
import ColdIcon from "../../assets/iconsImages/mode_cold.png"
import FanIcon from "../../assets/iconsImages/mode_fan.png"
import DryIcon from "../../assets/iconsImages/mode_dry.png"
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
    icon: {
        "& .MuiIconButton-label": {
            justifyContent: "flex-end",
        },
    },
    nested: {
        padding: 20,
        margin: 20,
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Schedules({ idSchedule, history }) {
    const { /*setListAvailableTemperature,*/ listSchedule, setListSchedule, setEditSchedule, setManualSystem, manualSystem, setNameSchedule, setIcons, setlistPoints, setDays, setWeekSelect, setType } = React.useContext(ScheduleContext)
    const classes = useStyles()
    const { t } = useTranslation('common')
    const [modalDeleteSchedule, setModalDeleteSchedule] = React.useState(false)
    const { listSystem, setListSystem } = React.useContext(SystemContext)
    const { isDemo, socket, deviceId } = React.useContext(UserContext)
    const { installActive } = React.useContext(InstallContext)
    const [modalAutoMode, setModalAutoMode] = React.useState(false)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const autoMode = () => {
        for (let [keyS, valueS] of Object.entries(listSystem)) {
            for (let keyZ of Object.keys(valueS.zones)) {
                for (let zoneS of listSchedule[idSchedule].zones) {
                    if (keyZ === zoneS) {
                        if (isDemo) {
                            listSystem[keyS].mode = 1;
                            listSystem[keyS].state.mode = listSchedule[idSchedule].mode
                            setListSystem({ ...listSystem });
                            break
                        }
                        else {
                            const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setSystemMode`;
                            const config = {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem("token"),
                                },
                            };
                            const data = {
                                mode: 1,
                                id: keyS,
                            };
                            axios.post(url, data, config)
                                .then((res) => {
                                    listSystem[keyS].mode = 1;
                                    listSystem[keyS].state.mode = listSchedule[idSchedule].mode
                                    setListSystem({ ...listSystem });
                                })
                                .catch((e) => {
                                    //console.log(e);
                                });
                        }
                    }
                }
            }
        }
    }

    const manualMode = () => {
        for (let [keyS, valueS] of Object.entries(listSystem)) {
            for (let keyZ of Object.keys(valueS.zones)) {
                for (let zoneS of listSchedule[idSchedule].zones) {
                    if (keyZ === zoneS) {
                        if (isDemo) {
                            if(listSystem[keyS].mode === 1){
                                setManualSystem(true)
                            }
                            listSystem[keyS].mode = 2;
                            setListSystem({ ...listSystem });
                            break
                        }
                        else {
                            const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setSystemMode`;
                            const config = {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem("token"),
                                },
                            };
                            const data = {
                                mode: 2,
                                id: keyS,
                            };
                            axios.post(url, data, config)
                                .then((res) => {
                                    if(listSystem[keyS].mode === 1){
                                        setManualSystem(true)
                                        listSystem[keyS].mode = 2;
                                        setListSystem({ ...listSystem })
                                    }
                                })
                                .catch((e) => {
                                    //console.log(e);
                                });
                                break
                        }
                    }
                }
            }
        }
    }

    const handleChange = (e) => {
        //listSchedule[idSchedule].enabled = !listSchedule[idSchedule].enabled
        if (e.target.checked) {
            if (isDemo) {
                setModalAutoMode(true);
                listSchedule[idSchedule].enabled = true;
                setListSchedule({ ...listSchedule });
            } else {
                setModalAutoMode(true);
                const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/enableAndDisableSchedule`;
                const config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                };
                const data = {
                    installationId: installActive.id,
                    id: idSchedule,
                    enabled: true,
                };
                axios
                    .post(url, data, config)
                    .then((res) => {
                        listSchedule[idSchedule].enabled = true;
                        setListSchedule({ ...listSchedule });
                        //console.log(res);
                    })
                    .catch((e) => {/*console.log(e)*/})
            }
        }
        else {
            if (isDemo) {
                //poner en modo manual el sistema y sacar un popup
                setModalAutoMode(false);
                manualMode()
                listSchedule[idSchedule].enabled = false;
                setListSchedule({ ...listSchedule });
            } else {
                setModalAutoMode(false);
                const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/enableAndDisableSchedule`;
                const config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                };
                const data = {
                    installationId: installActive.id,
                    id: idSchedule,
                    enabled: false,
                };
                axios
                    .post(url, data, config)
                    .then((res) => {                        
                        console.log(res);
                        manualMode()
                        listSchedule[idSchedule].enabled = false;
                        setListSchedule({ ...listSchedule });
                    })
                    .catch((e) => console.log(e));
            }
        }
    }
    const zoneNames = (idZone, nameZone) => {
        let newObj = []
        for (let [key, value] of Object.entries(listSystem)) {
            let newSystem = {
                id: key,
                zones: []
            }
            let newZone = []
            for (let k of Object.keys(value.zones)) {
                let nZone = k
                newZone.push(nZone)
            }
            newSystem.zones = newZone
            newObj.push(newSystem)
        }
        return changeNameZone(newObj, idZone, nameZone)
    }
    const changeNameZone = (names, idZone, nameZone) => {
        let zN = ''
        let index = 0
        if (t(`zone.${nameZone}`) === 'Zona' || t(`zone.${nameZone}`) === 'Zone') {
            for (let name of names) {
                for (let zone of name.zones) {
                    if (zone === idZone) {
                        index = (name.zones).indexOf(idZone)
                        break
                    }
                }
            }
            let f = t(`zone.${nameZone}`)
            zN = `${f} ${index + 1}`
        }
        else {
            zN = nameZone
        }
        return zN
    }

    const getZoneNameById = (idZone) => {
        for (let value of Object.values(listSystem)) {
            for (let [k, v] of Object.entries(value.zones)) {
                if (k === idZone) {
                    return zoneNames(idZone, v.name);
                }
            }
        }
    };
    const closeModal = () => {
        autoMode()
        setModalAutoMode(false);
    }

    const handleCloseModalAuto = () => {
        //poner en mode manual el sistema
        setModalAutoMode(false);
        if (isDemo) {
            setModalAutoMode(false);
            listSchedule[idSchedule].enabled = true;
            setListSchedule({ ...listSchedule });
        } else {
            const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/enableAndDisableSchedule`;
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            const data = {
                installationId: installActive.id,
                id: idSchedule,
                enabled: true,
            };
            axios
                .post(url, data, config)
                .then((res) => {
                    listSchedule[idSchedule].enabled = true;
                    setListSchedule({ ...listSchedule });
                    console.log(res);
                })
                .catch((e) => console.log(e));
        }
    };

    const handleOpenDeleteSchedule = () => {
        setModalDeleteSchedule(true);
    };
    const handleCloseDeleteSchedule = () => {
        setModalDeleteSchedule(false);
    };
    const handleClose = () => {
        setManualSystem(false)
    }
    const getSystemName = () => {
        let systemName = ''
        for (let z of listSchedule[idSchedule].zones) {
            for (let value of Object.values(listSystem)) {
                for (let k of Object.keys(value.zones)) {
                    if (z === k) {
                        systemName = value.name
                        break
                    }
                }
            }
        }
        return systemName
    }

    const formatDate = () => {
        let newObj = []
        if (listSchedule[idSchedule].specificDays !== undefined) {
            for (let day of listSchedule[idSchedule].specificDays) {
                let newDay = new Date(day.year, day.month-1, day.day)
                newObj.push(newDay)
            }
        }
        setDays(newObj)
    }

    const formatWeek = () => {
        setWeekSelect({
            sunday: listSchedule[idSchedule].weeklyDays.sunday,
            saturday: listSchedule[idSchedule].weeklyDays.saturday,
            tuesday: listSchedule[idSchedule].weeklyDays.tuesday,
            wednesday: listSchedule[idSchedule].weeklyDays.wednesday,
            thursday: listSchedule[idSchedule].weeklyDays.thursday,
            friday: listSchedule[idSchedule].weeklyDays.friday,
            monday: listSchedule[idSchedule].weeklyDays.monday
        })
    }

    const getValue = () => {
        if (listSchedule[idSchedule].type === 2) {
            setType(2)
            formatDate()
            setWeekSelect({
                sunday: false,
                saturday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                monday: false
            })
        }
        else {
            formatWeek()
            setType(1)
            setDays([])
        }
    }

    const toEditSchedule = () => {
        setEditSchedule(idSchedule)
        if(!isDemo){
            localStorage.setItem('idSchedule', idSchedule)
        } 
        getValue()      
        setNameSchedule(listSchedule[idSchedule].name)
        setIcons(listSchedule[idSchedule].mode) 
        setlistPoints(listSchedule[idSchedule].points)
        history.push('/editSchedule')
    }
    //socket inicio
    const createNewSchedule = (data) => {
        let newSchedule = {}
        if(data.type === 1){
            newSchedule[data.id] = {
                enabled: data.enabled,
                name: data.name,
                mode: data.mode,
                weeklyDays: data.weeklyDays,
                zones: data.zones,
                points: data.points,
                type: data.type,
                id: data.id
            }
            Object.assign(listSchedule, newSchedule)
            setListSchedule({ ...listSchedule })
        }
        else {
            newSchedule[data.id] = {
                enabled: data.enabled,
                name: data.name,
                mode: data.mode,
                specificDays: data.specificDays,
                zones: data.zones,
                points: data.points,
                type: data.type,
                id: data.id
            }
            Object.assign(listSchedule, newSchedule)
            setListSchedule({ ...listSchedule })
        }        
    }
    const updateSchedule = (data) => {
        let newSchedule = {}
        if(data.type === 1){
            newSchedule[data.id] = {
                enabled: data.enabled,
                name: data.name,
                mode: data.mode,
                weeklyDays: data.weeklyDays,
                zones: data.zones,
                points: data.points,
                type: data.type,
                id: data.id
            }
            Object.assign(listSchedule, newSchedule)
            setListSchedule({ ...listSchedule })
        }
        else {
            newSchedule[data.id] = {
                enabled: data.enabled,
                name: data.name,
                mode: data.mode,
                specificDays: data.specificDays,
                zones: data.zones,
                points: data.points,
                type: data.type,
                id: data.id
            }
            Object.assign(listSchedule, newSchedule)
            setListSchedule({ ...listSchedule })
        }   
    }
    const deleteSchedule = (data) => {
        let cumplio = false
        if(listSchedule[data.id]){            
        if(listSchedule[data.id].enabled){
            for(let keyZoneS of Object.keys(listSystem)){
                for(let keyZone of Object.keys(listSystem[keyZoneS].zones)){
                    if(!cumplio){
                        for(let zone of listSchedule[data.id].zones){
                            if(keyZone === zone){
                                //console.log(listSystem[keyZoneS].mode === 1)
                                if(listSystem[keyZoneS].mode === 1){                                    
                                    listSystem[keyZoneS].mode = 2
                                    setListSystem({...listSystem})
                                    delete listSchedule[data.id]
                                    setListSchedule({ ...listSchedule })
                                    cumplio = true
                                }                                
                            }
                        }
                    }
                }
            }
        }
        else {
            delete listSchedule[data.id]
            setListSchedule({ ...listSchedule })
        } 
        }  
    }
    const enableSchedule = (data) => {
        listSchedule[data.id].enabled = data.enabled
        setListSchedule({ ...listSchedule })
        for (let [keyS, valueS] of Object.entries(listSystem)) {
            for (let keyZ of Object.keys(valueS.zones)) {
                for (let zoneS of listSchedule[data.id].zones) {
                    if (keyZ === zoneS && listSystem[keyS].mode === 1) {                        
                            setManualSystem(true)
                            listSystem[keyS].mode = 2;
                            setListSystem({ ...listSystem })
                        
                    }
                }
            }
        }
    }

    const handleSocketIoServer = (val) => {
        let socketObject = JSON.parse(val)
        let installSocket = Object.values(socketObject)[0][0]
        let event = installSocket.event
        let data = installSocket.data
        if(event === 14) {            
            createNewSchedule(data)
        }
        else if(event === 15){
            deleteSchedule(data)
        }
        else if(event === 16) {
            updateSchedule(data)
        }
        else if(event === 17) {
            enableSchedule(data)
        }
    }

    const handleSocketIoServerA = (val, key) => {
        //console.log("connectionSchedule")
        const data = {
            installationId: key,
            deviceUuid: deviceId,
            socketKey: "uYfa9UZ3ytSMWUMGwfpjS8g9xN9rJ6Qm",
        }
        const dataId = { installationId: key }
        socket.emit('heartbeat', dataId)
        socket.emit('fromApp', data)
    }

    React.useEffect(() => {
        if (!isDemo) {
            socket.on('connect', (val) => handleSocketIoServerA(val, installActive.id))
            socket.connect()
            socket.on('fromServer', (val) => handleSocketIoServer(val))
        }
    },[listSchedule])
    //socket fin


    return (
        <Box className={classes.nested} component={Paper}>
            <Typography variant="h5">{listSchedule[idSchedule].name}</Typography>
            <Box style={{ display: "flex" }}>
                <Box style={{ marginRight: "auto" }}>
                    <Box style={{ paddingTop: 20 }}>
                        {listSchedule[idSchedule].type === 2 ?
                            <Box>
                                <Typography variant="h5">{t('schedules.schedules_calendar')}</Typography>
                                <Typography variant="body2">{listSchedule[idSchedule].specificDays[0].day}/{listSchedule[idSchedule].specificDays[0].month}/{listSchedule[idSchedule].specificDays[0].year} - {listSchedule[idSchedule].specificDays[(listSchedule[idSchedule].specificDays).length - 1].day}/{listSchedule[idSchedule].specificDays[(listSchedule[idSchedule].specificDays).length - 1].month}/{listSchedule[idSchedule].specificDays[(listSchedule[idSchedule].specificDays).length - 1].year}</Typography>
                            </Box>
                            : <Box>
                                <Typography variant="h5">{t('schedules.schedules_days')}</Typography>                                
                                {
                                    listSchedule[idSchedule].weeklyDays.sunday === true && listSchedule[idSchedule].weeklyDays.saturday === true 
                                    && listSchedule[idSchedule].weeklyDays.tuesday === true && listSchedule[idSchedule].weeklyDays.wednesday === true
                                    && listSchedule[idSchedule].weeklyDays.thursday === true && listSchedule[idSchedule].weeklyDays.friday === true
                                    && listSchedule[idSchedule].weeklyDays.monday === true 
                                    ?
                                    <Typography variant="body2">{t('schedules.schedules_all_days_selected')}</Typography>: <Box>
                                    {listSchedule[idSchedule].weeklyDays.sunday === true ? (
                                        <Typography variant="body2">{t('schedules.schedules_create_type_2_sunday')}</Typography>
                                    ) : null}
                                    {listSchedule[idSchedule].weeklyDays.saturday === true ? (
                                        <Typography variant="body2">{t('schedules.schedules_create_type_2_saturday')}</Typography>
                                    ) : null}
                                    {listSchedule[idSchedule].weeklyDays.tuesday === true ? (
                                        <Typography variant="body2">{t('schedules.schedules_create_type_2_tuesday')}</Typography>
                                    ) : null}
                                    {listSchedule[idSchedule].weeklyDays.wednesday === true ? (
                                        <Typography variant="body2">{t('schedules.schedules_create_type_2_wednesday')}</Typography>
                                    ) : null}
                                    {listSchedule[idSchedule].weeklyDays.thursday === true ? (
                                        <Typography variant="body2">{t('schedules.schedules_create_type_2_thursday')}</Typography>
                                    ) : null}
                                    {listSchedule[idSchedule].weeklyDays.friday === true ? (
                                        <Typography variant="body2">{t('schedules.schedules_create_type_2_friday')}</Typography>
                                    ) : null}                                
                                    {listSchedule[idSchedule].weeklyDays.monday === true ? (
                                        <Typography variant="body2">{t('schedules.schedules_create_type_2_monday')}</Typography>
                                    ) : null}
                                    </Box>
                                }
                            </Box>
                        }
                    </Box>
                    <Box style={{ paddingTop: 15 }}>
                        <Typography variant="h5">{t('schedules.schedules_schedule')}</Typography>
                        <LinearGauge points={listSchedule[idSchedule].points} />
                    </Box>
                </Box>
                <Box style={ matches ? {margin: "0 auto", paddingTop: 20, width: 100} : { margin: "0 auto", paddingTop: 20 }}>
                    <Typography variant="h5">{t('schedules.schedules_zones')}</Typography>
                    {listSchedule[idSchedule].zones.map((zone, i) => (
                        <Typography key={i} variant="body1" >
                            - {getZoneNameById(zone)}
                        </Typography>
                    ))}
                </Box>
                <Box style={{ marginLeft: "auto", display: "grid" }}>
                    <Box>
                        <IconButton color="inherit">
                            {listSchedule[idSchedule].mode === 1
                                ? <img style={{ width: 27 }} src={BrigthIcon} alt="mode hot" /> :
                                listSchedule[idSchedule].mode === 3
                                    ? <img style={{ width: 27 }} src={ColdIcon} alt="mode cold" /> :
                                    listSchedule[idSchedule].mode === 6
                                        ? <img style={{ width: 19, height: 26 }} src={DryIcon} alt="mode dry" /> :
                                        <img style={{ width: 27 }} src={FanIcon} alt="mode fan" />
                            }
                        </IconButton>
                        <IconButton color="inherit" onClick={toEditSchedule}>
                            <Icon path={mdiPencil} size={1} />
                        </IconButton>
                    </Box>
                    <Switch
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        checked={listSchedule[idSchedule].enabled}
                        onChange={handleChange}
                    />
                </Box>
            </Box>
            <Box
                style={{
                    marginLeft: "auto",
                    marginBottom: 0,
                    display: "grid",
                    width: 53,
                }}>
                <IconButton
                    onClick={handleOpenDeleteSchedule}
                    className={classes.icon}
                    color="inherit"
                >
                    <Icon path={mdiDelete} size={1} />
                </IconButton>
            </Box>
            <ModalDeleteSchedule
                name={t(listSchedule[idSchedule].name)}
                id_schedule={idSchedule}
                open={modalDeleteSchedule}
                onClose={handleCloseDeleteSchedule}
            />
            <ModalSetAutoMode
                open={modalAutoMode}
                onClose={handleCloseModalAuto}
                accept={closeModal}
                schedule={listSchedule[idSchedule]}
            />
            <Snackbar open={manualSystem} autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">{t(`system.${getSystemName()}`) === 'System' || t(`system.${getSystemName()}`) === 'Sistema' || t(`system.${getSystemName()}`) === 'Système' ? t(`system.${getSystemName()}`) : getSystemName()} {t('schedules.schedules_man_mode_alert')}</Alert>
            </Snackbar>
            {/* <Snackbar open={manualSystem} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">{t(`system.${getSystemName()}`) === 'System' || t(`system.${getSystemName()}`) === 'Sistema' || t(`system.${getSystemName()}`) === 'Système' ? t(`system.${getSystemName()}`) : getSystemName()} {t('schedules.schedules_man_mode_alert')}</Alert>
            </Snackbar> */}
        </Box>
    );
}