import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { ScheduleContext } from '../../../../Context/ScheduleContext'
import CircleCheckedFilled from '@material-ui/icons/FiberManualRecord'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function ModalAddPoint({ open, onClose }) {
    const { listAvailableTemperature, setlistPoints, setPointAdd } = React.useContext(ScheduleContext)
    const [time, setTime] = React.useState({
        hour: 10,
        minute: 30
    })
    const [tempSelect, setTempSelect] = React.useState(listAvailableTemperature[0].id)
    const colors = ['#ff4040', '#0088ff', 'gray', '#ffbf00', '#00a000', '#7956ca']
    const {t} = useTranslation('common')
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    React.useEffect(()=> {
        setTime({hour: 10, minute: 30})
        setTempSelect(listAvailableTemperature[0].id)
    },[])

    const valuePoint = () => {
        if (time.minute < 10 && time.hour < 10)
            return `0${time.hour}:0${time.minute}`
        else if (time.hour < 10)
            return `0${time.hour}:${time.minute}`
        else if (time.minute < 10)
            return `${time.hour}:0${time.minute}`
        else {
            return `${time.hour}:${time.minute}`
        }
    }

    const setColors = (aTemp) => {
        if (listAvailableTemperature.indexOf(aTemp) === 0)
            return colors[0]
        else if (listAvailableTemperature.indexOf(aTemp) === 1)
            return colors[1]
        else if (listAvailableTemperature.indexOf(aTemp) === 2)
            return colors[2]
        else if (listAvailableTemperature.indexOf(aTemp) === 3)
            return colors[3]
        else if (listAvailableTemperature.indexOf(aTemp) === 4)
            return colors[4]
        else if (listAvailableTemperature.indexOf(aTemp) === 5)
            return colors[5]
    }

    const addPoint = () => {
        setlistPoints(listPoints => [...listPoints, {
            temperature_id: tempSelect,
            time: time
        }])
        setPointAdd(Math.floor(Math.random() * (100000000 - 1) + 1))
        onClose()
    }
    const handleChangeTemperature = (e, nextIcon) => {
        setTempSelect(nextIcon)
    }

    return (
        <Dialog open={open}
        style={matches ? {height: '50%'} : {height: '100%'}}
            onClose={onClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">{t('schedules.schedules_create_modal_create_point_header')}</DialogTitle>
            <DialogContent>
                {listAvailableTemperature.map(at => (
                    <ToggleButtonGroup key={at.id} value={tempSelect} exclusive onChange={handleChangeTemperature}
                        style={{ paddingTop: '20px', textAlign: 'center' }}>
                        <ToggleButton style={{ borderRadius: '50px', border: 'transparent' }} value={at.id}>
                            <CircleCheckedFilled style={{ color: setColors(at) }} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                ))}
                <Box width={200}>
                    <TextField
                        fullWidth
                        id="time"
                        label="Seleccione la hora"
                        type="time"
                        value={valuePoint()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 60, // 5 min
                        }}
                        onChange={(e) => {
                            setTime({
                                hour: Number.parseInt((e.target.value).substring(0, 2)),
                                minute: Number.parseInt((e.target.value).substring(3, 5))
                            })
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', paddingTop: '20px' }}>
                <Button
                    onClick={addPoint}
                    color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.accept')}
                </Button>
                <Button onClick={onClose} color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
