import React from 'react'
import NavigationTopBar from '../../Components/NavBars/NavigationTopBar'
//import NavigationBottomBar from '../Components/NavBars/NavigationBottomBar'
import AddScheduleComponent from '../../Components/Schedules/ScheduleAdd/AddScheduleComponent'
import DrawlerLateralBar from'../../Components/NavBars/DrawerLateralBar'
import { InstallContext } from "../../Context/InstallContext"

export default function AddSchedule({history}) {
    const {setDisabled} = React.useContext(InstallContext)

    React.useEffect(()=> {
        setDisabled(true)
    },[])

    return (
        <div>
            <NavigationTopBar history={history} />
            <div style={{display: 'flex'}}>
                <DrawlerLateralBar />
                <AddScheduleComponent history={history} />
            </div>
            {/* <NavigationBottomBar /> */}
        </div>
    )
}
