import { Container, Typography, Button, IconButton, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import React from 'react'
import Icon from "@mdi/react"
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { mdiDelete } from '@mdi/js'
import { mdiPencil } from '@mdi/js'
import { mdiAccountPlus } from '@mdi/js'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        paddingTop: 110,
        paddingBottom: 284
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#C62828',
        color: '#EDE7D2',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    
}))(TableRow);


export default function ManageUsersComponent() {
    const users = [
        {username: 'Jorge'},
        {username: 'Juan'},
        {username: 'Agustin'},
        {username: 'Marc'}
    ]
    const {t} = useTranslation('common')

    const classes = useStyles()

    return (
        <Container>
            <div className={classes.root}>
                <Typography variant='h3' style={{marginBottom: 20}}>{t('drawler.manage_users')}</Typography>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Usuarios</StyledTableCell>
                                <StyledTableCell style={{width: 5}} align="right">Editar</StyledTableCell>
                                <StyledTableCell style={{width: 5}} align="right">Eliminar</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {   users.map((user, i) => (
                                <StyledTableRow key={i}>
                                    <StyledTableCell component="th" scope="row">
                                    {user.username}
                                    </StyledTableCell>
                                    <StyledTableCell align="right"><IconButton>
                                        <Icon path={mdiPencil} size={1} />
                                    </IconButton></StyledTableCell>
                                    <StyledTableCell align="right"><IconButton >
                                        <Icon path={mdiDelete} size={1} />
                                    </IconButton></StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    type='submit'
                    color='primary'
                    variant="contained"
                    style={{ marginTop: 20 }}
                >
                    <Icon path={mdiAccountPlus} size={1} /> &nbsp; Añadir usuario
                </Button>
            </div>
        </Container>
    )
}
