import React from 'react'
import { Box, TextField, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import WeekRadioUpdate from '../Week/WeekRadioUpdate'
import CalendarRadioUpdate from '../Calendar/CalendarRadioUpdate'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { ScheduleContext } from '../../../Context/ScheduleContext'
//import { SystemContext } from '../Context/SystemContext'
import BrigthIcon from "../../../assets/iconsImages/mode_heat.png"
import ColdIcon from "../../../assets/iconsImages/mode_cold.png"
import FanIcon from "../../../assets/iconsImages/mode_fan.png"
import DryIcon from "../../../assets/iconsImages/mode_dry.png"
import { SystemContext } from '../../../Context/SystemContext'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../../Context/UserContext'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function FormUpdateScheduleStart() {
    const { t } = useTranslation('common')
    const { setIcons, setNameSchedule, setEditSchedule, icons, nameSchedule, setType, setNewSystem, type } = React.useContext(ScheduleContext)
    const { listSystem } = React.useContext(SystemContext)
    const { isDemo } = React.useContext(UserContext)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    React.useEffect(() => {
        if(!isDemo){
            setEditSchedule(localStorage.getItem('idSchedule'))
        }
        getNewSystem()
    }, [])

    const getNewSystem = () => {
        let newObject = []
        for (let [key, value] of Object.entries(listSystem)) {
            let newZones = []
            for (let [k, v] of Object.entries(value.zones)) {
                newZones.push({ zoneName: v.name, zoneId: k, status: false })
            }
            let nSystem = {
                systemId: key,
                systemName: value.name,
                status: false,
                zones: newZones
            }
            newObject.push(nSystem)
        }
        setNewSystem(newObject)
    }

    const handleRadioChange = (event) => {
        setType(Number.parseInt(event.target.value))
    }

    const handleChangeIcon = (e, nextIcon) => {
        setIcons(nextIcon)
    }

    return (
        <div>
            <TextField
                style={{ marginTop: 20, width: 400 }}
                label={t('schedules.schedules_create_name')}
                required
                value={nameSchedule}
                onChange={(e) => setNameSchedule(e.target.value)}
            />
            <Typography style={{ marginTop: 20 }} variant='h6'>{t('schedules.schedules_create_type')}</Typography>
            <Box style={{ display: 'flex' }}>
                <RadioGroup aria-label="quiz" name="quiz" value={type} onChange={handleRadioChange}>
                    <FormControlLabel value={1} control={<Radio />} label={t('schedules.schedules_create_type_1')} />
                    <FormControlLabel value={2} control={<Radio />} label={t('schedules.schedules_create_type_2')} />
                    {type === 2 ?
                        <CalendarRadioUpdate /> 
                        : <WeekRadioUpdate />}
                </RadioGroup>
                <Box style={{ margin: '0px auto' }}>
                    <Typography variant='h6' style={{ textAlign: 'center' }}>{t('schedules.schedules_create_mode')}</Typography>
                    <ToggleButtonGroup value={icons} size="large" exclusive onChange={handleChangeIcon}
                        style={{ display: 'block', paddingTop: 20, textAlign: 'center' }}>
                        <ToggleButton style={{ borderRadius: 50, border: 'transparent' }} value={1}>
                            <img style={{ width: 27 }} src={BrigthIcon} alt="mode hot" />
                        </ToggleButton>
                        <ToggleButton style={matches ? {marginLeft: 0, borderRadius: 50, border: 'transparent'} : { marginLeft: 20, borderRadius: 50, border: 'transparent' }} value={3}>
                            <img style={{ width: 27 }} src={ColdIcon} alt="mode cold" />
                        </ToggleButton>
                        <ToggleButton style={matches ? {marginLeft: 0, borderRadius: 50, border: 'transparent'} : { marginLeft: 20, borderRadius: 50, border: 'transparent' }} value={6}>
                            <img style={{ width: 19, height: 26 }} src={DryIcon} alt="mode dry" />
                        </ToggleButton>
                        <ToggleButton style={matches ? {marginLeft: 0, borderRadius: 50, border: 'transparent'} : { marginLeft: 20, borderRadius: 50, border: 'transparent' }} value={5}>
                            <img style={{ width: 27 }} src={FanIcon} alt="mode fan" />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
        </div>
    )
}
