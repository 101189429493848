import React, { useState, Fragment, useContext } from 'react'
import { IconButton, Container, Typography, Table, TableContainer, Paper, TableBody, TableRow, TableCell, Modal, Fade, Backdrop } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SystemContext } from '../Context/SystemContext'
import EditIcon from '@material-ui/icons/Edit'
import HomeIcon from '@material-ui/icons/Home'
import ApartmentIcon from '@material-ui/icons/Apartment'
import { TextFields } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        paddingTop: 110
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));

export default function SettingComponent() {
    const classes = useStyles();
    const { listInstalls } = useContext(SystemContext)
    const [openModalInstall, setModalInstall] = useState(false);
    // const [openModalSystem, setModalSystem] = useState(false);
    // const [openModalZone, setModalZone] = useState(false);

    const handleOpenInstall = () => {
        setModalInstall(true);
    };

    const handleCloseInstall = () => {
        setModalInstall(false);
    };

    // const handleOpenSystem = () => {
    //     setModalInstall(true);
    // };

    // const handleCloseSystem = () => {
    //     setModalInstall(false);
    // };

    // const handleOpenZone = () => {
    //     setModalInstall(true);
    // };

    // const handleCloseZone = () => {
    //     setModalInstall(false);
    // };

    return (
        <Container>
            <div className={classes.root}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            {listInstalls.map((install, i) => (
                                <Fragment key={i}>
                                    <TableRow >
                                        <TableCell component='td' scope='row'>
                                            <Typography variant='h3'> {install.typeinstall} </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: 160 }} align='right'>
                                            <IconButton onClick={handleOpenInstall} color="inherit">
                                                <EditIcon />
                                            </IconButton>
                                            <Modal className={classes.modal} open={openModalInstall} onClose={handleCloseInstall} closeAfterTransition BackdropComponent={Backdrop}
                                                BackdropProps={{ timeout: 500 }}>
                                                <Fade in={openModalInstall}>
                                                    <div className={classes.paper}>
                                                        <h2>Perzonalizar instalación</h2>
                                                        <div className={classes.modal}>
                                                            <IconButton color='inherit'>
                                                                <HomeIcon />
                                                            </IconButton>
                                                            <IconButton color='inherit'>
                                                                <ApartmentIcon />
                                                            </IconButton>
                                                        </div>
                                                        <TextFields></TextFields>
                                                    </div>
                                                </Fade>
                                            </Modal>
                                        </TableCell>
                                    </TableRow>
                                    {
                                        install.systems.map((system, i) => (
                                            <Fragment key={i}>
                                                <TableRow>
                                                    <TableCell component='td' scope='row'>
                                                        <Typography variant='h5'>{system.name}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{ width: 160 }} align='right'>
                                                        <IconButton color="inherit">
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    system.rooms.map((room, i) => (
                                                        <TableRow key={i}>
                                                            <TableCell component='td' scope='row'>
                                                                <Typography variant='h6'>{room.name}</Typography>
                                                            </TableCell>
                                                            <TableCell style={{ width: 160 }} align='right'>
                                                                <IconButton color="inherit">
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </Fragment>
                                        ))
                                    }
                                </Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Container>
    )
}
