import React from 'react'
import { Dialog, TextField, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { SystemContext } from '../../../Context/SystemContext'
import axios from 'axios'
import { UserContext } from '../../../Context/UserContext'
import {URL} from '../../../Utils/URL'
import { useTranslation } from 'react-i18next'

export default function ModalEditSystem({ name, open, onClose, id_system }) {
    const [value, setValue] = React.useState(name)
    const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setSystemName`
    const { listSystem, setListSystem } = React.useContext(SystemContext)
    const { isDemo } = React.useContext(UserContext)
    const {t} = useTranslation('common')

    const handleChange = (e) => {
        setValue(e.target.value)
    }
    const updateSystem = () => {
        onClose()
        if (isDemo) {
            listSystem[id_system].name = value
            setListSystem({ ...listSystem })
        }
        else {
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
            const data = {
                name: value,
                id: id_system
            }
            axios.post(url, data, config)
                .then((res) => {
                    listSystem[id_system].name = value
                    setListSystem({ ...listSystem })
                })
                .catch((e) => {
                    //console.log(e)
                })
        }
    }
    const onCloseEdit = () =>{
        setValue(name)
        onClose()
    }

    return (
        <Dialog open={open}
            onClose={onClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            style={{marginBottom: 20}}>
            <DialogTitle id="scroll-dialog-title">{t('system.header_modal_system')}</DialogTitle>
            <DialogContent>
                <TextField
                    id="standard-multiline-flexible"
                    multiline
                    rowsMax={4}
                    value={value}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', paddingTop: 20, paddingBottom: 30 }}>
                <Button
                    onClick={updateSystem}
                    color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.accept')}
                </Button>
                <Button onClick={onCloseEdit} color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
