import React from 'react'
import { FormControlLabel, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ScheduleContext } from '../../../Context/ScheduleContext'
import ModalUpdatePoint from '../Points/Modals/ModalUpdatePoint'
import CircleCheckedFilled from '@material-ui/icons/FiberManualRecord'
import { useTranslation } from 'react-i18next'

export default function PointSchedule({ point }) {
    const { listAvailableTemperature } = React.useContext(ScheduleContext)
    const colors = ['#ff4040', '#0088ff', 'gray', '#ffbf00', '#00a000', '#7956ca']
    const [modalUpdatePoint, setModalUpdatePoint] = React.useState(false)
    const { t } = useTranslation('common')
    
    const setColors = () => {
        let aTemp = listAvailableTemperature.find((at) => at.id === point.temperature_id) 
        if (listAvailableTemperature.indexOf(aTemp) === 0)
            return colors[0]
        else if (listAvailableTemperature.indexOf(aTemp) === 1)
            return colors[1]
        else if (listAvailableTemperature.indexOf(aTemp) === 2)
            return colors[2]
        else if (listAvailableTemperature.indexOf(aTemp) === 3)
            return colors[3]
        else if (listAvailableTemperature.indexOf(aTemp) === 4)
            return colors[4]
        else if (listAvailableTemperature.indexOf(aTemp) === 5)
            return colors[5]
    }

    const handleOpenModalUpdatePoint = () => {
        setModalUpdatePoint(true)
    }

    const handleCloseModalUpdatePoint = () => {
        setModalUpdatePoint(false)
    }

    const getNamePoint = () => {
        for (let at of listAvailableTemperature) {
            if (at.id === point.temperature_id) {
                return at.name
            }
        }
    }
    const setNamePoint = () => {
        if (t(`temperatures.${getNamePoint()}`) === 'Normal' || t(`temperatures.${getNamePoint()}`) === 'Ordinaire' || t(`temperatures.${getNamePoint()}`) === 'Économie' || t(`temperatures.${getNamePoint()}`) === 'Normale' || t(`temperatures.${getNamePoint()}`) === 'Salvataggio' || t(`temperatures.${getNamePoint()}`) === 'Save' || t(`temperatures.${getNamePoint()}`) === 'Off' || t(`temperatures.${getNamePoint()}`) === 'Ahorro') {
            return t(`temperatures.${getNamePoint()}`)
        }
        else
            return getNamePoint()
    }
    const formattTime1 = () => {
        if(point.time.hour < 10){
            return `0${point.time.hour}`
        }
        else {
            return `${point.time.hour}`
        }
    }
    const formattTime2 = () => {
        if(point.time.minute < 10){
            return `0${point.time.minute}`
        }
        else {
            return `${point.time.minute}`
        }
    }

    return (
        <div>
            <FormControlLabel
                control={
                    <ListItem button onClick={(e) => { handleOpenModalUpdatePoint(e) }}>
                        <ListItemIcon>
                            <CircleCheckedFilled style={{ color: setColors() }} />
                        </ListItemIcon>
                        <ListItemText primary={setNamePoint()} />
                        <ListItemText style={{ textAlign: 'right' }} primary={`${formattTime1()}:${formattTime2()}`} />
                    </ListItem>
                } style={{width: 420}} />
            <ModalUpdatePoint
                open={modalUpdatePoint}
                onClose={handleCloseModalUpdatePoint}
                point={point}
            />
        </div>
    )
}
