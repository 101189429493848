import React from 'react'
import { ScheduleContext } from '../../../Context/ScheduleContext'
import 'react-day-picker/lib/style.css';
import DayPicker, { DateUtils } from 'react-day-picker';

export default function CalendarRadio() {
    const { setDays, days } = React.useContext(ScheduleContext)

    const handleDayClick = (e, { selected }) => {
        const arrayDays = [...days]
        if(selected) {
            const selectedIndex = days.findIndex(day => DateUtils.isSameDay(day, e))
            arrayDays.splice(selectedIndex, 1)
            setDays(arrayDays)
        }
        else {
            setDays(days => [...days, e])
        }
    }
    const MONTHS = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
        ];
    const WEEKDAYS_LONG = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        ];
    const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];

    return (
        <div style={{ margin: 20, width: 350 }}>
            <DayPicker
                locale="es"
                months={MONTHS}
                weekdaysLong={WEEKDAYS_LONG}
                weekdaysShort={WEEKDAYS_SHORT}
                onDayClick={handleDayClick}
                selectedDays={days}
            />
        </div>
    )
}
