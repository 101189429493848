import React, { useContext } from 'react'
import { Route, Redirect } from "react-router-dom"
import { UserContext } from '../Context/UserContext'

export default function ProtectedRoutes({ component: Component, ...rest }) {

    const { auth } = useContext(UserContext)
    //primero ver porque no pincha con el leo luego probar con esta via
    //https://stackoverflow.com/questions/61293320/protected-page-using-react-router-dom-doesnt-work
    return (
        <Route
            {...rest}
            render={(props) => 
                auth ? <Component {...props} />
                : <Redirect to="/" />
            }
        />
    )
}
