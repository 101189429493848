import React from 'react'
import NavigationTopBar from '../../Components/NavBars/NavigationTopBar'
//import NavigationBottomBar from '../Components/NavBars/NavigationBottomBar'
import ScheduleComponent from '../../Components/Schedules/ScheduleComponent'
import DrawlerLateralBar from '../../Components/NavBars/DrawerLateralBar'
import {InstallContext} from '../../Context/InstallContext'
import { UserContext } from '../../Context/UserContext'

export default function SchedulePage({ history }) {
    const { getInstallation, setDisabled, setInstallActive } = React.useContext(InstallContext)
    const { isDemo} = React.useContext(UserContext)

    React.useEffect(() => {
        setDisabled(true)
        if(!isDemo){
            const data = localStorage.getItem('installActive')
            getInstallation(JSON.parse(data).id)
            if(data){
                setInstallActive(JSON.parse(data))
            }
        }                  
    },[])
    
    return (
        <div>
            <NavigationTopBar history={history} />
            <div style={{ display: 'flex' }}>
                <DrawlerLateralBar />
                <ScheduleComponent history={history} />
            </div>    
            {/* <NavigationBottomBar /> */}
        </div>
        
    )
}
