import React, { useState, createContext } from 'react'

const ScheduleContext = createContext();

function ScheduleProvider({children}) {

    const [listSchedule, setListSchedule] = useState({})
    const [listAvailableTemperature, setListAvailableTemperature] = useState([])
    const [newSystem, setNewSystem] = React.useState([])
    const [icons, setIcons] = React.useState(1)
    const [nameSchedule, setNameSchedule] = React.useState('')
    const [listFull, setListFull]= React.useState(false)
    const [weekSelect, setWeekSelect] = React.useState({
        sunday:false,
        saturday:false,
        tuesday:false,
        wednesday:false,
        thursday:false,
        friday:false,
        monday:false
    })
    const [listPoints, setlistPoints] = React.useState([])
    const [valueSchUpd, setValueSchUpd] = React.useState(1)
    const [valueSchDel, setValueSchDel] = React.useState(1)
    const [editSchedule, setEditSchedule] = React.useState('')
    const [manualSystem, setManualSystem] = React.useState(false)
    const [type, setType] = React.useState(1)
    const [days, setDays] = React.useState([])
    const [pointAdd, setPointAdd] = React.useState(1)
    const [pointUpd, setPointUpd] = React.useState(1)
    const [pointDel, setPointDel] = React.useState(1)

    return (
        <ScheduleContext.Provider
        value={{
            pointAdd, 
            setPointAdd,
            pointUpd, 
            setPointUpd,
            pointDel, 
            setPointDel,
            days,
            setDays,
            type,
            setType,
            manualSystem,
            setManualSystem,
            editSchedule, 
            setEditSchedule,
            valueSchUpd, 
            setValueSchUpd,
            valueSchDel, 
            setValueSchDel,
            listPoints,
            setlistPoints,
            listFull,
            setListFull,
            weekSelect,
            setWeekSelect,
            nameSchedule,
            setNameSchedule,
            listSchedule,
            setListSchedule,
            listAvailableTemperature, 
            setListAvailableTemperature,
            newSystem,
            setNewSystem,
            icons,
            setIcons
        }}
        >
            {children}
        </ScheduleContext.Provider>
        )
}
export {ScheduleProvider, ScheduleContext};