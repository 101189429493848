import React from 'react'
import { Accordion, ListItemIcon, ListItemText, ListItem, Divider, List, Toolbar, CssBaseline, Drawer, Link as MaterialLink, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import ScheduleIcon from '@material-ui/icons/Schedule'
import InfoIcon from '@material-ui/icons/Info'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import { InstallContext } from '../../Context/InstallContext'
import { mdiAlphaZCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { useTranslation } from 'react-i18next'
import HomeIcon from '@material-ui/icons/Home'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { UserContext } from '../../Context/UserContext'

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 340,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: 198
        }
    },
    drawerPaper: {
        width: 340,
        zIndex: 1,
        [theme.breakpoints.down('xs')]: {
            width: 202
        }
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    underlineHover: {
        color: '#C62828',
    },
    expandIcon: {
        marginRight: '5px',
    },
    nested: {
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(1)
        }
    },
}));

export default function DrawerLateralBar() {
    const classes = useStyles()
    const { disabled } = React.useContext(InstallContext)
    const { hideDrawler } = React.useContext(UserContext)
    const {t} = useTranslation('common')
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <>
        {hideDrawler ?
            <>
            <CssBaseline />
            <Drawer className={classes.drawer} variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}>
                <Toolbar />
                <div className={classes.drawerContainer}>
                    <List>
                        <MaterialLink className={classes.underlineHover} to={'/install'} component={RouterLink} >
                            <ListItem style={{ color: 'black' }} button >
                                <ListItemIcon>
                                    <HomeIcon color='primary' />                                
                                </ListItemIcon>
                                <ListItemText primary={t('drawler.init')} />
                            </ListItem>
                        </MaterialLink>
                        {disabled ?
                            <MaterialLink className={classes.underlineHover} to={'/system'} component={RouterLink} >
                                <ListItem style={{ color: 'black' }} button >
                                    <ListItemIcon>
                                        <Icon path={mdiAlphaZCircleOutline} size={1.2} color='#C62828' />
                                    </ListItemIcon>
                                    <ListItemText primary={t('drawler.systems')} />
                                </ListItem>
                            </MaterialLink>
                            :
                            <ListItem disabled style={{ color: 'black' }} button >
                                <ListItemIcon>
                                    <Icon path={mdiAlphaZCircleOutline} size={1.2} color='#C62828' />
                                </ListItemIcon>
                                <ListItemText primary={t('drawler.systems')} />
                            </ListItem>
                        }
                        {disabled ?
                            <MaterialLink className={classes.underlineHover} to={'/schedules'} component={RouterLink} >
                                <ListItem style={{ color: 'black' }} button >
                                    <ListItemIcon>
                                        <ScheduleIcon color='primary' />
                                    </ListItemIcon>
                                    <ListItemText primary={t('drawler.programs')} />
                                </ListItem>
                            </MaterialLink>
                            :
                            <ListItem disabled style={{ color: 'black' }} button >
                                <ListItemIcon>
                                    <ScheduleIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={t('drawler.programs')} />
                            </ListItem>
                        }
                        {/* {disabled ?
                        <MaterialLink className={classes.underlineHover} to={'/manageUsers'} component={RouterLink} >
                            <ListItem style={{ color: 'black' }} button >
                                <ListItemIcon>
                                    <PeopleAltIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={'Gestión usuarios'} />
                            </ListItem>
                        </MaterialLink>
                        : */}
                        <ListItem disabled style={{ color: 'black' }} button >
                                <ListItemIcon>
                                    <PeopleAltIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={t('drawler.manage_users')} />
                            </ListItem>
                        {/* } */}
                    </List>
                    <Divider />
                    <List>
                        <Accordion defaultExpanded className={classes.underlineHover}>
                            <AccordionSummary className={classes.expandIcon} expandIcon={<ExpandMoreIcon color='primary' />} aria-controls="panel1c-content" id="panel1c-header">
                                <ListItem style={{ color: 'black', paddingLeft: 'initial' }}>
                                    <ListItemText primary={t('drawler.config')} />
                                </ListItem>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <MaterialLink className={classes.underlineHover} to={'/changePassword'} component={RouterLink} >
                                        <ListItem className={classes.nested} style={{ color: 'black' }} button>
                                            <ListItemIcon>
                                                <VpnKeyIcon color='primary' />
                                            </ListItemIcon>
                                            <ListItemText style={ matches ? {paddingRight: 10} : {paddingRight: 21}} primary={t('drawler.change_password')} />
                                        </ListItem>
                                    </MaterialLink>
                                    <MaterialLink className={classes.underlineHover} to={'/aboutUs'} component={RouterLink} >
                                        <ListItem className={classes.nested} style={{ color: 'black' }} button>
                                            <ListItemIcon>
                                                <InfoIcon color='primary' />
                                            </ListItemIcon>
                                            <ListItemText style={ matches ? {paddingRight: 10} : {paddingRight: 21}} primary={t('drawler.about')} />
                                        </ListItem>
                                    </MaterialLink>
                                </List>
                            </AccordionDetails>
                        </Accordion >
                    </List>
                </div>
            </Drawer>
        </>
        : null
        } 
        </>
    )
}