import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ModalDeleteTemperature from "./ModalDeleteTemperature";
import DeleteIcon from "@material-ui/icons/Delete";
import { UserContext } from "../../../../Context/UserContext";
import { ScheduleContext } from "../../../../Context/ScheduleContext";
import { InstallContext } from "../../../../Context/InstallContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {URL} from '../../../../Utils/URL'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function ModalUpdateTemperature({ open, onClose, aTemp }) {
    const {
        listAvailableTemperature,
        setListAvailableTemperature
    } = React.useContext(ScheduleContext)
    const [deleteTemperature, setDeleteTemperature] = React.useState(false)
    const { isDemo } = React.useContext(UserContext)
    const { installActive } = React.useContext(InstallContext)
    const [temperature, setTemperature] = React.useState(aTemp.temperature)
    const { t } = useTranslation('common')
    const [name, setName] = React.useState(aTemp.name)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const hideDelete = () => {
        if (listAvailableTemperature.indexOf(aTemp) === 0) return true;
        else if (listAvailableTemperature.indexOf(aTemp) === 1) return true;
        else if (listAvailableTemperature.indexOf(aTemp) === 2) return true;
        else if (listAvailableTemperature.indexOf(aTemp) > 2) return false;
    };

    const openModalDeleteTemperature = () => {
        setDeleteTemperature(true);
    };
    const closeModalDeleteTemperature = () => {
        setDeleteTemperature(false);
    };
    const handleChange = (e) => {
        setName(e.target.value);
    };
    const clickSumTemp = () => {
        if (temperature < 30.0)
            setTemperature(Math.round((temperature + 0.5) * 10) / 10);
    };

    const clickRestTemp = () => {
        if (temperature > 18.0)
            setTemperature(Math.round((temperature - 0.5) * 10) / 10);
    };

    const updateTemperature = () => {
        if (isDemo) {
            let updatedList = listAvailableTemperature.map((at) => {
                if (at.id === aTemp.id) {
                    return { ...at, name: name, temperature: temperature };
                }
                return at;
            });
            setListAvailableTemperature(updatedList);
            //setValueSch(Math.floor(Math.random() * (100 - 10)) + 10);
            onClose();
        } else {
            const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/updateTemperature`;
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
            const data = {
                installationId: installActive.id,
                name: name,
                value: temperature,
                id: aTemp.id,
            };
            axios
                .post(url, data, config)
                .then((res) => {
                    let updatedList = listAvailableTemperature.map((at) => {
                        if (at.id === aTemp.id && listAvailableTemperature.indexOf(aTemp) === listAvailableTemperature.indexOf(at)) {
                            return { ...at, name: name, temperature: temperature };
                        }
                        return at;
                    });
                    setListAvailableTemperature(updatedList);
                    //setValueSchUpd(Math.floor(Math.random() * (100 - 10)) + 10);
                    onClose();
                })
                .catch((e) => {/*console.log(e)*/})
        }
    };
    const setNewName = () => {
        if(t(`temperatures.${aTemp.name}`) === 'Normal' || t(`temperatures.${aTemp.name}`) === 'Ordinaire' || t(`temperatures.${aTemp.name}`) === 'Normale' || t(`temperatures.${aTemp.name}`) === 'Save' || t(`temperatures.${aTemp.name}`) === 'Ahorro' || t(`temperatures.${aTemp.name}`) === 'Salvataggio' || t(`temperatures.${aTemp.name}`) === 'Économie' || t(`temperatures.${aTemp.name}`) === 'Off'){
            return t(`temperatures.${name}`)
        }
        else
            return name
    }

    const onCloseEdit = () =>{
        setName(aTemp.name)
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="scroll-dialog-title"
            style={matches ? {height: '50%'} : {height: '100%'}}
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">{t('schedules.schedules_create_modal_update_temperatures_header')}</DialogTitle>
            <DialogContent>
                <TextField
                    id="standard-multiline-flexible"
                    label={t('schedules.schedules_create_modal_add_temperatures_name')}
                    multiline
                    rowsMax={4}
                    value={setNewName()}
                    onChange={handleChange}
                />
                {hideDelete() ? null : (
                    <IconButton color="inherit" onClick={openModalDeleteTemperature}>
                        <DeleteIcon color="action" />
                    </IconButton>
                )}
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                    <IconButton onClick={clickRestTemp} color="inherit">
                        <RemoveIcon />
                    </IconButton>
                        <Typography style={{fontFamily: 'system-ui', fontWeight: 400}} variant="h6">{temperature.toFixed(1)}º</Typography>
                    <IconButton onClick={clickSumTemp} color="inherit">
                        <AddIcon />
                    </IconButton>
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center", paddingTop: "20px" }}>
                <Button
                    onClick={updateTemperature}
                    color="secondary"
                    style={{ backgroundColor: "#C62828" }}>
                    {t('modals.accept')}
                </Button>
                <Button
                    onClick={onCloseEdit}
                    color="secondary"
                    style={{ backgroundColor: "#C62828" }}>
                    {t('modals.cancel')}
                </Button>
            </DialogActions>
            <ModalDeleteTemperature
                open={deleteTemperature}
                onClose={closeModalDeleteTemperature}
                closeUpdate={onClose}
                aTId={aTemp}/>
        </Dialog>
    );
}
