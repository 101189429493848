import React from 'react'
import { Dialog, Typography, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { SystemContext } from '../../../Context/SystemContext';
import { useTranslation } from "react-i18next"
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function ModalSetAutoMode({ open, onClose, accept, schedule }) {
    const { t } = useTranslation('common');
    const { listSystem } = React.useContext(SystemContext)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const getSystemName = () => {
        let systemName = ''
        for(let z of schedule.zones){
            for(let value of Object.values(listSystem)){
                for(let k of Object.keys(value.zones)){
                    if(z === k){
                        systemName = value.name
                        break
                    }
                }
            }
        }
        return systemName
    }

    const changeAutoModeOff = () => {        
        onClose()
    }

    return (
        <Dialog open={open}
        style={matches ? {height: '50%'} : {height: '100%'}}
            onClose={onClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">{t('schedules.schedules_auto_mode_modal_header')}</DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <Typography variant='body1'>{t('schedules.schedules_auto_mode_modal_body1')} <br />{t('schedules.schedules_auto_mode_modal_body2')} {t(`system.${getSystemName()}`) === 'Sistema' || t(`system.${getSystemName()}`) === 'System' || t(`system.${getSystemName()}`) === 'Système' ? t(`system.${getSystemName()}`) : getSystemName()} {t('schedules.schedules_auto_mode_modal_body3')}</Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', paddingTop: '20px' }}>
                <Button
                    onClick={accept}
                    color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.accept')}
                </Button>
                <Button onClick={() => changeAutoModeOff()} color='secondary' style={{ backgroundColor: '#C62828' }}>
                    {t('modals.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}