import React from 'react'
import axios from 'axios'
import { Dialog, Typography, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import {UserContext} from '../../../Context/UserContext'
import {InstallContext} from '../../../Context/InstallContext'
import {URL} from '../../../Utils/URL'
import { useTranslation } from 'react-i18next'
import { SystemContext } from '../../../Context/SystemContext'
import { ScheduleContext } from '../../../Context/ScheduleContext'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function ModalDeleteSchedule({name, id_schedule, open, onClose}) {
    const {t} = useTranslation('common')
    const {isDemo} = React.useContext(UserContext)
    const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/deleteSchedule`
    const {listSchedule, setListSchedule} = React.useContext(ScheduleContext)
    const {installActive } =  React.useContext(InstallContext)
    const {listSystem, setListSystem} = React.useContext(SystemContext)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const deleteSchedule = ()=>{
        if(isDemo){
            let cumplio = false
            if(listSchedule[id_schedule].enabled){
                for(let keyZoneS of Object.keys(listSystem)){
                    for(let keyZone of Object.keys(listSystem[keyZoneS].zones)){
                        if(!cumplio){
                            for(let zone of listSchedule[id_schedule].zones){
                                if(keyZone === zone){
                                    listSystem[keyZoneS].mode = 2
                                    setListSystem({...listSystem})
                                    delete listSchedule[id_schedule]
                                    setListSchedule({ ...listSchedule })
                                    cumplio = true
                                }
                                // else {
                                //     delete listSchedule[id_schedule]
                                //     setListSchedule({ ...listSchedule })
                                // }
                            }
                        }
                    }
                }
            }
            else {
                delete listSchedule[id_schedule]
                setListSchedule({ ...listSchedule })
            }            
        }
        else {
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
            const data = {
                installationId: installActive.id,
                id: id_schedule
            }
            axios.post(url, data, config)
            .then((res) => {
                let cumplio = false
            if(listSchedule[id_schedule].enabled){
                for(let keyZoneS of Object.keys(listSystem)){
                    for(let keyZone of Object.keys(listSystem[keyZoneS].zones)){
                        if(!cumplio){
                            for(let zone of listSchedule[id_schedule].zones){
                                if(keyZone === zone){
                                    listSystem[keyZoneS].mode = 2
                                    setListSystem({...listSystem})
                                    delete listSchedule[id_schedule]
                                    setListSchedule({ ...listSchedule })
                                    cumplio = true
                                }
                            }
                        }
                    }
                }
            }
            else {
                delete listSchedule[id_schedule]
                setListSchedule({ ...listSchedule })
            }                 
            })
            .catch((e) => {/*console.log(e)*/})
        }
    }

    return (
        <Dialog open={open}
        style={matches ? {height: '50%'} : {height: '100%'}}
        onClose={onClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">Desea eliminar la programación</DialogTitle>
        <DialogContent style={{textAlign: 'center'}}>
            <Typography variant='body1'>{name}</Typography>
        </DialogContent>
        <DialogActions style={{justifyContent: 'center', paddingTop: '20px'}}>
            <Button 
            onClick={() => deleteSchedule()} 
            color='secondary' style={{ backgroundColor: '#C62828' }}>
                {t('modals.accept')}
            </Button>
            <Button onClick={onClose} color='secondary' style={{ backgroundColor: '#C62828' }}>
                {t('modals.cancel')}
            </Button>
        </DialogActions>
        </Dialog>
    )
}
