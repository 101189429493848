import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid, Button } from '@material-ui/core';
import {useHistory} from 'react-router'

export default function Page404() {
    const gridStyle = { background: '#C62828', height: '100vh', padding: '40px 40px 0px 40px' }
    const history = useHistory()

    const handleClick = () => {
        history.push('/')
    }

    return (
        <Grid container style={gridStyle} direction="column" justify="flex-start" alignItems="center">
            <Typography variant="h1" color="secondary" style={{textAlign: 'center'}}>404</Typography>
            <Typography variant="h3" color="secondary" style={{textAlign: 'center'}}>Page Not Found</Typography>
            <Button onClick={handleClick} style={{backgroundColor: '#EDE7D2', marginTop: 40}}>Volver a inicio</Button>  
        </Grid>
    )
}
