import './App.css'
import './translations/i18n'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import Login from './Views/Authentification/Login'
import ForgetPasswordPage from './Views/Authentification/ForgetPasswordPage'
import SystemPage from './Views/Systems/SystemPage'
import SchedulePage from './Views/Schedules/SchedulePage'
import SettingPage from './Views/Settings'
import ChangePasswordPage from './Views/Authentification/ChangePasswordPage'
import ContactPage from './Views/Contact'
import AddSchedule from './Views/Schedules/AddSchedule'
import UpdateSchedule from './Views/Schedules/UpdateSchedule'
import InstallPage from './Views/Installation/InstallPage'
import ManageUsersPage from './Views/ManageUsersPage'
import ConfirmLogin  from './Views/Authentification/ConfirmLogin'
import Register from './Views/Authentification/Register'
import ProtectedRoutes from './Utils/ProtectedRoutes'
import Page404 from './Views/Page404'

function App() {

  return (
    <Router >
      <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/login' component={ConfirmLogin} />
          <Route exact path='/forgetPass' component={ForgetPasswordPage} />
          <ProtectedRoutes exact path='/install' component={InstallPage} />
          <ProtectedRoutes exact path='/system' component={SystemPage} />
          <ProtectedRoutes exact path='/schedules' component={SchedulePage} />
          <ProtectedRoutes exact path='/addSchedule' component={AddSchedule} />
          <ProtectedRoutes exact path='/editSchedule' component={UpdateSchedule} />
          <ProtectedRoutes exact path='/config' component={SettingPage} />          
          <ProtectedRoutes exact path='/manageUsers' component={ManageUsersPage} />
          <ProtectedRoutes exact path='/changePassword' component={ChangePasswordPage} />
          <ProtectedRoutes exact path='/aboutUs' component={ContactPage} /> 
          <Route exact path='/error' component={Page404} />
          <Redirect to='/error'/>        
      </Switch>
    </Router>
  );
}

export default App;
