import React, { useState, createContext } from "react";

const SystemContext = createContext();

function SystemProvider({ children }) {

    const [listSystem, setListSystem] = useState([]);
    const [listZones, setListZones] = useState({});
    const [tempRooms, setTempRooms] = useState([]);
    const [changeApp, setChangeApp] = useState(2);
    const [enabled, setEnabled] = useState(false);
    const [valueSys, setValueSys] = useState(1);
    const autoState = {
        mode: 1,
        name: "Sistema 1",
        state: {
            fan: {
                mode: 2,
                speed: 3,
            },
            mode: 3,
        },
        temperature: {
            setPoint: 28,
        },
        uuid: "3c4b03bb-778aad14-220a-DEV.dev0000001",
        zones: {
            zon_161c3effb59542bb816cf235a0dbf73c_159_001: {
                enabled: true,
                name: "Sala",
                temperature: {
                    sensor: 25,
                    setPoint: 28.5,
                },
            },
            zon_161c3effb59542bb816cf235a0dbf73c_159_002: {
                enabled: false,
                name: "Comedor",
                temperature: {
                    sensor: 27.2,
                    setPoint: 29.5,
                },
            },
            zon_161c3effb59542bb816cf235a0dbf73c_159_003: {
                enabled: true,
                name: "Habitacion",
                temperature: {
                    sensor: 28,
                    setPoint: 17.5,
                },
            },
            zon_161c3effb59542bb816cf235a0dbf73c_159_004: {
                enabled: true,
                name: "Habitacion",
                temperature: {
                    sensor: 28,
                    setPoint: 17.5,
                },
            },
            zon_161c3effb59542bb816cf235a0dbf73c_159_005: {
                enabled: true,
                name: "Habitacion",
                temperature: {
                    sensor: 28,
                    setPoint: 17.5,
                },
            },
            zon_161c3effb59542bb816cf235a0dbf73c_159_006: {
                enabled: true,
                name: "Habitacion",
                temperature: {
                    sensor: 28,
                    setPoint: 17.5,
                },
            },
        },
    };
    const [autoWarning, setAutoWarning] = useState(false)
    // const newArray = (listSystem) => {
    //     let newArray = listSystem.map(function(system){
    //         let newSystem=JSON.parse(JSON.stringify(system))

    //     })
    // }    

    return (
        <SystemContext.Provider
            value={{
                autoWarning,
                setAutoWarning,
                autoState,
                changeApp,
                setChangeApp,
                listSystem,
                setListSystem,
                tempRooms,
                setTempRooms,
                setListZones,
                listZones,
                setEnabled,
                enabled,
                setValueSys,
                valueSys,
            }}
        >
            {children}
        </SystemContext.Provider>
    );
}
export { SystemProvider, SystemContext };