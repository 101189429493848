import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { UserProvider } from './Context/UserContext'
import { ScheduleProvider } from './Context/ScheduleContext'
import { SystemProvider } from './Context/SystemContext'
import { InstallProvider } from './Context/InstallContext'
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import common_es from './translations/es/common.json'
import common_en from './translations/en/common.json' 
import common_fr from './translations/fr/common.json'
import common_it from './translations/it/common.json'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Karla"', 'sans-serif']
  },
  palette: {
    primary: {
      main: '#C62828',
    },
    secondary: {
      main: '#EDE7D2',
    },
  }
});

i18next.init({
  lng: 'es',
  interpolation: { escapeValue: false },
  resources: {
    es: {
      common: common_es,
    },
    en: {
      common: common_en,
    },
    fr: {
      common: common_fr,
    },
    it: {
      common: common_it,
    },
  },
})

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <I18nextProvider i18n={i18next}>
      <UserProvider>
        <InstallProvider>
          <SystemProvider>
            <ScheduleProvider>
              <App />
            </ScheduleProvider>
          </SystemProvider>
        </InstallProvider>
      </UserProvider>
    </I18nextProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);