import React from 'react'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { FormControlLabel, FormGroup, FormControl, Container, Checkbox } from '@material-ui/core'
import {ScheduleContext} from '../../../Context/ScheduleContext'
import { useTranslation } from 'react-i18next'

export default function WeekRadioUpdate() {
    const { t } = useTranslation('common')
    const {weekSelect, setWeekSelect} = React.useContext(ScheduleContext)
    
    const handleChange = (e) => {
        setWeekSelect({...weekSelect, [e.target.name]: e.target.checked})
    }
    const { sunday, saturday, tuesday, wednesday, thursday, friday, monday} = weekSelect
    
    return (
        <div style={{margin: 20}}>
            <Container>
            <FormControl component='fieldset'>
                <FormGroup>
                    <FormControlLabel
                    control={<Checkbox color='primary' checked={monday} icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} onChange={handleChange} name='monday'/>} label={t('schedules.schedules_create_type_2_monday')} />
                    <FormControlLabel 
                    control={<Checkbox color='primary' checked={tuesday} icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} onChange={handleChange} name='tuesday'/>} label={t('schedules.schedules_create_type_2_tuesday')} />
                    <FormControlLabel 
                    control={<Checkbox color='primary' checked={wednesday} icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} onChange={handleChange} name='wednesday'/>} label={t('schedules.schedules_create_type_2_wednesday')} />
                    <FormControlLabel 
                    control={<Checkbox color='primary' checked={thursday} icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} onChange={handleChange} name='thursday'/>} label={t('schedules.schedules_create_type_2_thursday')} />
                    <FormControlLabel 
                    control={<Checkbox color='primary' checked={friday} icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} onChange={handleChange} name='friday'/>} label={t('schedules.schedules_create_type_2_friday')} />
                    <FormControlLabel 
                    control={<Checkbox color='primary' checked={saturday} icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} onChange={handleChange} name='saturday'/>} label={t('schedules.schedules_create_type_2_saturday')} />
                    <FormControlLabel 
                    control={<Checkbox color='primary' checked={sunday} icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} onChange={handleChange} name='sunday'/>} label={t('schedules.schedules_create_type_2_sunday')} />                    
                </FormGroup>
            </FormControl>
            </Container>
        </div>
    )
}