import React from 'react'
import ManageUsersComponent from '../Components/ManageUsersComponent'
import NavigationTopBar from '../Components/NavBars/NavigationTopBar'
//import NavigationBottomBar from '../Components/NavBars/NavigationBottomBar'
import DrawlerLateralBar from'../Components/NavBars/DrawerLateralBar'

export default function ManageUsersPage({history}) {
    return (
        <div>
            <NavigationTopBar history={history} />
            <div style={{display: 'flex'}}>
                <DrawlerLateralBar />
                <ManageUsersComponent />
            </div>            
            {/* <NavigationBottomBar /> */}
        </div>
    )
}
