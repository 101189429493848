import React from "react";
import {
    //Container, Card, CardContent,
    FormControl,
    Select,
    IconButton,
    MenuItem,
    Container,
    Typography,
    Box,
    Snackbar,
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from "@material-ui/styles";
import ZoneCards from "./Zones/ZoneCards";
import BrigthIcon from "../../assets/iconsImages/mode_heat.png";
import ColdIcon from "../../assets/iconsImages/mode_cold.png";
import FanIcon from "../../assets/iconsImages/mode_fan.png";
import DryIcon from "../../assets/iconsImages/mode_dry.png";
import FanAIcon from "../../assets/iconsImages/fan_hover_A.png";
import Fan1Icon from "../../assets/iconsImages/fan_hover_1.png";
import Fan2Icon from "../../assets/iconsImages/fan_hover_2.png";
import Fan3Icon from "../../assets/iconsImages/fan_hover_3.png";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import ModalEditSystem from "./Modals/ModalEditSystem";
import axios from "axios";
import { SystemContext } from "../../Context/SystemContext";
import { UserContext } from "../../Context/UserContext";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from '@material-ui/icons/Edit'
import { useTranslation } from 'react-i18next'
import { InstallContext } from '../../Context/InstallContext'
import { ScheduleContext } from '../../Context/ScheduleContext'
import { URL } from '../../Utils/URL'
import socketIOClient from "socket.io-client"

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiInput-underline:after": {
            borderBottom: "2px solid #C62828",
        },
    },
    boxStyle: {
        marginRight: 20,
    }
}));

export default function Systems({ id_system }) {
    const classes = useStyles();
    const [modalSystem, setModalSystem] = React.useState(false);
    const { isDemo, socket, deviceId, setUserInstall, userInstall } = React.useContext(UserContext);
    const { listSystem, setListSystem, setChangeApp, autoWarning, setAutoWarning, valueSys } = React.useContext(SystemContext)
    const [hover, setHover] = React.useState(false)
    const { t } = useTranslation('common')
    const { install, installActive, setInstallActive } = React.useContext(InstallContext)
    const { listSchedule } = React.useContext(ScheduleContext)
    const [autoError, setAutoError] = React.useState(false)
    let socketTest = React.useRef(null)
    let socketUrl = `${URL.BACKEND_PREFIX}${URL.BACKEND_SOCKET_URL}`

    const systemsDefault = {
        sys_161c3effb59542bb816cf235a0dbf73c_159: {
            mode: 1,
            name: "default_system_name",
            state: {
                fan: {
                    mode: 2,
                    speed: 1
                },
                mode: 1
            },
            temperature: {
                setpoint: 23.0
            },
            uuid: "3c4b03bb-778aad14-220a-DEV.dev0000001",
            zones: {
                zon_161c3effb59542bb816cf235a0dbf73c_159_001: {
                    enabled: true,
                    name: "Salón",
                    temperature: {
                        sensor: 23.2,
                        setpoint: 23.5
                    }
                },
                zon_161c3effb59542bb816cf235a0dbf73c_159_002: {
                    enabled: false,
                    name: "Cocina",
                    temperature: {
                        sensor: 20.0,
                        setpoint: 20.5
                    }
                },
                zon_161c3effb59542bb816cf235a0dbf73c_159_003: {
                    enabled: true,
                    name: "Comedor",
                    temperature: {
                        sensor: 20.2,
                        setpoint: 21.5
                    }
                },
                zon_161c3effb59542bb816cf235a0dbf73c_159_004: {
                    enabled: true,
                    name: "Habitación 1",
                    temperature: {
                        sensor: 22.2,
                        setpoint: 19.5
                    }
                },
                zon_161c3effb59542bb816cf235a0dbf73c_159_005: {
                    enabled: true,
                    name: "Habitación 2",
                    temperature: {
                        sensor: 15.0,
                        setpoint: 24.0
                    }
                }
            }
        },
        sys_161c3effb59542bb816cf235a0dbf73c_129: {
            mode: 1,
            name: "default_system_name",
            state: {
                fan: {
                    mode: 2,
                    speed: 1
                },
                mode: 3
            },
            temperature: {
                setpoint: 23.0
            },
            uuid: "3c4b03bb-778aad14-220a-DEV.dev0000001",
            zones: {
                zon_161c3effb59542bb816cf235a0dbf73c_129_001: {
                    enabled: true,
                    name: "Estudio",
                    temperature: {
                        sensor: 22.2,
                        setpoint: 23.5
                    }
                },
                zon_161c3effb59542bb816cf235a0dbf73c_129_002: {
                    enabled: false,
                    name: "Habitación 3",
                    temperature: {
                        sensor: 20.5,
                        setpoint: 20.0
                    }
                }
            }
        }
    }

    const autoMode = () => {
        
        let cumplio = false
        if (Object.entries(listSchedule).length === 0) {
            let schedules = install.scheduler.schedules
            for (let keyZ of Object.keys(listSystem[id_system].zones)) {
                for (let valueS of Object.values(schedules)) {
                    for (let zoneSchId of valueS.zones) {
                        if (zoneSchId === keyZ && valueS.enabled && cumplio === false) {
                            if (isDemo) {
                                setListSystem(systemsDefault)
                            }
                            else {
                                listSystem[id_system].mode = 1
                                listSystem[id_system].state.mode = valueS.mode
                                setListSystem({ ...listSystem })
                            }
                            setAutoWarning(false)
                            setAutoError(false)
                            cumplio = true
                            break
                        }
                    }
                }
            }
        }
        else {
            for (let keyZ of Object.keys(listSystem[id_system].zones)) {
                for (let valueS of Object.values(listSchedule)) {
                    for (let zoneSchId of valueS.zones) {
                        if (zoneSchId === keyZ) {
                            if (valueS.enabled && cumplio === false) {
                                if (isDemo) {
                                    setListSystem(systemsDefault)
                                }
                                else {
                                    listSystem[id_system].mode = 1
                                    listSystem[id_system].state.mode = valueS.mode
                                    setListSystem({ ...listSystem });
                                }
                                setAutoWarning(false)
                                setAutoError(false)
                                cumplio = true
                                break
                            }
                        }
                    }
                }
            }
        }
        if (cumplio === false) {
            setAutoWarning(true)
            if (listSystem[id_system].mode === 3) {
                listSystem[id_system].mode = 3;
                setListSystem({ ...listSystem });
            }
            else {
                listSystem[id_system].mode = 2;
                setListSystem({ ...listSystem });
            }
        }
    }

    const setZonesFalse = () => {
        let count = 0
        for (let value of Object.values(listSystem[id_system].zones)) {
            if (value.enabled === false)
                count++
        }
        if (count === Object.keys(listSystem[id_system].zones).length && Object.keys(listSystem[id_system].zones).length !== 0) {
            listSystem[id_system].mode = 3
            setListSystem({ ...listSystem });
        }
    }
    const setZonesFalseSocket = (data) => {
        let count = 0
        for (let value of Object.values(listSystem[data.system_id].zones)) {
            if (value.enabled === false)
                count++
        }
        if (count === Object.keys(listSystem[data.system_id].zones).length && Object.keys(listSystem[data.system_id].zones).length !== 0) {
            listSystem[data.system_id].mode = 3
            setListSystem({ ...listSystem });
        }
        else {
            listSystem[data.system_id].mode = 2
            setListSystem({ ...listSystem });
        }
    }
    const changeTempSystem = (data) => {

        for (let key of Object.keys(listSystem[data.system_id].zones)) {
            listSystem[data.system_id].zones[key].temperature.setpoint = data.value
            setListSystem({ ...listSystem })
        }
        for (let key of Object.keys(listSystem[data.system_id].zones)) {
            listSystem[data.system_id].zones[key].enabled = true;
            setListSystem({ ...listSystem });
        }
        listSystem[data.system_id].temperature.setpoint = data.value
        listSystem[data.system_id].mode = 2
        setListSystem({ ...listSystem })
    }
    const changeSystemMode = (data) => {
        if (data.value === 3) {

            listSystem[data.system_id].mode = 3
            setListSystem({ ...listSystem })
            for (let key of Object.keys(listSystem[data.system_id].zones)) {
                listSystem[data.system_id].zones[key].enabled = false;
                setListSystem({ ...listSystem })
            }
        }
        else if (data.value === 1) {
            
            let cumplio = false
            if (Object.entries(listSchedule).length === 0) {
                
                let schedules = install.scheduler.schedules
                for (let keyZ of Object.keys(listSystem[data.system_id].zones)) {
                    for (let valueS of Object.values(schedules)) {
                        for (let zoneSchId of valueS.zones) {
                            if (zoneSchId === keyZ) {
                                listSystem[data.system_id].mode = 1
                                listSystem[data.system_id].state.mode = valueS.mode
                                setListSystem({ ...listSystem })
                                setAutoWarning(false)
                                setAutoError(false)
                                cumplio = true
                                break
                            }
                        }
                    }
                }
            }
            else {
                for (let keyZ of Object.keys(listSystem[data.system_id].zones)) {
                    for (let valueS of Object.values(listSchedule)) {
                        for (let zoneSchId of valueS.zones) {
                            if (zoneSchId === keyZ) {
                                //if (valueS.enabled) {
                                    listSystem[data.system_id].mode = 1;
                                    listSystem[data.system_id].state.mode = valueS.mode
                                    setListSystem({ ...listSystem })
                                    setAutoWarning(false)
                                    setAutoError(false)
                                    cumplio = true
                                    break
                                //}
                            }
                        }
                    }
                }
            }
            if (cumplio === false) {
                setAutoWarning(true)
                listSystem[data.system_id].mode = 2;
                setListSystem({ ...listSystem });
            }
        }
        else if (data.value === 2) {

            listSystem[data.system_id].mode = 2
            setListSystem({ ...listSystem })
        }
    }
    const changeSystemStateMode = (data) => {
        if (listSystem[data.system_id].mode === 3) {

            listSystem[data.system_id].state.mode = data.value
            setListSystem({ ...listSystem })
        }
        else {

            listSystem[data.system_id].state.mode = data.value
            listSystem[data.system_id].mode = 2
            setListSystem({ ...listSystem })
        }
    }
    const changeSystemStateFanMode = (data) => {
        listSystem[data.system_id].state.fan.mode = data.value
        setListSystem({ ...listSystem })
    }
    const changeSystemStateFanSpeed = (data) => {
        listSystem[data.system_id].state.fan.speed = data.value
        setListSystem({ ...listSystem })
    }
    const changeSystemName = (data) => {
        listSystem[data.system_id].name = data.value
        setListSystem({ ...listSystem })
    }
    const changeZoneName = (data) => {
        listSystem[data.system_id].zones[data.zone_id].name = data.value
        setListSystem({ ...listSystem })
    }
    const changeZoneSetPoint = (data) => {

        listSystem[data.system_id].zones[data.zone_id].temperature.setpoint = data.value
        listSystem[data.system_id].mode = 2
        setListSystem({ ...listSystem })
    }
    const changeZoneSensor = (data) => {
        listSystem[data.system_id].zones[data.zone_id].temperature.sensor = data.value
        setListSystem({ ...listSystem })
    }
    const changeZoneEnabled = (data) => {
        if (listSystem[data.system_id].mode === 3) {
            listSystem[data.system_id].zones[data.zone_id].enabled = data.value
            setListSystem({ ...listSystem });
        }
        else {
            listSystem[data.system_id].mode = 2
            listSystem[data.system_id].zones[data.zone_id].enabled = data.value
            setListSystem({ ...listSystem })
        }

    }
    const changeSystemStateModeOnly = (data) => {
        listSystem[data.system_id].state.fan.mode = data.value
        setListSystem({ ...listSystem })
    }
    const changeSystemZoneEnabledOnly = (data) => {
        listSystem[data.system_id].zones[data.zone_id].enabled = data.value
        setListSystem({ ...listSystem })
    }
    const changeSystemZoneSetPointOnly = (data) => {
        listSystem[data.system_id].zones[data.zone_id].temperature.setpoint = data.value
        setListSystem({ ...listSystem })
    }
    const changeInstallNameIcon = (data) => {
        setUserInstall({ ...userInstall, icon: data.value.icon, name: data.value.name })
        setInstallActive({ ...installActive, name: data.value.name, icon: data.value.icon })
    }
    const enableSchedule = (data) => {
        let cumplio = true
        //if(Object.keys(listSystem).length === 0){        
        let listSchedules = install.scheduler.schedules
        for (let keyS of Object.keys(listSystem)) {
            for (let keyZ of Object.keys(listSystem[keyS].zones)) {                
                for (let keyZSch of listSchedules[data.id].zones) {
                    if (keyZSch === keyZ && data.enabled === false && cumplio) {
                        listSystem[keyS].mode = 2
                        setListSystem({ ...listSystem })
                        cumplio = false
                    }
                }
            }
        }
    //}
    // else {
    //     for (let keyS of Object.keys(listSystem)) {
    //         for (let keyZ of Object.keys(listSystem[keyS].zones)) {                
    //             for (let keyZSch of listSchedule[data.id].zones) {
    //                 if (keyZSch === keyZ && data.enabled === false && cumplio) {
    //                     listSystem[keyS].mode = 2
    //                     setListSystem({ ...listSystem })
    //                     cumplio = false
    //                 }
    //             }
    //         }
    //     }
    // }
    }

    const handleSocketIoServer = (value) => {
        let socketObject = JSON.parse(value)
        let installSocket = Object.values(socketObject)[0][0]
        let event = installSocket.event
        let data = installSocket.data
        if (event === 1) {
            changeSystemMode(data)
        }
        else if (event === 2) {
            changeTempSystem(data)
        }
        else if (event === 3) {
            //ahora cuando este en auto me tengo q ir a manual solo si estoy en auto
            changeSystemStateMode(data)
        }
        else if (event === 4) {
            changeSystemStateFanMode(data)
        }
        else if (event === 5) {
            changeSystemStateFanSpeed(data)
        }
        else if (event === 6) {
            changeZoneEnabled(data)
            // te pone en manual si estaba en auto 
            setZonesFalseSocket(data)
        }
        else if (event === 7) {
            changeZoneSetPoint(data)
        }
        // else if (event === 8) {
        //     changeZoneMode(data)
        // }
        else if (event === 9) {
            changeZoneSensor(data)
        }
        if (event === 11) {
            changeInstallNameIcon(data)
        }
        else if (event === 12) {
            changeSystemName(data)
        }
        else if (event === 13) {
            changeZoneName(data)
        }
        else if (event === 17) {
            enableSchedule(data)
        }
        else if (event === 18) {
            //estos 3 nunca cambian a manual
            changeSystemStateModeOnly(data)
        }
        else if (event === 19) {
            //zone enabled
            changeSystemZoneEnabledOnly(data)
        }
        else if (event === 20) {
            //zone set point
            changeSystemZoneSetPointOnly(data)
        }
    }

    const handleSocketIoServerA = (val, key) => {
        //console.log("connectionSystem")
        const data = {
            installationId: key,
            deviceUuid: deviceId,
            socketKey: "uYfa9UZ3ytSMWUMGwfpjS8g9xN9rJ6Qm",
        }
        const dataId = { installationId: key }
        setInterval(() => {
            socketTest.current.emit('heartbeat', dataId)
        }, 30000);
        
        
        socketTest.current.emit('fromApp', data)
    }
    
    React.useEffect(() => {
        if (!isDemo) {
            socketTest.current = socketIOClient(socketUrl, {
                autoConnect: false
            })
            socketTest.current.on('connect', (val) => handleSocketIoServerA(val, installActive.id))
            socketTest.current.connect()
            socketTest.current.on('fromServer', (val) => handleSocketIoServer(val))
        }
        return () => {
            if(!isDemo) {
                socketTest.current.off('fromServer')
                socketTest.current.off('connect')
                socketTest.current.close()
            }
        }
    }, [socketUrl])

    React.useEffect(() => {
        setZonesFalse()
    }, [valueSys])

    const handlesystemStateFan = () => {
        let result;
        if (isDemo) {
            return listSystem[id_system].state.fan.speed
        }
        else {
            if (listSystem[id_system].state.fan.mode === 1) {
                result = 1;
            }
            else if (listSystem[id_system].state.fan.mode !== 1 && listSystem[id_system].state.fan.speed === 1) {
                result = 2;
            }
            else if (listSystem[id_system].state.fan.mode !== 1 && listSystem[id_system].state.fan.speed === 2) {
                result = 3;
            }
            else if (listSystem[id_system].state.fan.mode !== 1 && listSystem[id_system].state.fan.speed === 3) {
                result = 4
            }
            return result
        }
    }

    const handleChangeThermostat = (e) => {
        if (isDemo) {
            if (listSystem[id_system].mode === 3) {
                listSystem[id_system].state.mode = e.target.value
                setListSystem({ ...listSystem })
            }
            else {
                listSystem[id_system].state.mode = e.target.value
                listSystem[id_system].mode = 2
                setListSystem({ ...listSystem })
            }
        } else {
            const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setSystemStateMode`;
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
            const data = {
                mode: e.target.value,
                id: id_system
            }
            axios
                .post(url, data, config)
                .then((res) => {
                    if (listSystem[id_system].mode === 3) {
                        listSystem[id_system].state.mode = e.target.value
                        setListSystem({ ...listSystem })
                    }
                    else {
                        listSystem[id_system].state.mode = e.target.value
                        listSystem[id_system].mode = 2
                        setListSystem({ ...listSystem })
                    }
                })
                .catch((e) => {
                    //console.log(e);
                });
        }
    }
    const handleChangeFan = (e) => {
        //setsystemstatefan
        if (isDemo) {
            listSystem[id_system].state.fan.speed = e.target.value
            setListSystem({ ...listSystem })
        } else {
            const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setSystemStateFan`;
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            if (e.target.value === 1) {
                const data = {
                    speed: e.target.value,
                    id: id_system,
                    mode: 1,
                };
                axios
                    .post(url, data, config)
                    .then((res) => {
                        listSystem[id_system].state.fan.speed = e.target.value;
                        listSystem[id_system].state.fan.mode = 1;
                        setListSystem({ ...listSystem });
                    })
                    .catch((e) => {
                        //console.log(e);
                    });
            } else if (e.target.value === 2) {
                const data = {
                    speed: 1,
                    id: id_system,
                    mode: 0,
                };
                axios
                    .post(url, data, config)
                    .then((res) => {
                        listSystem[id_system].state.fan.speed = 1;
                        listSystem[id_system].state.fan.mode = 0;
                        setListSystem({ ...listSystem });
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            } else if (e.target.value === 3) {
                const data = {
                    speed: 2,
                    id: id_system,
                    mode: 0,
                };
                axios
                    .post(url, data, config)
                    .then((res) => {
                        listSystem[id_system].state.fan.speed = 2;
                        listSystem[id_system].state.fan.mode = 0;
                        setListSystem({ ...listSystem })
                    })
                    .catch((e) => {
                        //console.log(e);
                    });
            } else if (e.target.value === 4) {
                const data = {
                    speed: 3,
                    id: id_system,
                    mode: 0,
                };
                axios
                    .post(url, data, config)
                    .then((res) => {
                        listSystem[id_system].state.fan.speed = 3;
                        listSystem[id_system].state.fan.mode = 0;
                        setListSystem({ ...listSystem });
                    })
                    .catch((e) => {
                        //console.log(e);
                    });
            }
        }
    };

    const handleChangeOff = (e) => {
        if (isDemo) {
            enabledAllOff(e.target.value);
        } else {
            const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setSystemMode`;
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            enabledAllOff(e.target.value);
            const data = {
                mode: listSystem[id_system].mode,
                id: id_system,
            };
            axios.post(url, data, config)
                .then((res) => {

                })
                .catch((e) => {
                    //console.log(e);
                });
        }
        setChangeApp(Math.floor(Math.random() * (100 - 10)) + 10);
    };
    const enabledAllOff = (val) => {
        if (val === 3) {
            listSystem[id_system].mode = 3
            setListSystem({ ...listSystem })
            for (let key of Object.keys(listSystem[id_system].zones)) {
                listSystem[id_system].zones[key].enabled = false
                setListSystem({ ...listSystem })
            }
        } else if (val === 1) {
            autoMode()
        } else if (val === 2) {
            listSystem[id_system].mode = 2
            setListSystem({ ...listSystem })
        }
    };
    const handleOpenEditSystem = () => {
        setModalSystem(true);
    };
    const handleCloseEditModal = () => {
        setModalSystem(false);
    };
    //const [intervalState, setIntervalState] = React.useState(500)

    const clickRestTemp = () => {
        if (isDemo) {
            if (listSystem[id_system].temperature.setpoint > 18.0) {
                for (let key of Object.keys(listSystem[id_system].zones)) {
                    listSystem[id_system].zones[key].temperature.setpoint = listSystem[id_system].temperature.setpoint - 0.5;
                }
                for (let key of Object.keys(listSystem[id_system].zones)) {
                    listSystem[id_system].zones[key].enabled = true;
                    setListSystem({ ...listSystem });
                }
                listSystem[id_system].temperature.setpoint = listSystem[id_system].temperature.setpoint - 0.5
                listSystem[id_system].mode = 2
                setListSystem({ ...listSystem });
                // if (listSystem[id_system].state.mode === 1)
                //     window.clearInterval(intervalState)
            }
        }
        else {
            if (listSystem[id_system].temperature.setpoint > 18.0) {
                for (let key of Object.keys(listSystem[id_system].zones)) {
                    if (listSystem[id_system].zones[key].temperature.setpoint !== 1000) {
                        listSystem[id_system].zones[key].enabled = true;
                        listSystem[id_system].zones[key].temperature.setpoint = listSystem[id_system].temperature.setpoint - 0.5;
                        setListSystem({ ...listSystem });
                    }
                }
                listSystem[id_system].temperature.setpoint = listSystem[id_system].temperature.setpoint - 0.5
                listSystem[id_system].mode = 2
                setListSystem({ ...listSystem });
                const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setSystemTemperatureSetpoint`;
                const config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
                const data = {
                    id: id_system,
                    setPoint: listSystem[id_system].temperature.setpoint,
                    numberOfZones: 3,
                };
                axios
                    .post(url, data, config)
                    .then((res) => {

                    })
                    .catch((e) => {
                        //console.log(e);
                    });
            }
        }
    };
    const clickSumTemp = () => {
        if (isDemo) {
            if (listSystem[id_system].temperature.setpoint < 30.0) {
                for (let key of Object.keys(listSystem[id_system].zones)) {
                    listSystem[id_system].zones[key].temperature.setpoint = listSystem[id_system].temperature.setpoint + 0.5
                }
                for (let key of Object.keys(listSystem[id_system].zones)) {
                    listSystem[id_system].zones[key].enabled = true;
                    setListSystem({ ...listSystem });
                }
                listSystem[id_system].temperature.setpoint = listSystem[id_system].temperature.setpoint + 0.5;
                listSystem[id_system].mode = 2

                setListSystem({ ...listSystem });
                // const interval = window.setInterval(function () { console.log('empezo') }, Number.MAX_SAFE_INTEGER);
                // setIntervalState(interval)
            }
        }
        else {
            if (listSystem[id_system].temperature.setpoint < 30.0) {
                for (let key of Object.keys(listSystem[id_system].zones)) {
                    if (listSystem[id_system].zones[key].temperature.setpoint !== 1000) {
                        listSystem[id_system].zones[key].enabled = true;
                        listSystem[id_system].zones[key].temperature.setpoint = listSystem[id_system].temperature.setpoint + 0.5;
                        setListSystem({ ...listSystem });
                    }
                }
                listSystem[id_system].temperature.setpoint = listSystem[id_system].temperature.setpoint + 0.5;

                listSystem[id_system].mode = 2
                setListSystem({ ...listSystem });

                const url = `${URL.BACKEND_PREFIX}${URL.BACKEND_URL}${URL.BACKEND_API_PATH}/setSystemTemperatureSetpoint`;
                const config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                };
                const data = {
                    id: id_system,
                    setPoint: listSystem[id_system].temperature.setpoint,
                    numberOfZones: 1,
                };
                axios
                    .post(url, data, config)
                    .then((res) => {

                    })
                    .catch((e) => {
                        //console.log(e);
                    });
            }
        }
    };
    const handleClose = () => {
        setAutoWarning(false)
        setAutoError(false)
    }

    const systemNames = () => {
        let newObj = []
        for (let [key, value] of Object.entries(listSystem)) {
            let nSystem = {
                systemId: key,
                systemName: value.name
            }
            newObj.push(nSystem)
        }
        return changeName(newObj)
    }

    const changeName = (names) => {
        let sN = ''
        let index = 0
        if (t(`system.${listSystem[id_system].name}`) === 'Sistema' || t(`system.${listSystem[id_system].name}`) === 'System' || t(`system.${listSystem[id_system].name}`) === 'Système') {
            index = names.findIndex(s => s.systemId === id_system)
            let f = t(`system.${listSystem[id_system].name}`)
            sN = `${f} ${index + 1}`
        }
        else {
            sN = listSystem[id_system].name
        }
        return sN
    }

    return (
        <Container>
            <Box>
                <IconButton
                    onClick={handleOpenEditSystem}
                    style={{ marginBottom: 43, paddingLeft: 0, marginTop: 29 }}
                    color="inherit"
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}>
                    <Typography className={classes.editSystemName} variant={"h4"}>
                        {
                            systemNames()
                        }
                    </Typography>
                </IconButton>
                {hover ? <EditIcon style={{ marginBottom: 33 }} color='inherit' /> : null}
            </Box>
            <Box>
                <FormControl className={classes.boxStyle}>
                    <Select
                        // MenuProps={{
                        //     disableScrollLock: true,
                        // }} 
                        value={listSystem[id_system].state.mode} onChange={handleChangeThermostat}>
                        <MenuItem value={1}>
                            <IconButton color="inherit">
                                <img style={{ width: 27 }} src={BrigthIcon} alt="mode hot" />
                            </IconButton>
                        </MenuItem>
                        <MenuItem value={3}>
                            <IconButton color="inherit">
                                <img style={{ width: 27 }} src={ColdIcon} alt="mode cold" />
                            </IconButton>
                        </MenuItem>
                        <MenuItem value={6}>
                            <IconButton color="inherit">
                                <img style={{ width: 19, height: 26 }} src={DryIcon} alt="mode dry" />
                            </IconButton>
                        </MenuItem>
                        <MenuItem value={5}>
                            <IconButton color="inherit">
                                <img style={{ width: 27 }} src={FanIcon} alt="mode fan" />
                            </IconButton>
                        </MenuItem>
                    </Select>
                </FormControl>

                <FormControl className={classes.boxStyle}>
                    <Select MenuProps={{
                        disableScrollLock: true,
                    }} value={handlesystemStateFan()} onChange={handleChangeFan}>
                        <MenuItem value={1}>
                            <IconButton color="inherit">
                                <img
                                    style={{ width: 27 }}
                                    src={FanAIcon}
                                    alt="speed auto fan"
                                />
                            </IconButton>
                        </MenuItem>
                        <MenuItem value={2}>
                            <IconButton color="inherit">
                                <img style={{ width: 27 }} src={Fan1Icon} alt="speed 1 fan" />
                            </IconButton>
                        </MenuItem>
                        <MenuItem value={3}>
                            <IconButton color="inherit">
                                <img style={{ width: 27 }} src={Fan2Icon} alt="speed 2 fan" />
                            </IconButton>
                        </MenuItem>
                        <MenuItem value={4}>
                            <IconButton color="inherit">
                                <img style={{ width: 27 }} src={Fan3Icon} alt="speed 3 fan" />
                            </IconButton>
                        </MenuItem>
                    </Select>
                </FormControl>

                <FormControl className={classes.boxStyle}>
                    <Select MenuProps={{
                        disableScrollLock: true,
                    }} value={listSystem[id_system].mode} onChange={handleChangeOff}>
                        <MenuItem value={1}>
                            <IconButton
                                style={{ display: "flex", paddingBottom: 6 }}
                                color="inherit">
                                <PowerSettingsNewIcon />
                                &nbsp;{" "}
                                <Typography
                                    variant="h5"
                                    style={{ color: "green", fontWeight: 600 }}>
                                    AUTO
                                </Typography>
                            </IconButton>
                        </MenuItem>
                        {
                            listSystem[id_system].mode === 3 ?
                                <MenuItem>
                                    <IconButton
                                        style={{ display: "flex", paddingBottom: 6 }}
                                        color="inherit">
                                        <PowerSettingsNewIcon />
                                        &nbsp;{" "}
                                        <Typography
                                            variant="h5"
                                            style={{ color: "gray", fontWeight: 600 }}>
                                            MAN
                                        </Typography>
                                    </IconButton>
                                </MenuItem>
                                :
                                <MenuItem value={2}>
                                    <IconButton
                                        style={{ display: "flex", paddingBottom: 6 }}
                                        color="inherit">
                                        <PowerSettingsNewIcon />
                                        &nbsp;{" "}
                                        <Typography
                                            variant="h5"
                                            style={{ color: "orange", fontWeight: 600 }}>
                                            MAN
                                        </Typography>
                                    </IconButton>
                                </MenuItem>
                        }

                        <MenuItem value={3}>
                            <IconButton
                                style={{ display: "flex", paddingBottom: 6 }}
                                color="inherit">
                                <PowerSettingsNewIcon />
                                &nbsp;{" "}
                                <Typography
                                    variant="h5"
                                    style={{ color: "red", fontWeight: 600 }}>
                                    OFF
                                </Typography>
                            </IconButton>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box alignItems={"center"} display={"flex"} ml={4} mt={3} mb={3}>
                <IconButton onClick={clickRestTemp} color="inherit">
                    <RemoveIcon />
                </IconButton>
                <Typography style={{ fontFamily: 'system-ui', fontWeight: 400 }} variant="h3">{`${listSystem[id_system].temperature.setpoint.toFixed(1)}º`}</Typography>
                <IconButton onClick={clickSumTemp} color="inherit">
                    <AddIcon />
                </IconButton>
            </Box>

            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {Object.keys(listSystem[id_system].zones).map((key) => (
                    <ZoneCards
                        id_system={id_system}
                        key={key}
                        id_zone={key}
                        temperature={listSystem[id_system].temperature.setpoint}
                    />
                ))}
            </div>

            <ModalEditSystem
                id_system={id_system}
                name={systemNames()}
                open={modalSystem}
                onClose={handleCloseEditModal}
            />
            <Snackbar open={autoError} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">{t('system.systems_validations_schedules_creates')}</Alert>
            </Snackbar>
            <Snackbar open={autoWarning} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">{t('system.systems_validations_schedules_actives')}</Alert>
            </Snackbar>
        </Container>
    );
}
